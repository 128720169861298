import axios from 'axios';
import get from 'lodash/get';
import has from 'lodash/has';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import isEmpty from 'lodash/isEmpty';
import merge from 'lodash/merge';
import { canView } from '@/libs/aclMiddleware';
import { getUrl } from '@/config/api.routes';
import { getFile, applyReplyMessages, composeMessage } from '@/libs';
import { SIDE_PANEL_TAB_NAMES, MESSAGES_LIMIT } from '@/constants';
import { NOTES, TEAMS, CATEGORIES, TAGS, KNOWLEDGEBASE, QUICK_RESPONSE } from '@/constants/analytics';

const NOTES_LIST_LIMIT = 25;

export default {
  getAttachments({ state, commit, rootState }, { customerId, limit, offset, mode }) {
    const hide_deleted = !canView(rootState.agent.permissions, '/conversations/message/audit');
    return axios.get(`${getUrl('conversations.customers')}/${customerId}/attachments`, {
      params: { limit, offset, types: mode.types, ...(hide_deleted && { hide_deleted }) },
    }).then(({ data }) => {
      data.models = offset ?
        [...state.attachmentsHistory.records.models, ...data.models]
        : data.models;
      commit('SET_ATTACHMENTS_HISTORY', { records: data, loaded: true, mode });
    });
  },

  // todo (Gabe) - merge this with selectConversation
  getCustomerCoversationsFromMessages({ commit, rootGetters }, { customer, params }) {
    commit('CUSTOMER_CONVERSATIONS_LOADING', true);
    return axios.get(`${getUrl('conversations.customers')}/${customer.id}/messages`, { params: { ...params, events: true } })
      .then(async data => {
        const messages = get(data, 'data.models', []);
        const conversations = rootGetters['conversations/conversations'];

        /*
          Filter out conversation_id's to only retrive conversations
          for loaded (paginated) messages
        */
        let ids = [...new Set(messages.map(x => x.conversation_id))];
        ids = ids.filter(id => !conversations.some(c => c.id === id));
        params = { ids, lm: false };

        let promise;
        if (ids.length) {
          promise = axios.get(`${getUrl('conversations.customers')}/${customer.id}/conversations`, { params });
        } else {
          promise = Promise.resolve();
        }

        return [data, await promise];
      })
      .then(([messagesResult, conversationsResult]) => {
        commit('CUSTOMER_CONVERSATIONS_LOADING', false);
        const messages = get(messagesResult, 'data.models', []);
        const conversations = get(conversationsResult, 'data.models', []);
        const agentsById = rootGetters['agents/agentsById'];
        commit('TOTAL_CUSTOMER_MESSAGES', messagesResult.data.pagination.rowCount);
        commit('ADD_CONVERSATIONS', { conversations, messages, agentsById, customer });
      }).catch(() => {
        commit('CUSTOMER_CONVERSATIONS_LOADING', false);
      });
  },

  sendMessage({ commit }, { id, ...message }) {
    const composedMessage = composeMessage(message);
    const postUrl = `${getUrl('conversations.conversations', id)}/messages`;
    return axios.post(postUrl, composedMessage)
      .then(data => {
        commit('ADD_MESSAGE', data.data);
        return data.data;
      });
  },

  updateMessage(_, { conversationId, messageId, payload }) {
    return axios
      .put(`${getUrl('conversations.conversations', conversationId)}/messages/${messageId}`, payload);
  },

  deleteMessage(_, { conversationId, messageId }) {
    return axios
      .delete(`${getUrl('conversations.conversations', conversationId)}/messages/${messageId}`);
  },

  // todo (Gabe) - verify can be removed
  get(context, [id]) {
    return axios.get(`${getUrl('conversations.customers')}/${id}/conversations`);
    /**
      .then(data => {
         * Turning this on causes bug that stops All polling, but if we set
         * polling to true that will be wrong too to do that from here. Ideally
         * there shouldn't be a store action that doesn't commit to store, so
         * this whole flow needs to be refactored.
          commit('LOAD_LIST', [
              [data.data],
              rootState.agent.profile,
              isUpdate,
            ]);
            return data;
          });
    */
  },

  unfocus({ commit }) {
    commit('UNFOCUS');
  },

  focus({ commit }, customer) {
    commit('SET_FOCUS', customer);
  },

  scheduleMessage(params, [id, content, schedule]) {
    return axios.post(`${getUrl('conversations.conversations', id)}/schedule`, {
      content,
      schedule,
    }).then(data => data);
  },

  list({ commit, rootGetters }, params = {}) {
    const agentProfile = rootGetters['agent/profile'];
    const { channel, scope, polling } = params;

    delete params.channel;
    delete params.scope;
    delete params.polling;

    if (!polling) {
      commit('SET_FILTER', params.filter);
    }

    let endpoint = `${getUrl('conversations.conversations', channel)}/${scope}`;
    if (params.filter) {
      endpoint = `${endpoint}/search`;
    }

    // External and configurable conversations filter handle in background
    const hide_closed_conversations = get(agentProfile, 'preferences.hide_closed_conversations', false);
    params = merge(params, {
      filter: { ...(hide_closed_conversations && { hide_closed_conversations }) },
    });

    return axios.get(endpoint, { params })
      .then(data => {
        const conversations = get(data, 'data.models', []);
        const pagination = get(data, 'data.pagination', {});

        const replaceList = params.offset === 0;
        commit('LOAD_LIST', [
          conversations,
          agentProfile,
          replaceList,
          polling,
          params.order,
          scope,
          channel,
        ]);

        let markAsLoaded = false;
        if (pagination.rowCount) {
          markAsLoaded = true;
        }
        if (!polling) {
          commit('SET_PAGINATION', pagination);
        }
        commit('MARK_AS_LOADED', markAsLoaded);
        return data;
      });
  },

  refreshCounters({ commit, rootGetters }, { channel, scope }) {
    const views = rootGetters['agent/views'];
    const agentProfile = rootGetters['agent/profile'];
    // External and configurable conversations filter handle in background
    const hide_closed_conversations = get(agentProfile, 'preferences.hide_closed_conversations', false);
    const params = {
      scope,
      views,
      ...(hide_closed_conversations && { filter: { hide_closed_conversations } }),
    };
    return axios.get(`${getUrl('conversations.conversations')}/${channel}/stats`, { params })
      .then(data => {
        for (const [key, value] of Object.entries(data.data)) {
          commit('UPDATE_SPECIAL_COUNTER', [key, Number(value)]);
        }
        return data;
      });
  },

  setFilter(context, filter) {
    context.commit('SET_FILTER', filter);
  },

  selectConversation({
    state,
    commit,
    dispatch,
    rootState,
    rootGetters,
  },
  { id, customer }) {
    const agentsById = rootGetters['agents/agentsById'];

    // todo (Gabe) - evaluate cleaning this up
    if (!id) {
      commit('SELECT_CONVERSATION', [null, get(rootState, 'agent.profile', null)]);
      return Promise.resolve();
    }

    let isUnread = false;
    commit('CUSTOMER_CONVERSATIONS_LOADING', true);
    if (customer && customer.id) {
      const conversation = rootState.conversations.conversations
        .find(c => c.customer_id === customer.id);
      if (get(conversation, 'unread_message_count')) {
        isUnread = true;
      }
    }

    const endpoint = `${getUrl('conversations.customers')}/${customer.id}/messages`;
    return axios.get(endpoint, { params: { limit: MESSAGES_LIMIT, events: true } })
      .then(async data => {
        const messages = get(data, 'data.models', []);
        const params = { ids: [...new Set(messages.map(x => x.conversation_id))], lm: false };
        return [data, await axios.get(`${getUrl('conversations.customers')}/${customer.id}/conversations`, { params })];
      })
      .then(([messagesResult, conversationsResult]) => {
        const messages = get(messagesResult, 'data.models', []);
        const conversations = get(conversationsResult, 'data.models', []);
        const customerId = customer.id;
        const starred = get(state.conversations.find(c => c.customer_id === customerId), 'starred', false);

        applyReplyMessages({ messages });

        commit('SET_NOTES', [[], true]);
        commit('TOTAL_CUSTOMER_MESSAGES', messagesResult.data.pagination.rowCount);
        commit('SELECT_CONVERSATION', [{ conversations, messages, customer }, rootState.agent.profile, agentsById, starred]);

        dispatch('notesList', [id, NOTES_LIST_LIMIT, 0]);
        dispatch('scheduleList', [customerId, NOTES_LIST_LIMIT, 0]);
        dispatch('documentsList', [id]);
        dispatch('assetsList', [id]);

        commit('CUSTOMER_CONVERSATIONS_LOADING', false);
        commit('RESET_KB');

        if (isUnread) {
          dispatch('decreaseUnreadCounter');
        }

        return customerId;
        // TODO (Gabe) - Can remove once customer ID always passed to action
      }).then(customerId => axios.get(`${getUrl('conversations.customers')}/${customerId}`))
      .then(data => {
        commit('SET', ['selectedCustomer', data.data]);
        return data;
      })
      .catch(() => {
        commit('CUSTOMER_CONVERSATIONS_LOADING', true);
      });
  },

  updateNewConversationIndicator(context, value) {
    context.commit('UPDATE_NEW_CONVERSATION_INDICATOR', value);
  },

  updateIsConversationOpen(context, value) {
    context.commit('IS_COVERSATION_OPEN', value);
  },

  updateIsVideoContext(context, value) {
    context.commit('IS_VIDEO_CONTEXT', value);
  },

  updateIsCobrowseContext(context, value) {
    context.commit('IS_COBROWSE_CONTEXT', value);
  },

  readConversation(context, id) {
    const payload = {
      event: {
        action: 'messages.mark.read',
        content: '',
      },
      message_type: 'event',
    };

    return axios.post(`${getUrl('conversations.conversations')}/${id}/events`, payload)
      .then(data => {
        context.commit('MARK_AS_READ', [{ id }, get(context, 'rootState.agent.profile', null)]);
        return data;
      });
  },

  notesList({ commit }, [conversation, limit, offset]) {
    return axios.get(`${getUrl('conversations.conversations', conversation)}/notes`, {
      params: { limit, offset },
    }).then(data => {
      commit('SET_NOTES', [data.data.models, offset === 0]);
      return data;
    });
  },

  scheduleList({ commit }, [customerId, limit, offset]) {
    return axios.get(`${getUrl('management.agents', 'me')}/customers/${customerId}/schedules`, {
      params: { limit, offset },
    }).then(data => {
      commit('SET_SCHEDULES', [data.data.models, offset === 0]);
      return data;
    });
  },

  addNote({ commit, rootState, dispatch }, [conversation, content]) {
    return axios.post(`${getUrl('conversations.conversations', conversation)}/notes`, {
      content,
    }).then(data => {
      commit('ADD_NOTE', [conversation, data.data]);
      dispatch(
        'notifications/emitEventToSocket',
        { name: NOTES.ADDED,
          payload: {
            agent: get(rootState, 'agent.profile'),
            note: content,
            conversation_id: conversation },
        },
        { root: true },
      );
      return data;
    });
  },

  removeNote({ commit, rootState, dispatch }, [conversation, note]) {
    return axios.delete(`${getUrl('conversations.conversations', conversation)}/notes/${note}`).then(data => {
      commit('REMOVE_NOTE', [conversation, note]);
      dispatch(
        'notifications/emitEventToSocket',
        { name: NOTES.REMOVED,
          payload: {
            agent: get(rootState, 'agent.profile'),
            conversation_id: conversation,
            note_id: note,
          },
        },
        { root: true },
      );
      return data;
    });
  },

  async documentsList({ commit }, [conversationId]) {
    const ret = await axios.get(`${getUrl('conversations.conversations', conversationId)}/suggestions/documents`);
    commit('SET_SUGGEST_ITEM_HISTORY_BY_MESSAGE', [ret.data.models, 'documents']);
    return ret.data.models;
  },

  async assetsList({ commit }, [conversationId]) {
    const ret = await axios.get(`${getUrl('conversations.conversations', conversationId)}/suggestions/assets`);
    commit('SET_SUGGEST_ITEM_HISTORY_BY_MESSAGE', [ret.data.models, 'assets']);
    return ret.data.models;
  },

  starConversation({ commit }, [id, value]) {
    return (value
      ? axios.post(`${getUrl('management.agents', 'me')}/conversations`, {
        conversation: {
          id,
        },
      })
      : axios.delete(`${getUrl('management.agents', 'me')}/conversations/${id}`))
      .then(data => {
        commit('STAR_CONVERSATION', [id, value]);
        return data;
      });
  },

  updateTags({ commit, rootState, dispatch }, [conversation, tags, updateType]) {
    return axios
      .put(`${getUrl('conversations.conversations', conversation)}/tags`, tags)
      .then(data => {
        commit('UPDATE_TAGS', [conversation, data.data]);
        dispatch(
          'notifications/emitEventToSocket',
          { name: TAGS.UPDATED,
            payload: {
              agent: get(rootState, 'agent.profile'),
              tags,
              conversation_id: conversation,
              updateType,
            },
          },
          { root: true },
        );
        return data;
      });
  },

  updateCategories({ commit, rootState, dispatch }, [conversation, categories, updateType]) {
    return axios
      .put(`${getUrl('conversations.conversations', conversation)}/categories`, categories)
      .then(data => {
        commit('UPDATE_CATEGORIES', [conversation, data.data]);
        dispatch(
          'notifications/emitEventToSocket',
          { name: CATEGORIES.UPDATED,
            payload: {
              agent: get(rootState, 'agent.profile'),
              categories,
              conversation_id: conversation,
              updateType,
            },
          },
          { root: true },
        );
        return data;
      });
  },

  updateTeams({ commit, rootState, dispatch }, [conversation, teams, updateType]) {
    return axios
      .put(`${getUrl('conversations.conversations', conversation)}/teams`, teams)
      .then(data => {
        commit('UPDATE_TEAMS', [conversation, data.data]);
        dispatch(
          'notifications/emitEventToSocket',
          { name: TEAMS.UPDATED,
            payload: {
              agent: get(rootState, 'agent.profile'),
              teams,
              conversation_id: conversation,
              updateType,
            },
          },
          { root: true },
        );
        return data;
      });
  },

  updateAgents({ commit }, [conversation, agents]) {
    return axios
      .put(`${getUrl('conversations.conversations', conversation)}/agents`, agents)
      .then(data => {
        commit('UPDATE_AGENTS',
          [conversation, get(data, 'data.agents', [])]);
        return data;
      });
  },

  assignPrimaryAgent({ commit }, [conversation, payload]) {
    return axios
      .put(`${getUrl('conversations.conversations', conversation)}/attachprimary`, payload)
      .then(data => {
        commit('UPDATE_AGENTS',
          [conversation, get(data, 'data.agents', [])]);
        return data;
      });
  },

  lockToPrimaryAgent({ commit }, [conversation, payload]) {
    return axios
      .put(`${getUrl('conversations.conversations', conversation)}/lockprimary`, payload)
      .then(data => {
        commit('UPDATE_LOCK_STATUS', [get(data, 'data'), get(data, 'locked_by.profile', {})]);
        return data;
      });
  },

  updateCustomer({ commit }, [id, customer]) {
    return axios
      .put(getUrl('conversations.customers', id), customer)
      .then(data => {
        commit('UPDATE_CUSTOMER', [data.data]);
        commit('messages/UPDATE_MESSAGES_SENDER', data.data, { root: true });
        return data;
      });
  },

  closeConversation(context, conversation) {
    return axios
      .put(`${getUrl('conversations.conversations', conversation)}/status`, {
        value: 'closed',
      })
      .then(data => data);
  },

  exportConversation(context, id) {
    // Todo (Gabe) Remove from store actions
    return axios
      .get(`${getUrl('conversations.conversations', id)}/export`, {
        headers: {
          accept: 'text/csv',
        },
      })
      .then(getFile);
  },

  lockConversation({ commit, rootState }, id) {
    return axios
      .put(`${getUrl('conversations.conversations', id)}/lock`, {})
      .then(data => {
        commit('UPDATE_LOCK_STATUS', [get(data, 'data'), rootState.agent.profile]);
        commit('agent/LOCK_CONVERSATION', id, { root: true });
        return data;
      });
  },

  unlockConversation({ commit, rootState }, id) {
    return axios
      .delete(`${getUrl('conversations.conversations', id)}/lock`)
      .then(data => {
        commit('UPDATE_LOCK_STATUS', [get(data, 'data'), rootState.agent.profile]);
        commit('agent/UNLOCK_CONVERSATION', id, { root: true });
        return data;
      });
  },

  botOn({ commit, rootState }, id) {
    return axios
      .put(`${getUrl('conversations.conversations', id)}/bot_status`, {
        value: 'enabled',
      }).then(data => {
        commit('UPDATE_LOCK_STATUS', [get(data, 'data'), rootState.agent.profile]);
        return data;
      });
  },

  setSystemMessagesDisplay({ commit }, display) {
    commit('SET_SYSTEM_MESSAGES_DISPLAY', display);
  },

  toggleIsTimestampAbsolute({ state, commit }) {
    commit('SET_IS_TIMESTAMP_ABSOLUTE', !state.isTimestampAbsolute);
  },

  runDialog(context, [id, dialog]) {
    // Todo (Gabe) Remove from store actions
    return axios
      .put(`${getUrl('conversations.conversations', id)}/dialog`, {
        id: dialog.id,
        name: dialog.name,
      }).then(data => data);
  },

  runWorkflow(context, [id, workflow]) {
    // Todo (Gabe) Remove from store actions
    return axios
      .put(`${getUrl('conversations.conversations', id)}/workflow`, {
        id: workflow.id,
        name: workflow.name,
      }).then(data => data);
  },

  selectSuggestions({ commit }, payload) {
    commit('SELECT_SUGGESTIONS', payload);
    commit('SET_OPENED_SIDEBAR_TAB', SIDE_PANEL_TAB_NAMES.KNOWLEDGE_BASE);
  },

  setKBMode({ commit, rootState, dispatch }, payload) {
    commit('SET_KB_MODE', payload);
    dispatch(
      'notifications/emitEventToSocket',
      { name: KNOWLEDGEBASE.TAB_CHANGED,
        payload: {
          agent: get(rootState, 'agent.profile'),
          mode: payload,
        },
      },
      { root: true },
    );
  },

  setScope({ commit }, scope) {
    commit('SET_SCOPE', scope);
  },

  setSelectedTab({ commit }, selectedTab) {
    commit('SET_SELECTED_TAB', selectedTab);
  },

  setChannel({ commit }, channel) {
    commit('SET_CHANNEL', channel);
  },

  setSidePanelTab({ commit }, tabName) {
    commit('SET_OPENED_SIDEBAR_TAB', tabName);
  },

  async getQuickResponses({ commit }, params) {
    const data = await axios.get(`${getUrl('management.agents', 'me')}/quickresponses`, { params });
    commit('SET_QUICK_RESPONSES_LIST', { list: data.data.models, isNew: get(params, 'offset', 0) === 0 });
    return data;
  },

  async updateQuickResponse({ rootState, dispatch }, [id, content]) {
    const data = await axios.put(`${getUrl('management.agents', 'me')}/quickresponses/${id}`, { content });
    dispatch(
      'notifications/emitEventToSocket',
      { name: QUICK_RESPONSE.UPDATED,
        payload: {
          agent: get(rootState, 'agent.profile'),
          quick_response: content,
          quick_response_id: id,
        },
      },
      { root: true },
    );
    return data;
  },

  async deleteQuickResponse({ rootState, dispatch }, id) {
    const data = await axios.delete(`${getUrl('management.agents', 'me')}/quickresponses/${id}`);
    dispatch(
      'notifications/emitEventToSocket',
      { name: QUICK_RESPONSE.REMOVED,
        payload: {
          agent: get(rootState, 'agent.profile'),
          quick_response_id: id,
        },
      },
      { root: true },
    );
    return data;
  },

  async pinQuickResponse({ rootState, dispatch }, id) {
    const data = await axios.post(`${getUrl('management.agents', 'me')}/quickresponses/${id}/is_pinned`);
    dispatch(
      'notifications/emitEventToSocket',
      { name: QUICK_RESPONSE.PINNED,
        payload: {
          agent: get(rootState, 'agent.profile'),
          quick_response_id: id,
        },
      },
      { root: true },
    );
    return data;
  },

  async unpinQuickResponse({ rootState, dispatch }, id) {
    const data = await axios.delete(`${getUrl('management.agents', 'me')}/quickresponses/${id}/is_pinned`);
    dispatch(
      'notifications/emitEventToSocket',
      { name: QUICK_RESPONSE.UNPINNED,
        payload: {
          agent: get(rootState, 'agent.profile'),
          quick_response_id: id,
        },
      },
      { root: true },
    );
    return data;
  },

  async createQuickResponse({ state, rootState, dispatch }, { content, is_pinned }) {
    const quickResponse = state.quickResponses.find(e => e.content === content);
    if (quickResponse) {
      return Promise.resolve(false);
    }
    const data = await axios.post(`${getUrl('management.agents', 'me')}/quickresponses`, { content, is_pinned });
    dispatch(
      'notifications/emitEventToSocket',
      { name: QUICK_RESPONSE.CREATED,
        payload: {
          agent: get(rootState, 'agent.profile'),
          quick_response: content,
        },
      },
      { root: true },
    );
    return data;
  },

  sendDmDeepLink(context, [id]) {
    // todo fetch custom dm card message for business, and remove from store actions
    const content = 'Please message us directly';
    return axios.post(`${getUrl('conversations.conversations', id)}/messages?deepLink=true`, { content });
  },

  updatePollLimit(context, value) {
    context.commit('UPDATE_POLL_LIMIT', value);
  },

  updateConversation({ rootState, commit }, conversation) {
    commit('UPDATE_CONVERSATION', [conversation, rootState.agent.profile]);
  },

  removeAgent({ commit, state }, { conversation, agentId, removePrimary }) {
    const endpoint = `${conversation.id}/agents/${agentId}`;
    const updates = [axios.delete(getUrl('conversations.conversations', endpoint))];
    if (removePrimary) {
      const path = `${getUrl('conversations.customers', conversation.customer_id)}/agent`;

      updates.push(axios.delete(path));
    }
    return Promise.all(updates)
      .then(() => {
        const agents = conversation.agents.filter(a => a.id !== agentId);
        commit('UPDATE_AGENTS', [conversation.id, agents]);

        const { selectedCustomer } = state;
        if (removePrimary && selectedCustomer.id === conversation.customer_id) {
          commit('REMOVE_PRIMARY_AGENT', [conversation.id, selectedCustomer]);
        }
      });
  },

  decreaseUnreadCounter({ commit, state }) {
    if (state.counters.unread <= 0) { return }
    // TODO (Gabe) - Decrease stat when selected in all tab
    const valueForScope = state.counters.unread - 1;
    commit('UPDATE_SPECIAL_COUNTER', ['unread', valueForScope]);
  },

  async obfuscateMessage({ commit, state }, message) {
    const customerId = get(state, 'selectedCustomer.id');
    const messageId = get(message, 'id');

    const ret = await axios.put(`${getUrl('conversations.customers')}/${customerId}/messages/${messageId}/obfuscate`);
    commit('REPLACE_MESSAGE', get(ret, 'data'));
    return get(ret, 'data');
  },

  async refreshSchedule({ dispatch, state }) {
    const customerId = get(state, 'selectedCustomer.id');
    if (customerId) {
      return dispatch('scheduleList', [customerId, NOTES_LIST_LIMIT, 0]);
    }
  },

  // Mark customer read label
  async markConversationReadByCustomer({ state, commit }, { conversation, event }) {
    if (has(conversation, 'customer_id')
      && get(state, 'selectedCustomer.id') === get(conversation, 'customer_id')) {
      commit('UPDATE_CUSTOMER_READ', event);
    }
  },

  async getChannelsList({ commit }) {
    const res = await axios.get(`${getUrl('conversations.conversations')}/channelslist`);
    commit('SET_CHANNELS', res.data);
  },

  async sendSyncRequest(_, { args, mapping }) {
    const apiInfo = get(mapping, 'apiInfo', null);
    if (!apiInfo) {
      throw new Error('Api info is missing');
    }

    const payload = cloneDeep(apiInfo);
    const argsEntities = [];
    for (const [name, path] of Object.entries(payload.request_entities)) {
      const resolved_value = get(args, path);
      if (isEmpty(resolved_value)) {
        throw new Error(`${name} is missing.`);
      }

      // Note: resolved_value needs to be always string but the right type is
      // set from Api backend.
      argsEntities.push({ name, resolved_value: String(resolved_value) });
    }

    set(payload, 'entities', argsEntities);
    return (await axios.post(getUrl('aimanager.apiRequests'), payload)).data;
  },

  async getCustomerById({ _, commit }, { customerId }) {
    const res = await axios.get(`${getUrl('conversations.customers')}/${customerId}/`, { params: { customerId } });
    commit('SET_CUTOMER_FROM_ROUTE', res.data);
  },

  async getConversationIdFromCustomer(_, { customerId }) {
    const res = await axios.get(`${getUrl('conversations.customers')}/${customerId}/conversations`, { params: { customerId } });
    return res.data.models[0].id;
  },

  async getConversation(_, { conversationId, customerId }) {
    const data = await axios.get(`${getUrl('conversations.customers')}/${customerId}/conversations`, { params: { ids: [conversationId] } });
    return data.data.models[0];
  },
};
