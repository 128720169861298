import COLORS from './colors';

const cobrowseVolumeConfig = {
  name: 'cobrowse_volume',
  title: 'Communication',
  metric: 'cobrowse_volume',
  lines: {
    cobrowse_volume: {
      label: 'Cobrowse sessions',
      position: 1,
      color: COLORS.green,
    },
    video_volume: {
      label: 'Video sessions',
      position: 2,
      color: COLORS.steel_blue,
    },
  },
  helpText: 'Displays the number of collaboration sessions',
};

export default cobrowseVolumeConfig;
