<template lang="pug">
  .aiq-function-box
    .aiq-function-names-box
      aiq-select(placeholder="/functions",
              size="small",
              v-model="selectedFunctionName")
          aiq-option(v-for="func in iqtoolsCommands",
                  :key="func.iqtoolsName"
                  :label="func.iqtoolsName"
                  :value="func.iqtoolsName")
      aiq-button.aiq-function-delete-btn(@click="remove" :disabled="disableClose")
        i.el-icon-close
    .aiq-function-parameters-box.select-box-link(v-if="hasArguments && paramsType !== 'singleObject' && paramsType !== 'stringArray'")
      aiq-select(placeholder="",
                 size="small",
                 :disabled="areParametersDisabled"
                 :multiple="paramsType === 'multiObject'",
                 :reserve-keyword="false"
                 v-model="selectedArguments")
        div(v-if="paramsType === 'bot'")
          aiq-option(:label="argumentList"
                     :value="argumentList")
        div(v-else-if="paramsType === 'bool'")
          aiq-option(:key="argumentList[0]"
                     :label="argumentList[0]"
                     :value="true")
          aiq-option(:key="argumentList[1]"
                     :label="argumentList[1]"
                     :value="false")
      
        div(v-else)
          aiq-option(v-for="param in argumentList",
                     :key="param.id"
                     :label="param.name"
                     :value="param.id")
    .aiq-function-parameters-box.select-box-link(v-if="hasArguments && paramsType === 'singleObject'")
      .key-val-arguments
        .key-val(v-for="(item, index) in keyValueArgsList")
          aiq-input.option-left(:model-value="item[0]" size='small' placeholder='entity or key'
                                @change="onKeyValChange($event, item[0], 'left', index)")
          span to
          aiq-input.option-right(:model-value="item[1]" size='small' placeholder='value'
                                 @change="onKeyValChange($event, item[1], 'right', index)")

          aiq-button.button-add-new-item(size="small" @click="onRemoveArg(index)")
            i.el-icon-minus
        .key-val
          aiq-button.button-add-new-item(size="small" @click="onNewField")
            i.el-icon-plus

    .aiq-function-parameters-box.select-box-link(v-if="hasArguments && paramsType === 'stringArray'")
      .keyword-list
        aiq-keyword-input(:list="stringArrayArgList"
                          :deletable="true"
                          @createItem="addStringArg"
                          @deleteItem="deleteStringArg")

</template>

<script>
import { createNamespacedHelpers } from 'vuex';
import get from 'lodash/get';
import clone from 'lodash/clone';

import { allCommandsBySystemName, commandsParamsTypes } from '@/libs';

const { mapState } = createNamespacedHelpers('buildingKit');
const { mapGetters } = createNamespacedHelpers('configs');

export default {
  name: 'aiq-function-box',
  props: {
    localFunctionId: {
      type: Number,
      required: true,
    },
    func: {
      type: Object,
      required: true,
    },
    disableClose: {
      type: Boolean,
      default: false,
    },
  },
  emits: ['selectedParams', 'selectedFunctionName', 'remove'],
  data() {
    return {
      allCommandsBySystemName,
      selectedFunctionParameters: this.func.params,
    };
  },
  mount() {
  },
  computed: {
    ...mapState(['commands']),
    ...mapGetters([
      'iqtoolsCommands',
      'iqtoolsEnabledCommandsLookup',
    ]),
    areParametersDisabled() {
      return !this.iqtoolsEnabledCommandsLookup[this.selectedFunctionName];
    },
    selectedArguments: {
      get() {
        return this._convertArgsForUI();
      },
      set(selectedArguments) {
        if (selectedArguments !== this.selectedArguments) {
          const info = {
            localFunctionId: this.localFunctionId,
            selectedParams: selectedArguments,
          };
          this.$emit('selectedParams', info);
        }
      },
    },
    selectedFunctionName: {
      get() {
        if (this.func.command_name) {
          return allCommandsBySystemName[this.selectedSystemName].iqtoolsName;
        }

        return '';
      },
      set(iqtoolsName) {
        if (iqtoolsName !== this.selectedFunctionName) {
          const selectedName = this.iqtoolsEnabledCommandsLookup[iqtoolsName];
          const info = {
            localFunctionId: this.localFunctionId,
            selectedName,
          };

          this.$emit('selectedFunctionName', info);
        }
      },
    },
    selectedFunctionDef() {
      return allCommandsBySystemName[this.selectedSystemName];
    },
    selectedSystemName() {
      return this.func.command_name;
    },
    paramsType() {
      if (this.selectedFunctionName === '') {
        return false;
      }

      return allCommandsBySystemName[this.func.command_name].paramsType;
    },
    hasArguments() {
      if (this.selectedFunctionName === '') {
        return false;
      }

      const typeOfFunction = allCommandsBySystemName[this.func.command_name].paramsType;

      return get(commandsParamsTypes, `${typeOfFunction}.hasArguments`);
    },
    argumentList() {
      return !this.selectedFunctionName ? [] : this.selectedFunctionDef.getParamsList(this.$store);
    },
    optionList() {
      // TODO: Implement option selection
      return [];
    },
    keyValueArgsList() {
      if (!this.func.params.arguments
          || !(this.func.params.arguments instanceof Array)
          || this.func.params.arguments.length === 0) {
        return [['', '']];
      }

      const args = [];
      for (const item of this.func.params.arguments) {
        const lValue = get(item, 'left_val', '');
        const rValue = get(item, 'right_val', '');
        args.push([lValue, rValue]);
      }

      return args;
    },
    stringArrayArgList() {
      return get(this.func, 'params.arguments', []);
    },
  },
  methods: {
    remove() {
      this.$emit('remove', this.localFunctionId);
    },
    _convertArgsForUI() {
      if (!this.func.command_name) {
        return [];
      }

      return commandsParamsTypes[this.paramsType].toComponentParams(this.func.params);
    },
    onKeyValChange(newVal, oldVal, position, index) {
      const newParamObj = clone(this.func.params);

      if (!newParamObj.arguments && index === 0) {
        newParamObj.arguments = [{ left_val: '', right_val: '' }];
      }

      const item = newParamObj.arguments[index];
      item[`${position}_val`] = newVal;

      this.sendParams(newParamObj);
    },
    onNewField() {
      const newParamObj = clone(this.func.params);
      if (!newParamObj.arguments) {
        newParamObj.arguments = [];
      }

      newParamObj.arguments.push({ left_val: '', right_val: '' });

      this.sendParams(newParamObj);
    },
    onRemoveArg(index) {
      const newParamObj = clone(this.func.params);

      newParamObj.arguments.splice(index, 1);

      if (newParamObj.arguments.length === 0) {
        newParamObj.arguments.push({ left_val: '', right_val: '' });
      }

      this.sendParams(newParamObj);
    },
    sendParams(params) {
      this.$emit('selectedParams', {
        localFunctionId: this.localFunctionId,
        selectedParams: params,
      });
    },

    addStringArg(keyword) {
      if (!this.stringArrayArgList.includes(keyword)) {
        this.sendParams({ arguments: [...this.stringArrayArgList, keyword] });
      }
    },

    deleteStringArg(keyword) {
      this.sendParams({ arguments: this.stringArrayArgList.filter(k => k !== keyword) });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../../../../styles/aiq-variables.scss";
@import "../../../../../../../../styles/aiq-mixins.scss";

.aiq-function-box {
  margin-bottom: 12px;
}

.search-field-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 46px;
  padding: 8px 0 8px 8px;

  [class^="iq-ico"] {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    z-index: 10;
  }
  .el-button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    min-height: 32px;
    font-size: 24px;
    line-height: 32px;
    padding: 0;
    border: 0;
  }
}
.aiq-function-names-box {
  position: relative;

  .aiq-function-delete-btn {
    position: absolute;
    right: 0;
    border: 0;
    font-size: 10px;
    color: #BFCBD9;
    background-color: transparent;
  }
}
.aiq-function-parameters-box {
  position: relative;
  margin: 8px 0 0 20px;
  display: flex;
  flex-flow: row nowrap;
  justify-content: flex-start;
  align-items: center;
  width: calc(100% - 20px);

  .aiq-function-delete-btn {
    flex: 0;
    min-width: 30px;
    text-align: center;
  }

  .el-tag {
    position: relative;
    background-color: $aiq-bgc-chip-default;
    display: inline-block;
    padding: 0 8px;
    height: 20px;
    line-height: 20px;
    font-size: 11px;
    font-weight: 300;
    color: #fff;
    border-radius: 15px;
    box-sizing: border-box;
    white-space: nowrap;

    &.el-tag--primary {
      background-color: $aiq-bgc-chip-team
    }
  }

  &.select-box-link {
    @include select-box-link(-8px, -10px, 10px, 24px);
  }

  .option-left {
    padding-right: 10px;
  }

  .option-right {
    padding-left: 10px;
  }

  .key-val {
    display: flex;
    margin-bottom: 10px;

  }

  .keyword-list {
    width: 100%;
  }
}
</style>
