import get from 'lodash/get';

export const INTERNAL_SEGMENT = {
  ANONYMOUS: 'anonymous',
  AUTHENTICATED: 'authenticated',
};


/**
 * Check if customer has only specified segment from internal segment.
 */
export const isSegment = (customer, segment) => {
  const customerSegments = get(customer, 'profile.customer_segment', '');
  const listNotIncluded = Object.values(INTERNAL_SEGMENT).filter(s => s !== segment);

  for (const unwanted of listNotIncluded) {
    if (customerSegments.includes(unwanted)) {
      return false;
    }
  }

  return customerSegments.includes(segment);
};

export const formatCustomerSegmentsForDisplay = (customerSegment) => {
  if (Array.isArray(customerSegment)) {
    customerSegment = customerSegment.join(', ');
  } else if (typeof customerSegment === 'string') {
    customerSegment = customerSegment.replace(/[{}]+/g, '').replace(',', ', ');
  }

  return customerSegment;
};
