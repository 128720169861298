export const makeDependencyAlertMsg = (parentName, res) => {
  const data = res.data;
  const suffix = 'Please remove from dependencies before deleting.';
  const list = [
    `${data.intents.length} intents`,
    `${data.dialogs.length} dialogs`,
    `${data.workflows.length} workflows`,
  ];

  const contents = list.map(item => `<li>${item}</li>`).join('');

  return {
    title: `Delete ${parentName}`,
    content: `${'<div>'
      + '<div>This '}${parentName.toLowerCase()} is being used in: </div> `
      + `<ul class='dependency-items-list'>${contents}</ul>`
      + `<div>${suffix}</div>`
      + '</div>',
  };
};

export const changeEntityTypeWarning = {
  title: 'Change Entity Type?',
  content: 'Changing the entity type can significantly change the behavior of the system. Are you sure you want to proceed?',
};
