import COLORS from './colors';

const conversationbyInteractionChartConfig = {
  name: 'conversations_by_interaction',
  title: 'Conversation Interaction',
  metric: 'conversations_by_interaction',
  lines: {
    agentonly: {
      label: 'Only Agents',
      position: 1,
      color: COLORS.tangerine,
    },
    botonly: {
      label: 'Only Bot',
      position: 2,
      color: COLORS.steel_blue,
    },
    customeronly: {
      label: 'Only Customers',
      position: 3,
      color: COLORS.lavender,
    },
    agentsbots: {
      label: 'Agents and Bot',
      position: 4,
      color: COLORS.bougainvillea,
    },
    botcustomer: {
      label: 'Bot and Customer',
      position: 5,
      color: COLORS.blood_red,
    },
    agentscustomer: {
      label: 'Agents and Customer',
      position: 6,
      color: COLORS.grass,
    },
    agentsbotcustomer: {
      label: 'Agents, Bot and Customer',
      position: 7,
      color: COLORS.orange,
    },
    nointeraction: {
      label: 'No interaction',
      position: 8,
      color: COLORS.celestial_blue,
    },
    total_convos: {
      label: 'Total',
      position: 9,
      color: COLORS.bottle_green,
      type: 'spline'
    },
  },
  helpText:
    'The Conversation Interaction graph shows all conversation interaction variations as a percentage of total conversations, totaling 100%. Hover over a bar to see detailed information for that date. Click export to export the data for the current view.',
};

export default conversationbyInteractionChartConfig;
