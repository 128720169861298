<template lang="pug">
aiq-tooltip(effect="light"
            placement="right"
            :show-after="500"
            popper-class="customers-dashboard-tooltip"
            :flip="true"
            v-model:visible="isVisible")
  template(v-if="conversationWasCreated" v-slot:content)
    .container
      .spinner(v-if="loading || !conversationData.length")
        font-awesome-icon(size="lg"
                          icon="spinner"
                          spin)
      template(v-else)
        aiq-row
          aiq-col.left-side(:sm="9")
            customer-info(:channel="conversation.channel"
                        :conversation="conversation"
                        :customer="item",
                        :tenant="tenantVariables"
                        :sidePanelConfig="sidePanelConfig"
                        :disable="true"
                        :includeDetails="false"
                        @updateProfile="updateProfile")
          aiq-col.right-side(:sm="15")
            tooltip-body(:conversationData="conversationData")
  template(v-else v-slot:content)
    .no-conversation-container
      div No conversation has been created for this customer yet
  slot(name="customerInfoItem")
</template>

<script>
import { BOT_EMAIL } from '@/constants/agent';
import { mapState } from 'vuex';
import get from 'lodash/get';
import CustomerInfo from '@/components/CustomerInfo/CustomerInfo.vue';
import TooltipBody from './TooltipBody.vue';

const NOT_AVAILABLE = {
  value: 'N/A',
  type: 'string',
};

const LAST_SENDER_TYPE = {
  customers: 'Customer',
  agents: 'Agent',
};

export default {
  name: 'TooltipInfo',
  props: {
    item: {
      type: Object,
      default: () => ({}),
    },
  },
  components: {
    CustomerInfo,
    TooltipBody,
  },
  data() {
    return {
      isVisible: false,
      loading: false,
      conversationData: [],
      conversation: {},
      conversationWasCreated: true,
    };
  },
  computed: {
    ...mapState({
      tenantVariables: state => state.configs.tenantVariables,
      sidePanelConfig: state => state.configs.config.side_panel,
    }),
  },
  watch: {
    isVisible(newIsVisible) {
      if (newIsVisible) {
        if (!this.item.last_chat_details) {
          this.conversationWasCreated = false;
          return [];
        }
        const { conversation_id, customer_id } = this.item.last_chat_details;
        this.loading = true;
        this.$store.dispatch('conversations/getConversation', {
          conversationId: conversation_id,
          customerId: customer_id,
        })
          .then((conversation) => {
            this.conversation = conversation;
            const { primaryAgent, agents, tags, categories, teams } = conversation;
            const assignedAgents = agents
              .filter(({ id, email }) => email !== BOT_EMAIL && id !== primaryAgent.id)
              .map((agent) => agent.fullName);
            this.loading = false;
            this.conversationData = [
              {
                label: 'Assigned Agents:',
                ...(assignedAgents.length ? {
                  value: assignedAgents,
                  type: 'list',
                } : NOT_AVAILABLE),
              },
              {
                label: 'Tags:',
                ...(tags.length ? {
                  value: tags.map(({ name }) => name),
                  type: 'tags',
                } : NOT_AVAILABLE),
              },
              {
                label: 'Categories:',
                ...(categories.length ? {
                  value: categories.map(({ name }) => name),
                  type: 'categories',
                } : NOT_AVAILABLE),
              },
              {
                label: 'Teams:',
                ...(teams.length ? {
                  value: teams.map(({ name }) => name),
                  type: 'teams',
                } : NOT_AVAILABLE),
              },
              {
                label: 'Last Sender',
                ...(get(this.item, 'latest_message.sender_type') ? {
                  value: LAST_SENDER_TYPE[get(this.item, 'latest_message.sender_type')],
                  type: 'string',
                } : NOT_AVAILABLE),
              },
              {
                label: 'Last Message',
                type: 'message',
                value: this.item.latest_message,
              },
            ];
          });
      }
    },
  },
};
</script>

<style lang="scss">
.customers-dashboard-tooltip.el-popper {
  max-width: 800px;
  .container {
    width: 800px;
    
    .spinner {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    .left-side {
      .customer-info {
        .customer-info-basic {
          // background: red;
          border-bottom: 0;

          .customer-info-basic_field {
            > label {
              color: #41474d;
              font-size: 12px;
              line-height: 14.4px;
              font-weight: 400;
            }
          }
        }
      }
    }
    .right-side {
      .row {
        padding: 5px 0;
        .segments {
          .el-tag {
            background-color: #f4f4f5;
            border-color: #e9e9eb;
            color: #909399;
          }
        }

        .value {
          font-weight: 100;
        }
        .center {
          display: flex;
          justify-content: center;
        }

        .message-wrapper.message-agent {
          padding-left: 0;
        }

        .message-container {
          padding: 0;
          width: 100%;

          .message-avatar {
            display: none;
          }

          .message-bubble {
            flex: 1;
            
            .message-bubble_header {
              .message-bubble_date {
                width: 100%;
              }
            }
          }
          .msg-suggestions-container {
            display: none;
          }
        }
      }
    }
  }

  .no-conversation-container {
    text-align: center;
    width: 350px;
  }
}
</style>
