import { createNamespacedHelpers } from 'vuex';
import { Component, Watch, Vue, toNative } from 'vue-facing-decorator';
import cloneDeep from 'lodash/cloneDeep';
import get from 'lodash/get';

import isEqual from 'lodash/isEqual';
import assign from 'lodash/assign';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import { WebchatConfig } from '@/components';
import { exportJsonFile } from '@/libs/fileUtils';

const { mapActions, mapState } = createNamespacedHelpers('configs');

const INFO = 'Configure OIDC settings for authenticated customer login.';
// TODO: Fetch this info once backend serves supported products
const idpProducts = [
  { value: 'okta', label: 'Okta' },
  { value: 'ping_federate', label: 'Ping Federate' },
  { value: 'ping_for_customer', label: 'Pingone For Customer' },
  { value: 'auth0', label: 'Auth0' },
];

const DEFAULT_AUTH_SELECTION = 'oidc';

@Component({
  name: 'OidcAuthentication',
  components: {
    ManagementPage,
    WebchatConfig,
  },
  computed: {
    ...mapState({
      savedOidcForm: state => state.integrations.authentication.value,
      savedSegmentForm: state => state.integrations.customer_segment,
      savedWellknownConfigForm: state => state.integrations.wellknown_config,
      webchatConfigs: state => state.channels.webchat.webchat_configs,
    }),
    pristineOidc() {
      return isEqual(this.localOidcForm, this.savedOidcForm);
    },
    pristineSegment() {
      return isEqual(this.localSegmentForm, this.savedSegmentForm);
    },
    pristineWellknownConfig() {
      return isEqual(this.localWellknownConfigForm, this.savedWellknownConfigForm);
    },
    AUTH_OPTIONS() {
      return [
        {
          label: 'OIDC',
          value: 'oidc',
        },
        {
          label: 'SAML',
          value: 'saml',
        },
        {
          label: 'OIDC-FORMPOST-WITH-AJAX',
          value: 'oidc-formpost-with-ajax',
        },
      ];
    },
    isOidcType() {
      return this.authType === 'oidc';
    },
  },
  methods: {
    ...mapActions([
      'loadAuthentication',
      'loadCustomerSegment',
      'loadWellKownConfiguration',
      'updateAuthentication',
      'updateCustomerSegment',
      'updateWellknownConfiguration',
    ]),
  },
})


class AuthenticationTab extends Vue {
  authType = DEFAULT_AUTH_SELECTION; // default selection
  fieldLocations = ['claim', 'profile'];
  newChips = '';
  isSecretVisble = false;
  productSelections = idpProducts;

  localOidcForm = {
    product: '',
    authority: '',
    client_id: '',
    client_secret: '',
    grant_type: '',
    post_logout_redirect_uri: '',
    redirect_uri: '',
    response_type: '',
    scope: '',
    use_pkce: false,
    use_saml: false,
    show_internal_openid_config: false,
    claims: {
      first_name_field: '',
      middle_name_field: '',
      last_name_field: '',
      email_field: '',
    },
    external_identity: {
      enabled: false,
      field_name: '',
      field_type: '',
    },
  };

  localSegmentForm = {
    source: {
      fields: [],
      field_name: '',
      field_location: 'claim',
    },
    enabled: false,
    segment_webchat_configs: [],
  };

  localWellknownConfigForm = {
    issuer: '',
    authorization_endpoint: '',
    token_endpoint: '',
    userinfo_endpoint: '',
    revocation_endpoint: '',
    end_session_endpoint: '',
  };

  selectedSegment = '';

  created() {
    this.$_info = INFO;
  }

  async mounted() {
    // Fetch Auth type setting
    try {
      const authConfig = await this.$store.dispatch('settings/getSetting', { name: 'auth_configuration' });
      this.authType = get(authConfig, 'auth_type', DEFAULT_AUTH_SELECTION);
    } catch (err) {
      this.$aiq.notify.error(`Failed: ${err.message}`);
    }

    this.localOidcForm = cloneDeep(this.savedOidcForm);
    this.localSegmentForm = cloneDeep(this.savedSegmentForm);
    this.localWellknownConfigForm = cloneDeep(this.savedWellknownConfigForm);
  }

  @Watch('savedOidcForm')
  syncSavedOidcForm(savedOidcForm) {
    this.localOidcForm = cloneDeep(savedOidcForm);
  }

  @Watch('savedSegmentForm')
  syncSavedSegmentForm(savedSegmentForm) {
    this.localSegmentForm = cloneDeep(savedSegmentForm);
  }

  @Watch('savedWellknownConfigForm')
  syncSavedWellknownConfigForm(savedWellknownConfigForm) {
    this.localWellknownConfigForm = cloneDeep(savedWellknownConfigForm);
  }

  @Watch('authType')
  async onAuthTypeChange(selection) {
    try {
      await this.$store.dispatch('settings/updateSetting', {
        name: 'auth_configuration',
        payload: {
          auth_type: selection,
        },
      });
      this.$aiq.notify.success('Updated');
    } catch (err) {
      this.$aiq.notify.error(`Failed: ${err.message}`);
    }
  }

  load() {
    this.loadAuthentication();
    this.loadCustomerSegment();
    this.loadWellKownConfiguration();
    this.$store.dispatch('configs/getWebchatConfigs');
  }

  resetOidcForm() {
    this.localOidcForm = cloneDeep(this.savedOidcForm);
  }

  resetSegmentForm() {
    this.localSegmentForm = cloneDeep(this.savedSegmentForm);
  }

  resetWellKnownForm() {
    this.localWellknownConfigForm = cloneDeep(this.savedWellknownConfigForm);
  }

  onSaveOidcForm() {
    return this.updateAuthentication(this.localOidcForm)
      .then(() => {
        this.$aiq.notify.success('Configuration is saved.');
      }).catch(() => {
        this.$aiq.notify.error('Error saving the configuration.');
      });
  }

  onSaveSegmentForm() {
    return this.updateCustomerSegment(this.localSegmentForm)
      .then(() => {
        this.$aiq.notify.success('Configuration is saved.');
      }).catch(err => {
        this.$aiq.notify.error('Error saving the configuration.', err);
      });
  }

  onSaveWellknownConfigForm() {
    return this.updateWellknownConfiguration(this.localWellknownConfigForm)
      .then(() => {
        this.$aiq.notify.success('Configuration is saved.');
      }).catch(err => {
        this.$aiq.notify.error('Error saving the configuration.', err);
      });
  }

  onAddFieldValue(val) {
    this.localSegmentForm.source.fields.push(val);
    this.localSegmentForm.segment_webchat_configs.push({
      name: val,
      webchat_configs: cloneDeep(this.webchatConfigs),
    });
  }

  onDeleteFieldValue(val) {
    const segmentForm = this.localSegmentForm;
    segmentForm.source.fields =
      segmentForm.source.fields.filter(f => f !== val);
    segmentForm.segment_webchat_configs =
      segmentForm.segment_webchat_configs.filter(item => item.name !== val);
  }

  onCustomConfigChange(name) {
    this.selectedSegment = name;
  }

  getSegmentConfig(name) {
    const segments_webchat_configs = this.localSegmentForm.segment_webchat_configs;
    return segments_webchat_configs.find(item => item.name === name);
  }

  getWebchatConfig() {
    const config = this.getSegmentConfig(this.selectedSegment);
    return config ? config.webchat_configs : null;
  }

  saveWebchatConfigs(newConfig) {
    const config = this.getSegmentConfig(this.selectedSegment);
    if (config) {
      config.webchat_configs = newConfig;
    }
    this.onCloseDialog();
  }

  onCloseDialog() {
    this.selectedSegment = '';
  }

  toggleSecretVisble() {
    this.isSecretVisble = !this.isSecretVisble;
  }

  exportConfig() {
    return exportJsonFile(this.localOidcForm, 'oidc_configuration.json');
  }

  async importConfig(data) {
    try {
      this.localOidcForm = assign({}, this.localOidcForm, JSON.parse(await data.raw.text()));
    } catch (err) {
      this.$aiq.notify.error(err.message);
    }
  }
}
export default toNative(AuthenticationTab);
