<template lang="pug">
  .onboarding
    .form-container
      .sub-header--solid On Boarding
      .form__inner-content
        .settings-container
          .header-config-container
            .field-label
              .name Introduction
              aiq-input-number(:model-value="introductionVersion"
                               size="small"
                               @change="onVersionChange('introduction', $event)"
                               :min="0.0"
                               :max="100.0"
                               :step="0.1")
            .sub-field(v-for="(item, index) in localOnboarding.introduction.pages")
              div(v-if="localOnboardingStrings.introduction.pages[index]")
                label.sub-label.small-font Screen {{ index + 1 }}
                label.sub-label.delete-font.pointer(@click="onDeleteScreen(index)") Delete
                .inline-label-box
                  label.inline-item.small-font Title
                  aiq-input(v-model="localOnboardingStrings.introduction.pages[index].header" placeholder="Title" size="small")
                .inline-label-box
                  label.inline-item.small-font Image
                  .upload-button
                    aiq-upload.no-margin(v-model="item.imageUrl"
                               :http-request="upload(index)"
                               :action="uploadUrl"
                               accept="image/*"
                               :headers="headers"
                               :show-file-list="false"
                               :before-upload="onHandleBeforeUpload"
                               :on-error="onUploadFailed"
                               :disabled="!uploadEnabled"
                               :on-success="onHandleUploadSuccess")
                      aiq-button(size="small" :disabled="!uploadEnabled") Select File
                    aiq-input(v-model="item.imageUrl" placeholder="Select File" size="small" disabled)
                .inline-label-box(v-if="item.imageUrl")
                  aiq-image.image(:src="item.imageUrl")
                .inline-label-box
                  label.inline-item.small-font Content
                  aiq-input(v-model="localOnboardingStrings.introduction.pages[index].content"
                            placeholder="Content"
                            size="small"
                            type="textarea")
            .add-screen
              i.el-icon-plus.pointer(@click='onAddScreen')

        .settings-container
          .header-config-container
            .field-label
              .name Terms and Conditions
              aiq-input-number(:model-value="tAndCVersion"
                               size="small"
                               @change="onVersionChange('t_and_c', $event)"
                               :min="0.0"
                               :max="100.0"
                               :step="0.1")
            .content
              aiq-input(v-model="localOnboardingStrings.t_and_c.t_and_c"
                        placeholder="Agreement"
                        size="small"
                        type="textarea")
        .settings-container
          .settings__item
            .save-buttons__container.settings__item
              aiq-button(:disabled="isConfigChanged"
                        @click="resetConfig") Cancel
              aiq-button.save__button(type="primary"
                                      :disabled="isConfigChanged"
                                      @click="saveConfig") Save


</template>

<script>
import isEqual from 'lodash/isEqual';
import get from 'lodash/get';
import set from 'lodash/set';
import cloneDeep from 'lodash/cloneDeep';
import { mapState } from 'vuex';
import { hosts } from '@/config/api.routes';
import { convertVersionToNumber } from '@/libs/formatters';
import axios from 'axios';

const getLangkeyReferences = (index) => ({
  header_langkey: `onboard.introduction.pages.${index}.header`,
  content_langkey: `onboard.introduction.pages.${index}.content`,
});
  
// TODO: File uploads for T & C and Image file changes
export default {
  name: 'onboarding',
  props: {
    onboarding: {
      type: Object,
      default: {
        introduction: {
          pages: [],
        },
        t_and_c: {
          tAndC: '',
        },
      },
    },
  },
  emits: ['save'],
  data() {
    return {
      localOnboarding: cloneDeep(this.onboarding),
      localOnboardingStrings: {
        introduction: {
          pages: [],
        },
        t_and_c: {
          t_and_c: '',
        },
      },
      uploadUrl: `${hosts.management}files/upload`,
      headers: {
        Authorization: this.$store.getters['agent/authToken'],
      },
      uploadEnabled: true,
      uploadingIndex: -1,
    };
  },
  watch: {
    webchatLanguageStrings: {
      handler(newVal) {
        const clone = cloneDeep(get(newVal, 'onboard', {}));
        // We need to ensure the new language page strings contains same array length of previus
        this.localOnboarding.introduction.pages.forEach((_, index) => {
          if (!clone.introduction.pages[index]) {
            clone.introduction.pages.push({
              header: '',
              content: '',
            });
          }
        });
        // Remove items from strings starting at the index of localOnboarding's length
        const startingIndex = this.localOnboarding.introduction.pages.length;
        const itemsCount = clone.introduction.pages.length - startingIndex;
        clone.introduction.pages.splice(startingIndex, itemsCount);
        this.localOnboardingStrings = clone;
      },
      deep: true,
    },
    onboarding(newVal) {
      this.localOnboarding = cloneDeep(newVal);
    },
  },
  computed: {
    ...mapState({
      webchatLanguageStrings: state => state.configs.language_strings,
    }),
    isConfigChanged() {
      return isEqual(this.localOnboarding, this.onboarding) &&
        isEqual(this.localOnboardingStrings, get(this.webchatLanguageStrings, 'onboard', {}));
    },
    tAndCVersion() {
      return convertVersionToNumber(get(this.localOnboarding, 't_and_c.version', 'v 0.0'));
    },
    introductionVersion() {
      return convertVersionToNumber(get(this.localOnboarding, 'introduction.version', 'v 0.0'));
    },
  },
  methods: {
    resetConfig() {
      this.localOnboarding = cloneDeep(this.onboarding);
      this.localOnboardingStrings = cloneDeep(get(this.webchatLanguageStrings, 'onboard', {}));
    },

    saveConfig() {
      this.$emit('save', { onboarding: this.localOnboarding, onboardingStrings: this.localOnboardingStrings });
    },

    onAddScreen() {
      this.localOnboarding.introduction.pages.push({
        imageUrl: '',
        header_langkey: '',
        content_langkey: '',
      });
      this.localOnboardingStrings.introduction.pages.push({
        header: '',
        content: '',
      });
      this.updateLankeyReferences();
    },

    onDeleteScreen(index) {
      this.localOnboarding.introduction.pages.splice(index, 1);
      this.localOnboardingStrings.introduction.pages.splice(index, 1);
      this.updateLankeyReferences();
    },

    updateLankeyReferences() {
      this.localOnboarding.introduction.pages.forEach((page, index) => {
        const { header_langkey, content_langkey } = getLangkeyReferences(index);
        page.header_langkey = header_langkey;
        page.content_langkey = content_langkey;
      });
    },

    upload(index) {
      return (data) => {
        this.uploadingIndex = index;
        // Move to common place
        const formData = new FormData();
        formData.append('file', data.file);
        formData.append('acl', 'public');
        return axios
          .post(this.uploadUrl,
            formData,
            { headers: { 'Content-Type': 'multipart/form-data' } })
          .then((res) => res.data);
      };
    },

    onHandleBeforeUpload() {
      this.uploadEnabled = false;
    },

    onHandleUploadSuccess(res) {
      if (this.uploadingIndex < 0 || !res.url) {
        return this.onUploadFailed();
      }

      this.localOnboarding.introduction.pages[this.uploadingIndex].imageUrl = res.url;
      this.resetUploading();
      this.$aiq.notify.success('Successful');
    },

    onUploadFailed() {
      this.$aiq.notify.error('Failed');
      this.resetUploading();
    },

    resetUploading() {
      this.uploadEnabled = true;
      this.uploadingIndex = -1;
    },

    onVersionChange(name, value) {
      set(this.localOnboarding, `${name}.version`, `v ${value}`);
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/aiq-variables.scss";

.form-container {
  flex: 1;
  margin-bottom: 20px;

  .form__inner-content {
    background: #FFFFFF;
    border: 1px solid #E0E6ED;
    display: flex;
    flex-direction: column;
    padding: 18px;

    * {
      margin: 5px 0px;
    }

    .delete-font {
      font-size: 10px;
      color: red;
      margin-left: 10px;
    }

    .no-margin {
      padding-right: 10px;
      margin: 0px;
    }

    .settings-container {
      display: flex;

      .settings__item {
        align-self: flex-start;
        flex-grow: 1;
        min-width: 589px;
      }
    }


    .field-label {
      color: #475669;
      letter-spacing: 0;
      font-family: $aiq-font-family;
      font-size: 14px;
      font-weight: 300;
      display: flex;
      justify-content: space-between;
    }

    .header-config-container {
      width: 482px;
    }

    .save-buttons__container {
      background-color: #fff;
      bottom: 0;
      display: flex;
      justify-content: flex-end;
      left: 0;
      margin-bottom: 0px;
      padding: 5px 8px 0px 0px;
      width: calc(100% - 4px);

      * {
        margin: 0px 5px;
      }

      .save__button {
        margin-right: 0px;
      }
    }
  }

}

.small-font {
  font-size: 12px;
}

.inline-label-box {
  display: flex;

  .inline-item {
    width: 150px;
  }
}

.image {
  height: 50px;
}

.pointer {
  cursor: pointer;
}

.upload-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
}

</style>
