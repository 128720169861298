<template lang="pug">
  .webchat-config
    .form-container
      .sub-header--solid Webchat Configuration
      .form__inner-content
        .settings-container
          .header-config-container.field-label Websocket Server
            aiq-input(v-model="localWebchatConfigs.configs.SOCKET_HOST"
                      placeholder="Web socker server such as https://socket-service.{ENV}.agentiq.co/"
                      size="small")

        .settings-container
          .header-config-container.field-label Targets
            aiq-select(@change="changeTargets" v-model="localWebchatConfigs.configs.targets" multiple placeholder="Select" :reserve-keyword="false")
              aiq-option(v-for="item in defaultOptions"
                        :key="item.value"
                        :label="item.label"
                        :value="item.value")

        .settings-container
          .header-config-container.field-label Title
            aiq-input(v-model="localWebchatConfigsStrings.configs.webchat_title"
                      placeholder="Title content here"
                      size="small"
                      type="textarea")

        .settings-container
          .header-config-container.field-label Greeting Message
            aiq-input(v-model="localWebchatConfigsStrings.configs.welcome_message"
                      placeholder="Greeting message here"
                      size="small"
                      type="textarea")

        .settings-container
          .header-config-container
            .field-label Theme
            aiq-input(v-model="localWebchatConfigs.theme.color['base-theme-primary']"
                      placeholder="Primary color"
                      size="small")
            aiq-input(v-model="localWebchatConfigs.theme.color['base-theme-secondary']"
                      placeholder="Secondary color"
                      size="small")

        .hamburger(v-for="ingredients  in hamburger")
          .settings-container
            .header-config-container
              div.field-label {{ ingredients.label }}
              .sub-field(v-for="(item, index)  in localWebchatConfigs.configs.hamburger[ingredients.key]")
                label.sub-label.small-font {{ item.description }}
                div
                  aiq-image(v-if="item.url", :src="item.url", :style="'width: 200px;'")
                .inline-label-box(v-if="localWebchatConfigsStrings.configs.hamburger[ingredients.key] && localWebchatConfigsStrings.configs.hamburger[ingredients.key][index]")
                  label.inline-item.small-font Display Name
                  aiq-input(v-model="localWebchatConfigsStrings.configs.hamburger[ingredients.key][index].label"
                            placeholder="Display Name"
                            size="small")
                .inline-label-box
                  label.inline-item.small-font Visibility
                  aiq-select(v-model="item.visibility" multiple placeholder="Select" :reserve-keyword="false")
                    aiq-option(v-for="item in channelOptions"
                              :key="item.value"
                              :label="item.label"
                              :value="item.value")
                .inline-label-box
                  label.inline-item.small-font Excluded Segments
                  aiq-select(v-model="item.excluded_segments" multiple placeholder="Select" :reserve-keyword="false")
                    aiq-option(v-for="item in segments"
                              :key="item"
                              :label="item"
                              :value="item")
                .inline-label-box(v-if="item.type === 'workflow'")
                  label.inline-item.small-font Workflow Name
                  aiq-select(v-model="item.workflow" placeholder="Workflow Name")
                    aiq-option(v-for="item in workflows"
                              :key="item"
                              :label="item"
                              :value="item")


        .settings-container
          .header-config-container
            .field-label Chat Icon
            label.sub-label.small-font {{ chatIconDescription }}
            .sub-field(v-for="(item, key) in localWebchatConfigs.theme.bubble")
              label.sub-label.small-font {{ $filters.toUpperFirst(item.label || key) }}
              .image-set(v-if="key !== 'location'")
                .inline-label-box
                  label.inline-item.small-font Background Color
                  aiq-input(v-model="item.background_color" placeholder="RGB/Hex Color" size="small")
                .inline-label-box
                  label.inline-item.small-font Image
                  .upload-button
                    aiq-upload.no-margin(v-model="item.image_url"
                                :http-request="upload(key)"
                                :action="uploadUrl"
                                accept="image/*"
                                :headers="headers"
                                :show-file-list="false"
                                :before-upload="onHandleBeforeUpload"
                                :disabled="!uploadEnabled"
                                :on-success="onHandleUploadSuccess"
                                ref="chatIconUploader")
                      aiq-button(size="small" :disabled="!uploadEnabled") Select File
                    aiq-input.image_url(v-model="item.image_url" placeholder="Select File" size="small" disabled)
                      template(v-if="item.image_url" v-slot:append)
                        aiq-button(class="p-0" @click="cleanBubbleImage(key)")
                          i.el-icon-close
                .inline-label-box(v-if="item.image_url")
                  aiq-image.image(:src="item.image_url" :key="item.image_url")
              .non-image-set(v-else)
                //- Currently it has only location
                aiq-select(v-model="localWebchatConfigs.theme.bubble[key]")
                  aiq-option(v-for="location in locationOptionsForWebchat"
                             :key="location"
                             :value="location"
                             :label="$filters.toUpperFirst(location)")

        alert-messages(v-if="showInterstitialConfig"
                      ref="alertMessages"
                      :segments="segments"
                      :localWebchatConfigsStrings="localWebchatConfigsStrings")

        .settings-container
          .header-config-container
            .field-label Feature Configuration
            .sub-field
              label Show Image Upload
              .inline-label-box
                label.inline-item.small-font Enabled
                aiq-switch(v-model="localWebchatConfigs.features.IMAGE_UPLOAD"
                           style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA")

            .sub-field
              label Show Close Button
              .inline-label-box
                label.inline-item.small-font Targets
                aiq-select(v-model="localWebchatConfigs.features.SHOW_CLOSE_BTN.visibility" multiple placeholder="Select" :reserve-keyword="false")
                  aiq-option(v-for="item in channelOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value")

            .sub-field
              label Show Minimize Button
              .inline-label-box
                label.inline-item.small-font Targets
                aiq-select(v-model="localWebchatConfigs.features.SHOW_MINIMIZE_BTN.visibility" multiple placeholder="Select" :reserve-keyword="false")
                  aiq-option(v-for="item in channelOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value")

            .sub-field
              label Show Logout Button
              .inline-label-box
                label.inline-item.small-font Targets
                aiq-select(v-model="localWebchatConfigs.features.SHOW_LOGOUT_BTN.visibility" multiple placeholder="Select" :reserve-keyword="false")
                  aiq-option(v-for="item in channelOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value")

            .sub-field
              label {{ $t('settings_tab.channels_tab.webchat_configuration.queue_transparency.title') }}
              .inline-label-box
                label.inline-item.small-font {{ $t('settings_tab.channels_tab.webchat_configuration.queue_transparency.targets') }}
                aiq-select(v-model="localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.visibility" multiple placeholder="Select")
                  aiq-option(v-for="item in channelOptions"
                            :key="item.value"
                            :label="item.label"
                            :value="item.value")
              .inline-label-box(v-if="showQueueMessageField")
                label.inline-item.small-font {{ $t('settings_tab.channels_tab.webchat_configuration.queue_transparency.define_queue_message') }}
                  aiq-tooltip(placement="top")
                    template(v-slot:content)
                      div(v-html="$t('settings_tab.channels_tab.webchat_configuration.queue_transparency.define_queue_message_tooltip')")
                    fa-icon.question-mark(:icon="['fas', 'circle-question']")
                aiq-input(v-model="localWebchatConfigsStrings.features.show_queue_transparency.display_message" placeholder="Write message" size="small")
              .inline-label-box
                label.inline-item.small-font {{ $t('settings_tab.channels_tab.webchat_configuration.queue_transparency.segment_priority_queue') }}
                aiq-switch(v-model="localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.segment_queue"
                           active-color="#30C28B"
                           inactive-color="#C0CCDA")
              .inline-label-box
                label.inline-item.small-font {{ $t('settings_tab.channels_tab.webchat_configuration.queue_transparency.leave_queue') }}
                  aiq-tooltip(placement="top")
                    template(v-slot:content)
                      div(v-html="$t('settings_tab.channels_tab.webchat_configuration.queue_transparency.leave_queue_tooltip')")
                    fa-icon.question-mark(:icon="['fas', 'circle-question']")
                aiq-switch(v-model="localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue"
                           active-color="#30C28B"
                           inactive-color="#C0CCDA")
              .inline-label-box(v-if="localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue", :class="{'is-error': v$.localWebchatConfigsStrings.features.show_queue_transparency.leave_queue_prompt.$error }")
                label.inline-item.small-font {{ $t('settings_tab.channels_tab.webchat_configuration.queue_transparency.leave_queue_prompt') }}
                .d-flex-column
                  aiq-input(v-model="localWebchatConfigsStrings.features.show_queue_transparency.leave_queue_prompt"
                            size="small"
                            name="queueTransparency.leave_queue_prompt")
                  span.text-danger(v-show="v$.localWebchatConfigsStrings.features.show_queue_transparency.leave_queue_prompt.$error") {{ v$.localWebchatConfigsStrings.features.show_queue_transparency.leave_queue_prompt.$errors[0]?.$message }}
              .inline-label-box(v-if="localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue", :class="{'is-error': v$.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue_workflow.$error}")
                label.inline-item.small-font {{ $t('settings_tab.channels_tab.webchat_configuration.queue_transparency.leave_queue_workflow') }}
                  aiq-tooltip(placement="top")
                    template(v-slot:content)
                      div(v-html="$t('settings_tab.channels_tab.webchat_configuration.queue_transparency.leave_queue_workflow_tooltip')")
                    fa-icon.question-mark(:icon="['fas', 'circle-question']")
                .d-flex-column
                  aiq-select(v-model="localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue_workflow" 
                            :placeholder="$t('global.select')" 
                            name="queueTransparency.leave_queue_workflow")
                    aiq-option(v-for="item in workflows"
                              :key="item"
                              :label="item"
                              :value="item")
                  span.text-danger(v-show="v$.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue_workflow.$error") {{ v$.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue_workflow.$errors[0]?.$message }}
            .sub-field
              label Block Chat on Time Drift
              .inline-label-box
                label.inline-item.small-font Enabled
                aiq-switch(v-model="localWebchatConfigs.features.BLOCK_TIME_DRIFT.enabled"
                           style="--aiq-switch-on-color: #30C28B; --aiq-switch-off-color: #C0CCDA")
              .inline-label-box
                label.inline-item.small-font Acceptable Time Drift(in seconds)
                aiq-input(v-model.number="localWebchatConfigs.features.BLOCK_TIME_DRIFT.tolerable_time" placeholder="Select File" size="small")
              .inline-label-box
                label.inline-item.small-font Blocked Message
                aiq-input(v-model="localWebchatConfigsStrings.features.block_time_drift.display_message" placeholder="Message" size="small")

        .settings-container
          .settings__item
            .save-buttons__container.settings__item
              aiq-button(:disabled="isWebchatConfigChanged"
                        @click="resetWebchatConfig") Cancel
              aiq-button.save__button(type="primary"
                                      :disabled="isWebchatConfigChanged"
                                      @click="saveWebchatConfigs") Save

</template>

<script>
import isEqual from 'lodash/isEqual';
import cloneDeep from 'lodash/cloneDeep';
import difference from 'lodash/difference';
import set from 'lodash/set';
import get from 'lodash/get';
import inRange from 'lodash/inRange';
import alertMessages from './AlertMessages.vue';
import { HAMBURGER_MENU_SELECTION_OPTIONS } from '@/constants/settings';
import { hosts } from '@/config/api.routes';
import { mapState } from 'vuex';
import { CHAT_ICON_RESOLUTION } from '@/constants';
import axios from 'axios';
import { useVuelidate } from '@vuelidate/core';
import { requiredIf } from '@vuelidate/validators';
  
export default {
  name: 'webchat-config',
  components: {
    alertMessages,
  },
  setup () {
    return { v$: useVuelidate() };
  },
  validations () {
    return {
      localWebchatConfigs: {
        features: {
          SHOW_QUEUE_TRANSPARENCY: {
            visibility: { $autoDirty: true },
            display_message: { $autoDirty: true },
            segment_queue: { $autoDirty: true },
            leave_queue: { $autoDirty: true },
            leave_queue_prompt: { $autoDirty: true },
            leave_queue_workflow:{ 
              requiredIf: requiredIf(this.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue), 
              $autoDirty: true 
            },
          }
        }
      },
      localWebchatConfigsStrings: {
        features: {
          show_queue_transparency: {
            leave_queue_prompt: {
              requiredIf: requiredIf(this.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.leave_queue), 
              $autoDirty: true 
            }
          }
        }
      }
    };
  },
  props: {
    webchatConfigs: {
      type: Object,
    },
    segments: {
      type: Array,
      default: () => [],
    },
    workflows: {
      type: Array,
      default: () => [],
    },
  },
  emits: ['save'],
  data() {
    return {
      localWebchatConfigsStrings: {
        configs: {
          webchat_title: '',
          welcome_message: '',
          hamburger: {
            menu: [],
          },
        },
        features: {
          block_time_drift: {
            display_message: '',
          },
          show_queue_transparency: {
            display_message: '',
            leave_queue_prompt: '',
          },
        },
        banner_and_interstitial_messages: {},
      },
      localWebchatConfigs: this.cloneLocalWebchatConfigs(this.webchatConfigs),
      channelOptions: cloneDeep(HAMBURGER_MENU_SELECTION_OPTIONS),
      defaultOptions: cloneDeep(HAMBURGER_MENU_SELECTION_OPTIONS),
      hamburger: [
        {
          label: 'Hamburger Menu',
          key: 'menu',
        },
        { label: 'Legal Images',
          key: 'images',
        },
      ],
      uploadingKey: null,
      uploadUrl: `${hosts.management}files/upload`,
      uploadEnabled: true,
      headers: {
        Authorization: this.$store.getters['agent/authToken'],
      },
    };
  },
  watch: {
    webchatLanguageStrings: {
      handler(newVal) {
        this.localWebchatConfigsStrings = cloneDeep(get(newVal, 'webchat', {}));
      },
      deep: true,
    },
    webchatConfigs(newVal) {
      this.localWebchatConfigs = this.cloneLocalWebchatConfigs(newVal);
    },
  },
  computed: {
    ...mapState({
      webchatLanguageStrings: state => state.configs.language_strings,
      showInterstitialConfig: state => state.featureFlags.FEATURE_FLAGS.INTERSTITIAL_CONFIGURATION,
    }),

    isAlertMessageChanged() {
      return !isEqual(this.$refs.alertMessages.alertMessage, this.$store.getters['alertMessages/alertMessageModel']);
    },

    isWebchatConfigChanged() {
      return isEqual(this.localWebchatConfigs, this.webchatConfigs) &&
          isEqual(this.localWebchatConfigsStrings, get(this.webchatLanguageStrings, 'webchat', {})) &&
          !this.isAlertMessageChanged;
    },

    locationOptionsForWebchat() {
      return ['left', 'right'];
    },

    showQueueMessageField() {
      return difference(this.channelOptions.map(x => x.value),
        this.localWebchatConfigs.features.SHOW_QUEUE_TRANSPARENCY.visibility).length !== 0;
    },
    chatIconDescription() {
      const { min, max } = CHAT_ICON_RESOLUTION;
      return this.$t(
        'settings_tab.channels_tab.webchat_configuration.chat_icon.description',
        {
          minWidth: min.width,
          minHeight: min.height,
          maxWidth: max.width,
          maxHeight: max.height,
        },
      );
    },
  },
  methods: {
    resetWebchatConfig() {
      this.localWebchatConfigs = this.cloneLocalWebchatConfigs(this.webchatConfigs);
      this.localWebchatConfigsStrings = cloneDeep(get(this.webchatLanguageStrings, 'webchat', {}));
      this.$refs.alertMessages.cleanForm();
    },

    async saveWebchatConfigs() {
      if (!(await this.v$.$validate())) {
        this.$aiq.notify.error(
          this.$t('settings_tab.channels_tab.webchat_configuration.unable_save_error'),
        );
        return;
      }
      this.$emit('save', {
        config: this.localWebchatConfigs,
        configStrings: this.localWebchatConfigsStrings,
        ...(this.isAlertMessageChanged ? { alertMessage: this.$refs.alertMessages.alertMessage } : {}),
      });
    },

    changeTargets(changedItems) {
      const menu = this.localWebchatConfigs.configs.hamburger.menu;
      for (const m of menu) {
        m.visibility = m.visibility.filter(item => changedItems.includes(item));
      }

      this.channelOptions = cloneDeep(HAMBURGER_MENU_SELECTION_OPTIONS
        .filter(m => changedItems.includes(m.value)));
    },

    upload(key) {
      return async (data) => {
        this.uploadingKey = key;
        const formData = new FormData();
        formData.append('file', data.file);
        formData.append('acl', 'public');
        try {
          const res = await axios
            .post(this.uploadUrl,
              formData,
              { headers: { 'Content-Type': 'multipart/form-data' } });
          return res.data;
        } catch (err) {
          this.onUploadFailed(err.data.error);
        }
      };
    },

    async onHandleBeforeUpload(file) {
      const { min, max } = CHAT_ICON_RESOLUTION;
      const objectUrl = URL.createObjectURL(file);
      let errorMsg = '';
      this.uploadEnabled = false;

      const imageSize = await new Promise((resolve) => {
        const img = new Image();
        img.onload = () => resolve({ height: img.height, width: img.width });
        img.src = objectUrl;
      });

      URL.revokeObjectURL(objectUrl);

      if (
        inRange(imageSize.width, min.width, max.width + 1) &&
          inRange(imageSize.height, min.height, max.height + 1)
      ) {
        return true;
      }

      if (imageSize.width < min.width || imageSize.height < min.height) {
        errorMsg = this.$t(
          'settings_tab.channels_tab.webchat_configuration.chat_icon.error_smaller',
          {
            imgWidth: imageSize.width,
            imgHeight: imageSize.height,
            minWidth: min.width,
            minHeight: min.height,
          },
        );
      } else {
        errorMsg = this.$t(
          'settings_tab.channels_tab.webchat_configuration.chat_icon.error_larger',
          {
            imgWidth: imageSize.width,
            imgHeight: imageSize.height,
            maxWidth: max.width,
            maxHeight: max.height,
          },
        );
      }
      this.onUploadFailed(errorMsg);
      return false;
    },

    onHandleUploadSuccess(res) {
      if (!this.uploadingKey || !res.url) {
        return this.onUploadFailed();
      }

      this.localWebchatConfigs.theme.bubble[this.uploadingKey].image_url = res.url;
      this.resetUploading();
      this.$aiq.notify.success('Successful');
    },

    onUploadFailed(errorMsg = 'Failed') {
      this.$aiq.notify.error(errorMsg);
      this.resetUploading();
      this.$refs.chatIconUploader.clearFiles();
    },

    resetUploading() {
      this.uploadEnabled = true;
      this.uploadingKey = null;
    },

    cleanBubbleImage(key) {
      this.localWebchatConfigs.theme.bubble[key].image_url = '';
    },

    cloneLocalWebchatConfigs(newConfig) {
      const newLocalWebchatConfigs = cloneDeep(newConfig);
      set(newLocalWebchatConfigs, 'theme.bubble.open.label', 'Open Chat Icon');
      set(newLocalWebchatConfigs, 'theme.bubble.close.label', 'Close Chat Icon');
      return newLocalWebchatConfigs;
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../styles/aiq-variables.scss";

.form-container {
  flex: 1;
  margin-bottom: 20px;

  .form__inner-content {
    background: #FFFFFF;
    border: 1px solid #E0E6ED;
    display: flex;
    flex-direction: column;
    padding: 18px;

    * {
      margin: 5px 0px;
    }

    .no-margin {
      padding-right: 10px;
      margin: 0px;
    }

    .settings-container {
      display: flex;

      .settings__item {
        align-self: flex-start;
        flex-grow: 1;
        min-width: 589px;
      }
    }


    .field-label {
      color: #475669;
      letter-spacing: 0;
      font-family: $aiq-font-family;
      font-size: 14px;
      font-weight: 300;
    }

    .header-config-container {
      width: 482px;
    }

    .save-buttons__container {
      background-color: #fff;
      bottom: 0;
      display: flex;
      justify-content: flex-end;
      left: 0;
      margin-bottom: 0px;
      padding: 5px 8px 0px 0px;
      width: calc(100% - 4px);

      * {
        margin: 0px 5px;
      }

      .save__button {
        margin-right: 0px;
      }
    }

    .image_url.el-input {
      display: inline-table;
    }
  }

}

.small-font {
  font-size: 12px;
}

.inline-label-box {
  display: flex;

  .inline-item {
    width: 150px;
  }
}

.image {
  height: 30px;
}

.pointer {
  cursor: pointer;
}

.upload-button {
  width: 100%;
  display: flex;
  justify-content: space-between;
}
.p-0.el-button {
  padding: 0;
  margin: 2px 0px;
}
.inline-label-box {
  span.text-danger {
    height: 0;
    margin-top: -5px;
    color: #F56C6C;
    font-size: 12px;
  }
  svg.question-mark {
    position: absolute;
    margin-left: 5px;
    margin-top: 4px;
    &:hover {
      color: $aiq-bgc-header;
    }
    &:focus {
      outline: none;
    }
  }
}
.d-flex-column {
  display: flex;
  flex-direction: column;
  flex-basis: 100%;
}
</style>
<style lang="scss">
.inline-label-box {
  &.is-error .el-input__inner, &.is-error .el-textarea__inner {
    border-color: #ff4949 !important;
  }
}
</style>
