import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import {
  selectedBreakdownOption,
  datePickerDefaultOptions,
  getDiffDays,
  getDefaultDateRange,
  getNonReactiveDateRange,
} from '../configs/dateRange';
import get from 'lodash/get';
import isEmpty from 'lodash/isEmpty';
import isEqual from 'lodash/isEqual';
import debounce from 'lodash/debounce';
import moment from 'moment';
import { mapState } from 'vuex';
import round from 'lodash/round';
import { loadFilterOptions } from '../helpers/filterHelper';

export default {
  name: 'performanceMixin',
  components: {
    ManagementPage,

  },
  watch: {
    dateRange: debounce(async function onDateRangeChange(newValue, oldValue) {
      if (newValue !== oldValue) {
        await this.loadList();
        await loadFilterOptions(this, this.teamsEndpoint);
        this.setDateRange(newValue);
      }
    }, 1),
    searchTerm() {
      this.loadList();
    },
  },
  computed: {
    ...mapState({
      currentAgentID: state => state.agent.profile.id,
      currentAgentFname: state => state.agent.profile.profile.first_name || '',
      currentAgentLname: state => state.agent.profile.profile.last_name || '',
      currentAgentRoles: state => state.agent.profile.roles,

    }),
    showCurrentAgentOnly() {
      return isEqual(this.currentAgentRoles, ['agent']);
    },
    firstAgentPerf() {
      return get(this.performanceData, '0', {});
    },
    datePickerOptions() {
      return datePickerDefaultOptions();
    },
  },
  data() {
    return {
      performanceData: [],
      searchTerm: '',
      decimalPrecision: 2,
      agentsPerformanceTitle: 'Performance Metrics',
      myTabTitle: 'My Performance',
      dateRange: getDefaultDateRange(),
      selectedBreakdownOption: selectedBreakdownOption(),
      loading: true,
      fetchForCurrentAgent: false,
      selectedFilters: [],
      teamsEndpoint: 'all_teams',
      agentsTeams: 'agents_teams',
      duration: '',
      durationTooltip: '',
    };
  },
  async mounted() {
    this.setDateRange(getDefaultDateRange());
    await this.loadList();
    await loadFilterOptions(this, this.teamsEndpoint);
  },
  methods: {
    getAgentName(datapoint) {
      return `${datapoint.first_name || ''} ${datapoint.last_name || ''}`;
    },
    getNumberOfConversations(datapoint) {
      return datapoint.total_conversations || 0;
    },
    getConversationsPerHour(datapoint) {
      const value = datapoint.conversations_per_hour < 1 ? '< 1' : round(datapoint.conversations_per_hour,
        this.decimalPrecision);
      return value || 0;
    },
    getMessagesPerHour(datapoint) {
      const value = datapoint.messages_per_hour < 1 ? '< 1' : round(datapoint.messages_per_hour,
        this.decimalPrecision);
      return value || 0;
    },
    getRatings(datapoint) {
      return datapoint.total_ratings || 0;
    },
    getMedianRating(datapoint) {
      return round(datapoint.median_rating_value, this.decimalPrecision) || 'N/A';
    },
    getAvgRating(datapoint) {
      return round(datapoint.average_rating_value, this.decimalPrecision) || 'N/A';
    },
    getNumberOfMessages(datapoint) {
      return datapoint.total_messages || 0;
    },
    getnumberOfCustomers(datapoint) {
      return datapoint.number_of_customers || 0;
    },
    getnumberOfReturningCustomers(datapoint) {
      return datapoint.returning_customers || 0;
    },
    getAvgFRT(datapoint) {
      return datapoint.average_first_response_time || '';
    },
    getMedianFRT(datapoint) {
      return datapoint.median_first_response_time || '';
    },
    async loadList() {
      const [startDate, endDate] = getNonReactiveDateRange(this.dateRange);
      const periodStart = moment(startDate).unix();
      const periodEnd = moment(endDate).unix();
      if (!(periodStart && periodEnd)) {
        return;
      }
      const params = {
        period_start: periodStart,
        period_end: moment.unix(periodEnd).endOf('day').unix(),
        bucket: this.selectedBreakdownOption.value,
      };
      let filters = {};
      if (this.showCurrentAgentOnly || this.fetchForCurrentAgent) {
        filters = { agents: [this.currentAgentID] };
      }
      if (!isEmpty(this.selectedFilters)) {
        const teamsWrap = get(this.selectedFilters, '0', [this.agentsTeams]);
        const teams = get(teamsWrap, this.agentsTeams);
        const agentTeams = [];
        for (let i = 0; i < teams.length; i += 1) {
          agentTeams.push(teams[i]);
        }
        if (!isEmpty(agentTeams)) {
          filters[this.agentsTeams] = agentTeams;
        }
      }
      if (!isEmpty(filters)) {
        params.filters = [filters];
      }
      if (!isEmpty(this.searchTerm)) {
        params.query = this.searchTerm;
      }
      return this.$store
        .dispatch('metrics/getMetric', ['banker_performance', params])
        .then(data => {
          this.loading = false;
          this.performanceData = get(data, 'dataset.0.data_points', []);
        });
    },
    setDateRange(range) {
      this.duration = `Over ${getDiffDays(range)} days`;
      this.durationTooltip = ` over ${getDiffDays(range)} days.`;
    },
  },
};
