import COLORS from './colors';

const conversationsChartConfig = {
  name: 'conversations',
  title: 'Conversation Activity',
  metric: 'conversations',
  lines: {
    user_initiated: {
      label: 'Agent Initiated',
      position: 1,
      color: COLORS.yellow,
    },
    customer_initiated: {
      label: 'Customer Initiated',
      position: 2,
      color: COLORS.red,
    },
    bot_initiated: {
      label: 'Bot Initiated',
      position: 3,
      color: COLORS.orange,
    },
    total: {
      label: 'Total Conversations',
      position: 4,
      color: COLORS.blue,
      type: 'spline',
    },
  },
  helpText:
    'Displays all conversation activity, which when totaled, equals total conversations.',
};

export default conversationsChartConfig;
