<template lang="pug">
  .filter-container
    .no-search(v-if="showBackArrow")
      .back-to-conversation(@click="$emit('back')")
        i.iq-ico-arrow
        span Back to Conversations

    .search-field-container(v-else)
      aiq-checkbox-group(:model-value="selectedStatusFilters"  size="small")
        aiq-tooltip(effect="dark", content="Show unread conversations", placement="top", :show-after="1000" v-if="!inAllTeamTab")
          aiq-checkbox-button(label="unread"
                              value="unread"
                              @change="onChange")
            | Unread &nbsp {{ counters.unread }}
        aiq-tooltip(effect="dark", content="Show Watch List", placement="top", :show-after="1000" v-if="!inAllTeamTab")
          aiq-checkbox-button(label="starred"
                              value="starred"
                              @change="onChange")
            | Watch List &nbsp {{ counters.starred }}
      aiq-tooltip(effect="dark", content="Search and Filter", placement="top", :show-after="1000")
        aiq-button(@click="toggleOpen", :class="{'closed': !opened}") &#8942;
    .full-filter-container(v-if="opened")
      .inline-intro
      aiq-input(:id="$_IDS.CONV_SEARCH"
                v-model="filter.query"
                placeholder="Search name, category, tag, or segment"
                size="small")
      .filter-row
        aiq-input#filter-search-content(size="small", placeholder="Search message content", v-model="filter.message_content")
      .filter-row
        aiq-date-picker(
            v-model="filter.dateRange.start_date"
            @change="applyFilterAfterDateStartChange"
            size="small"
            type="datetime"
            placeholder="Start date"
            format="YYYY/MM/DD hh:mm:ss a"
            popper-class="messages-search__date-picker-popup"
            :clear-icon="Icon.CircleCloseFilled"
            class="conversations-date-picker")
      .filter-row
        aiq-date-picker(
            v-model="filter.dateRange.end_date"
            @change="applyFilterAfterDateEndChange"
            size="small"
            type="datetime"
            placeholder="End date"
            popper-class="messages-search__date-picker-popup"
            format="YYYY/MM/DD hh:mm:ss a"
            :clear-icon="Icon.CircleCloseFilled"
            class="conversations-date-picker")
      .filter-row
        aiq-select(@change="applyFilter"
                    v-model="filter.team_id"
                    filterable
                    clearable
                    size="small"
                    placeholder="Team"
                    popper-class="select-popup-team"
                    :clear-icon="Icon.CircleCloseFilled"
                    :suffix-icon="Icon.CaretBottom"
                    )
          aiq-option(v-for="team in teams"
                      :key="team.id"
                      :label="team.name"
                      :value="team.id")
        aiq-select(@change="applyFilter"
                    v-model="filter.agent_id"
                    filterable
                    clearable
                    size="small"
                    placeholder="Agent"
                    popper-class="select-popup-agent"
                    :clear-icon="Icon.CircleCloseFilled"
                    :suffix-icon="Icon.CaretBottom"
                    )
          aiq-option(v-for="agent in agents"
                      :key="agent.id"
                      :label="agent.fullName"
                      :value="agent.id")
      .filter-row
        .checkbox-container
          aiq-checkbox(
            label="Queued",
            name="type",
            @change="applyFilter",
            v-model="filter.queued")
        .checkbox-container
          aiq-checkbox(
            label="Unresponded",
            name="unresponded",
            @change="applyFilter",
            v-model="filter.unresponded")
      .filter-row
        .checkbox-container
          aiq-checkbox(
            label="Served By AI",
            name="ai_served",
            @change="applyFilter",
            v-model="filter.ai_served")
        .checkbox-container
          aiq-checkbox(
            label="Served By Agents",
            name="agent_served",
            @change="applyFilter",
            v-model="filter.agent_served")
      .filter-row
        .checkbox-container
          aiq-checkbox(
            label="Active",
            name="status",
            @change="applyFilter",
            v-model="filter.active")
        .checkbox-container
          aiq-checkbox(
            label="Unlocked",
            name="unlocked",
            @change="applyFilter",
            v-model="filter.unlocked")
      .filter-row(v-if="!inAllTeamTab")
        .checkbox-container
          aiq-checkbox(
            label="Primary Agent",
            name="type",
            @change="applyFilter",
            v-model="filter.i_am_primary")
      .filter-row
        aiq-button.clear-filter-button(v-if="canView('/conversations/left_panel/jump_to_conversation')"
                                       @click="showJumpToUI") Jump to
        aiq-dialog(title="Jump to Conversation"
                   :model-value="showJumpToPopUp"
                   :show-close="false")
          aiq-input(placeholder="Lynq ID"
                    v-model="jumpToCustomerId"
                    type="number"
                    @keyup.enter="goToConversation")
          template(v-slot:footer)
            .dialog-footer
              aiq-button(@click="closeJumpToUI") Cancel
              aiq-button(@click="goToConversation")  Go
        .checkbox-container
        aiq-button.clear-filter-button(@click="clearFilter", :disabled="!isFilled") Clear filter
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import moment from 'moment';
import { getInputKeyupSubject } from '@/libs';
import cloneDeep from 'lodash/cloneDeep';
import { IDS } from '../conversationConstants';

const DEBOUNCE_TIMEOUT = 3000;

const filter = {
  query: '',
  message_content: '',
  dateRange: { start_date: null, end_date: null },
  team_id: null,
  agent_id: null,
  opened: false,
  queued: false,
  unresponded: false,
  active: false,
  unlocked: false,
  ai_served: false,
  agent_served: false,
  filter_type: null,
};

const setupDateFilter = (newVal) => ({
  start_date: newVal ? toIsoString(newVal[0]) : null,
  end_date: newVal ? toIsoString(newVal[1]) : null,
});

const toIsoString = (date) => moment.utc(new Date(date)).toISOString();

export default {
  name: 'conversationsFilter',
  data() {
    return {
      filter: cloneDeep(filter),
      dateRangeValues: [],
      startDate: '',
      endDate: '',
      searchConversationSubscription: null,
      searchContentSubscription: null,
      showJumpToPopUp: false,
      jumpToCustomerId: '',
    };
  },
  props: {
    opened: {
      type: Boolean,
      default: () => false,
    },
    appliedFilter: {
      type: Object,
      default: () => filter,
    },
    selectedStatusFilters: {
      type: Array,
      default: () => [],
    },
    showBackArrow: {
      type: Boolean,
      default: () => false,
    },
  },
  emits: ['open', 'applyFilter', 'select'],
  computed: {
    ...mapState({
      counters: state => state.conversations.counters,
      scope: state => state.conversations.scope,
      selectedTab: state => state.conversations.selectedTab,
    }),
    ...mapGetters({
      agents: 'agents/sortedList',
      teams: 'teams/sortedTeams',
    }),
    inAllTeamTab() {
      return this.selectedTab === 'all' || this.selectedTab === 'team';
    },
    inTeamTab() {
      return this.selectedTab === 'team';
    },
    isFilled() {
      return this.filter.query !== ''
       || this.filter.message_content !== ''
       || this.filter.team_id !== null
       || this.filter.agent_id !== null
       || Object.values(this.filter.dateRange).filter(Boolean).length > 0
       || this.filter.queued
       || this.filter.unresponded
       || this.filter.unlocked
       || this.filter.active
       || this.filter.ai_served
       || this.filter.agent_served;
    },
  },
  watch: {
    'filter.team_id': function teamIdWatch(newVal) {
      this.filter.team_id = !newVal ? null : newVal;
    },
    'filter.agent_id': function agentIdWatch(newVal) {
      this.filter.agent_id = !newVal ? null : newVal;
    },
    dateRangeValues(newVal) {
      this.filter.dateRange = setupDateFilter(newVal);
    },
    appliedFilter(newVal) {
      this.filter = { ...this.filter, ...newVal };
    },
    opened(newVal) {
      if (newVal) {
        this.registerInputs();
      } else {
        this.unRegisterInputs();
      }
    },
  },
  methods: {
    toggleOpen() {
      this.$emit('open', !this.opened);
    },

    applyFilterAfterDateStartChange(neStartDate) {
      this.setupDateStartEndFilter(neStartDate, null);
      this.applyFilter();
    },

    applyFilterAfterDateEndChange(newEndDate) {
      this.setupDateStartEndFilter(null, newEndDate);
      this.applyFilter();
    },

    setupDateStartEndFilter(startDatenewVal, endDatenewVal) {
      // eslint-disable-next-line max-len
      this.filter.dateRange.start_date = startDatenewVal ? toIsoString(startDatenewVal) : this.filter.dateRange.start_date;
      // eslint-disable-next-line max-len
      this.filter.dateRange.end_date = endDatenewVal ? toIsoString(endDatenewVal) : this.filter.dateRange.end_date;
    },

    applyFilter() {
      this.$emit('applyFilter', this.filter);
    },

    clearFilter() {
      this.filter = cloneDeep(filter);
      this.dateRangeValues = [];
      this.startDate = '';
      this.endDate = '';
      this.applyFilter();
    },

    showJumpToUI() {
      this.showJumpToPopUp = true;
    },

    async goToConversation() {
      const customerId = this.jumpToCustomerId;
      this.closeJumpToUI();
      if (!customerId) {
        // Do nothing if it is empty string.
        return;
      }
      try {
        this.$router.push({
          name: 'ConversationByCustomerId',
          params: { id: customerId },
        });
      } catch (err) {
        this.$aiq.notify.error(`Unable to route to the customer ${customerId}`);
      }
    },

    closeJumpToUI() {
      this.showJumpToPopUp = false;
      this.jumpToCustomerId = '';
    },

    onChange(val, evt) {
      const currentButton = evt.target.defaultValue;
      if (!val) {
        this.$emit('select', []);
      } else {
        this.$emit('select', [currentButton]);
      }
    },

    registerInputs() {
      this.$nextTick(() => {
        this.searchConversationSubscription = getInputKeyupSubject(
          this.$_IDS.CONV_SEARCH,
          DEBOUNCE_TIMEOUT,
        )
          .subscribe(() => {
            this.applyFilter();
          });
      });
      this.$nextTick(() => {
        this.searchContentSubscription = getInputKeyupSubject('filter-search-content', DEBOUNCE_TIMEOUT)
          .subscribe(() => {
            this.applyFilter();
          });
      });
    },

    unRegisterInputs() {
      if (this.searchConversationSubscription) {
        this.searchConversationSubscription.unsubscribe();
        this.searchConversationSubscription = null;
      }
      if (this.searchContentSubscription) {
        this.searchContentSubscription.unsubscribe();
        this.searchContentSubscription = null;
      }
    },
  },

  created() {
    this.$_IDS = IDS;
  },

  beforeUnmount() {
    this.unRegisterInputs();
  },
};
</script>

<style lang="scss" scoped>
@import "../../../styles/media-queries.scss";
@import "../../../styles/aiq-variables.scss";

@include mobile {
  .el-checkbox-group{
    width: 90% !important;
  }
}

.search-field-container .el-checkbox-group{
  width:216px;
  min-height:30px;
  margin-top: 5px;
}
.date-picker_popup {
  width: 240px;
  transform-origin: center top 0px;
  z-index: 2003;
  position: absolute;
  top: -155px;
  left: 76px;
}
.full-filter-container {
  padding: 0 8px 10px 8px;
  opacity: 1;
  transition: all 300ms;

  &.hide {
    height: 0px;
    opacity: 0;;
  }
}
.filter-row {
  display: flex;
  margin-top: 8px;

  > * {
    &:not(:first-child) {
      margin-left: 8px;
    }
  }
}
#filter-search-content {
  margin-bottom: 8px;
}
.search-field-container {
  position: relative;
  display: flex;
  width: 100%;
  height: 46px;
  padding: 8px 0 8px 8px;

  [class^="iq-ico"] {
    position: absolute;
    top: 50%;
    left: 15px;
    transform: translateY(-50%);
    z-index: 10;
  }
  .el-button {
    display: flex;
    align-items: center;
    justify-content: center;
    min-width: 32px;
    min-height: 32px;
    font-size: 24px;
    line-height: 32px;
    padding: 0;
    border: 0;
  }
}
.md-theme-agentiq .md-input-container input {
  color: #6a7682;

  &::-webkit-input-placeholder {
      color: #6a7682;
      font-size: 12px;
  }
}
.checkbox-container {
  display: flex;
  flex-grow: 1;
  align-items: center;
  width: 48%;
  height: 22px;

  .el-checkbox {
    display: flex;
    align-items: center;
    margin: 0;
  }
}
.clear-filter-button {
  height: 22px;
  padding-top: 0;
  padding-bottom: 0;
  font-size: 12px;

  &[disabled] {
    color: #E5E9F2;
    border-color: #E5E9F2;
    background-color: #fff;

    &:hover {
      color: #E5E9F2;
    }
  }
}


.no-search {
  padding: 8px;
}

.back-to-conversation {
  color: #567db9;
  cursor: pointer;
  height: 30px;
  display: flex;
  padding-top: 5px;

  span {
    margin: 3px 0px 0px 4px;
  }
}

</style>

<style lang="scss">
@import "../../../styles/aiq-variables.scss";

.conversations-date-picker {
  .el-input__inner  {
    border: 1px solid $aiq-icon-primary !important;
  }
}

.filter-row {
  .el-date-editor.el-input {
    width: 100%;
    height: 30px;
  }
}
</style>
