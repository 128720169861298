<template lang="pug">
  .assets-container
    aiq-dialog.dialog-transparent(v-model="previewBoxVisible"
                                  :show-close="false"
                                  :modal-append-to-body="false"
                                  :close-on-click-modal="true")
      aiq-image(v-if="mediaPreview"
          :file="getMediaItem(mediaPreview).item"
          @click="previewBoxVisible = false")
    .assets-list
      template(v-for="(item, index) in records.models")
        h3.inner-header(v-if="hasHeaderDate(index)") {{ getDate(item.created_at) }}
        aiq-tooltip(v-if="showDeletedMedia(item)", 
                    effect="dark", 
                    placement="top-end",
                    :show-after="1000",
                    :content="deletedMediaTooltip(item)", 
                    :disabled="!item.deleted_by" )
          div.drag(:transfer-data="getMediaItem(item)",
              :key="item.id",
              :draggable="!item.deleted_by"
              :class="['asset-item', { deleted: !!item.deleted_by }]"
              @click="!item.deleted_by && onMediaClicked(item)")
            i.iq-ico-expand.asset-expand(@click.stop="previewMedia(item)")
            .asset-item_img-preview
              aiq-image(:file="getMediaItem(item).thumbnail")
            .asset-item_title(:title="getMediaItem(item).title")
              div {{getMediaItem(item).title}}
              div
                aiq-timeago(:since="item.created_at" :minTime="0")
              div Sender: {{ getSender(item) }}
    infinite-loading(ref="infiniteLoading"
                    v-if="doInfiniteScroll"
                    spinner="waveDots"
                    :distance="10"
                    @infinite="$emit('onScrollLoad', $event)")
      template(v-slot:spinner)
        .filter-loading
          span.filter-loading-item(v-for="item in 5")
      template(v-slot:no-results) No documents history
      template(v-slot:complete) No more results

</template>
<script>
import { mapActions } from 'vuex';
import get from 'lodash/get';
import { MEDIA_TYPE } from '@/constants/analytics';


export default {
  name: 'media',
  props: {
    records: {
      type: Object,
      default: () => {},
    },
    doInfiniteScroll: {
      type: Boolean,
      default: false,
    },
    getSender: {
      type: Function,
      default: () => null,
    },
    hasHeaderDate: {
      type: Function,
      default: () => null,
    },
    getDate: {
      type: Function,
      default: () => null,
    },
  },
  data() {
    return {
      previewBoxVisible: false,
      mediaPreview: null,
    };
  },
  watch: {

  },
  methods: {
    ...mapActions({
      clearAttachedAssets: 'assets/clearAttachedAssets',
      attachAsset: 'assets/attachAsset',
      attachFile: 'files/attachFile',
    }),
    showDeletedMedia(item) {
      return !item.deleted_by || this.canView('/conversations/message/audit');
    },
    deletedMediaTooltip({ deleted_by_agent }) {
      return `Deleted by ${get(deleted_by_agent, 'fullName', '')}`;
    },
    getMediaItem(item) {
      const type = get(item, 'attachment.message_type', '');
      let media;
      switch (type) {
        case 'asset':
          media = {
            title: get(item, 'attachment.asset.file.payload.name', ''),
            url: get(item, 'attachment.asset.file.payload.url', ''),
            item: get(item, 'attachment.asset', {}),
            type: 'asset',
          };
          break;
        case 'file':
          media = {
            title: get(item, 'attachment.file.name', ''),
            url: get(item, 'attachment.file.url', ''),
            item: get(item, 'attachment.file', {}),
            type: 'file',
            thumbnail: get(item, 'attachment.file.thumbnail', get(item, 'attachment.file', {})),
          };
          break;
        default:
          return item;
      }
      return media;
    },
    onMediaClicked(item) {
      const media = this.getMediaItem(item);
      switch (media.type) {
        case 'asset':
          this.attachAsset(media.item); // TODO (Akshay) send source
          break;
        case 'file':
          this.$store.dispatch('files/attachFile', { file: media.item, source: MEDIA_TYPE.HISTORY });
          break;
        default:
          break;
      }
    },
    previewMedia(media) {
      this.mediaPreview = media;
      this.previewBoxVisible = true;
    },
  },
};
</script>
<style lang="scss" scoped>
.assets-list {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
}
.inner-header {
  padding: 0 0 10px 0;
  color: #48576a;
  font-size: 12px;
  line-height: 14px;
  font-weight: 300;
  width: 100%;
}
.asset-item {
  position: relative;
  width: 134px;
  margin-bottom: 12px;
  border-radius: 4px;
  box-shadow: 0 0 3px 0 rgba(191,203,217,0.50);
  background-color: #fff;
  overflow: hidden;
  cursor: pointer;

  &:hover {
    box-shadow: 0 0 3px 2px rgba(191,203,217,0.50);

    .asset-expand {
      opacity: 1;
    }
  }
  &:active {
    box-shadow: none;
  }

  &_img-preview {
    width: 139px;
    height: 139px;
    background-position: center center;
    background-size: cover;
  }
  &_title {
    padding: 10px;
    color: #48576A;
    font-size: 11px;
    line-height: 13px;
    font-weight: 300;

    div {
      padding-bottom: 5px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
    }
  }

  &.deleted {
    opacity: .5;
    cursor: not-allowed;
    i.asset-expand {
      cursor: pointer;
    }
  }
}
.asset-expand {
  position: absolute;
  top: 6px;
  right: 6px;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 22px;
  height: 22px;
  border-radius: 50%;
  background-color: #5993FF;
  cursor: pointer;
  opacity: 0;
}
.dialog-transparent {

  img {
    cursor: pointer;
  }
}
</style>


