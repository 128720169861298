import isUndefined from 'lodash/isUndefined';
import round from 'lodash/round';

const DECIMAL_PRECISION = 2;
export const percentFormatter = v => `${v}%`;
export const textFormatter = v => v;
export const intTickFormatter = v => (Math.floor(parseFloat(v)) === parseFloat(v) ? v : null);
export const onSort = (a, b) => (a <= b ? 1 : -1);
export const
      hoursFormatter = (hours, decimalPrecision = DECIMAL_PRECISION) => {
      // eslint-disable-next-line max-len
        const roundedHours = (isUndefined(hours) || hours < 0) ? 0 : round(hours, decimalPrecision);
        return `${roundedHours}h`;
      };
export const
      secondsFormater = (seconds, decimalPrecision = DECIMAL_PRECISION) => {
      // eslint-disable-next-line max-len
        const roundedHours = (isUndefined(seconds) || seconds < 0) ? 0 : round(seconds, decimalPrecision);
        return `${roundedHours}s`;
      };
