export const QUERY_MAPPING = {
  '/users': {
    filters: {
      query: 'query',
      dateRangeFilter: 'dateRangeFilter',
      teamSelectedOptions: {
        optionsContainer: 'teamOptions',
        objPropertyName: 'name',
        isAsync: true,
      },
      availabilitySelectedOptions: {
        optionsContainer: 'availabilityOptions',
        objPropertyName: 'field',
        simplePropertyValue: true,
        isAsync: true,
      },
      roleSelectedOptions: {
        optionsContainer: 'roles',
        objPropertyName: 'name',
        simplePropertyValue: true,
        isAsync: true,
      },
    },
  },
  '/settings/agents/manage': {
    filters: {
      query: 'query',
    },
  },
  '/conversations': {
    filters: {
      active: 'active',
      customer_segments: {
        optionsContainer: 'customer_segments',
      },
      ai_served: 'ai_serve',
      queued: 'queued',
      unresponded: 'unresponded',
      agent_served: 'agent_served',
      date_range: 'date_range',
      teams: {
        optionsContainer: 'teams',
      },
      tag_names: {
        optionsContainer: 'tag_names',
      },
    },
  },
};
