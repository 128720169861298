<template lang="pug">
.main(tabindex="-1")
  WindowResize
  slot(v-if="!hideHeader" name="header")
    header-default(
      @logout="logout",
      @changeAvailability="changeAvailability")
  aiq-row(type="flex", :class="{windowWrapper: isMobileContext}")
    aiq-col.sidebar(v-if="showSidebar")
      slot(name="sidebar")
        sidebar-default(:items="sidebarItems")
    aiq-col.content(:class="{ 'full-screen': hideHeader }")
      template(v-if="!tutorialInProgress")
        slot
      template(v-else)
        tutorial

  aiq-dialog.shortcut-menu(v-model="isShortcutMenuOpened"
                          :modal="false"
                          title="Keyboard Shortcuts"
                          width="815px")
    shortcut-help-menu
  slot(name="footer")

</template>

<script>
import mitt from 'mitt';

import { mapState, mapGetters } from 'vuex';
import get from 'lodash/get';
import HeaderDefault from './header.vue';
import SidebarDefault from './sidebar.vue';
import Tutorial from '../Tutorial.vue';
import { isTutorialVisible } from '@/libs/tutorial';
import { Events, ShortcutController } from '@/libs';
import { AGENT_STATUS, KEYBOARD_KEYS } from '@/constants';
import ShortcutHelpMenu from '@/components/ShortcutHelpMenu/ShortcutHelpMenu.vue';
import { AGENT_ONBOARDING } from '@/constants/analytics';
import WindowResize from './components/WindowResize.vue';

const ONE_SECOND_MS = 1000;
const SHORTCUT_LIST_EVT = 'toggle-shortcut-list';
const rawDefs = { custom: {
  [KEYBOARD_KEYS.QUESTION_MARK]: SHORTCUT_LIST_EVT,
} };

export default {
  name: 'layoutMain',
  props: {
    sidebarItems: Array,
    hideHeader: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    HeaderDefault,
    ShortcutHelpMenu,
    SidebarDefault,
    Tutorial,
    WindowResize,
  },
  computed: {
    ...mapState({
      availability: state => get(state.agent, 'profile.profile.available', AGENT_STATUS.Away.label),
      profile: state => state.agent.profile.profile,
      tutorialCompletionState: state => state.agent.tutorialCompletionState,
      totalLogins: state => state.agent.profile.totalLogins,
      userGuideConfig: state => state.configs.config.user_guiding,
      agent: state => state.agent.profile,
      isMobileContext: state => state.settings.isMobileContext,
    }),
    ...mapGetters({
      tutorialInProgress: 'agent/tutorialInProgress',
    }),
    showSidebar() {
      return Array.of.apply(null, this.sidebarItems).length;
    },
    shouldAutoDisplayTutorial() {
      const maxLoginCounts = get(this.userGuideConfig, 'max_count_login_to_launch', -1);
      return get(this.userGuideConfig, 'login_to_launch', false)
        && isTutorialVisible(this.totalLogins, this.tutorialCompletionState, maxLoginCounts);
    },
  },
  data() {
    return {
      closePopupListener: null,
      idleOpened: false,
      isShortcutMenuOpened: false,
      openPopupListener: null,
      seconds: process.env.IDLE_POPUP_TIMEOUT,
      timer: null,
      universalShortcutController: null,
      universalShortcutEmitter: mitt(),
    };
  },
  mounted() {
    if (this.shouldAutoDisplayTutorial) {
      this.$store.dispatch('notifications/emitEventToSocket', {
        name: AGENT_ONBOARDING.AUTO_OPEN,
        payload: {
          agent: this.agent,
          config: this.userGuideConfig,
        },
      });

      this.$store.dispatch('agent/showTutorial');
    }

    this.closePopupListener = () => {
      this.clearIdleDialog();
    };

    Events.on('close.idle.popup', this.closePopupListener);

    this.openPopupListener = () => {
      if (this.idleOpened || !this.availability) return;
      this.showPopup();
    };

    Events.on('open.idle.popup', this.openPopupListener);

    this.universalShortcutController = new ShortcutController({
      rootElem: this.$el,
      rawDefs,
      emitter: this.universalShortcutEmitter });
    this.universalShortcutController.attachListener();
    this.universalShortcutEmitter.on(SHORTCUT_LIST_EVT, this.toggleShortcutList);

    this.$el.focus();
  },

  unmounted() {
    Events.off('close.idle.popup', this.closePopupListener);
    Events.off('open.idle.popup', this.openPopupListener);
    this.universalShortcutController.removeListener(SHORTCUT_LIST_EVT, this.toggleShortcutList);
  },
  methods: {
    changeAvailability(value) {
      return this.$store.dispatch('agent/changeAvailability', value);
    },

    clearIdleDialog(timer = this.timer) {
      clearInterval(timer);
      this.idleOpened = false;
      this.seconds = process.env.IDLE_POPUP_TIMEOUT;
      this.$aiq.closeConfirm();
    },

    logout() {
      this.$store.dispatch('agent/logout').then(() => {
        this.$router.go({ path: '/login' });
      });
    },

    showPopup() {
      this.$store.dispatch('agent/changeIdlePopupActivity', true);
      this.idleOpened = true;
      this.timer && clearInterval(this.timer);
      this.timer = setInterval(() => {
        const confirmMessageContent = document.querySelector('.el-message-box__content');
        if (!confirmMessageContent) {
          return;
        }
        this.seconds -= 1;
        confirmMessageContent.innerText = `Are you still here? ${this.seconds}`;
        if (this.seconds === 1) {
          this.changeAvailability(AGENT_STATUS.Away.field).then(() => {
            this.$store.dispatch('agent/changeIdlePopupActivity', AGENT_STATUS.Away.field);
            this.clearIdleDialog();
          });
        }
      }, ONE_SECOND_MS);

      this.$aiq.confirm(
        "Haven't seen you for a while",
        `Are you still here? ${this.seconds}`,
        { showCancelButton: false },
      ).then(() => {
        this.$store.dispatch('agent/changeIdlePopupActivity', false);
        this.clearIdleDialog();
      });
    },

    toggleShortcutList() {
      this.isShortcutMenuOpened = !this.isShortcutMenuOpened;
    },
  },
};
</script>

<style lang="scss">
@import "../../styles/aiq-extensions.scss";

.main {
  .shortcut-menu {
    .el-dialog__header {
      @extend %shortcut-menu__bg;
    }

    .el-dialog__body {
      @extend %shortcut-menu__bg;
      border-top: 1px solid #fff;
      
    }
  }
}
</style>

<style lang="scss" scoped>
  @import "./../../styles/aiq-variables.scss";
  @import "../../styles/aiq-extensions.scss";

  .main {
    min-height: 100%;
    background-color: $aiq-bgc-body;


    .shortcut-menu {
      div {
        color: #fff;
      }
    }
  }

  .sidebar {
    flex-grow: 0;
    flex-basis: auto;
    width: 70px;
    margin-top: 4px;
    border-radius: 0 4px 0 0;
    overflow: hidden;
  }

  .content {
    display: flex;
    height: calc(100vh - 70px);
    width: 100%;
    margin-top: 4px;
  }

  .content.full-screen {
    height: calc(100vh - 45px);
  }

  .sidebar + .content {
    width: calc(100vw - 70px);
    max-width: calc(100vw - 70px);
  }

  .windowWrapper{
    padding-top: 62px;
  }

</style>
