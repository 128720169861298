<template lang="pug">
  management-page.scrollable(title="BankerPerformance"
                :creatable="false"
                :searchable="false"
                :showDownloadButton="true"
                :showHeader="false")
    template(v-slot:body)
      .toolbar
          .end_tools
            b.flex-spacer
              span Performance
              aiq-tooltip.help-icon(placement="right")
                template(v-slot:content)
                  div( v-html="$filters.sanitize(helpText)")
                i.iq-ico-explanation
            FilteredInput.search(v-model="searchTerm" size="small" placeholder="Search Agents")

            .dropdown-filters(v-if="innerWidth>=smallToolbarWidth")
                .dropdown-filter
                    aiq-select(placeholder="Filter by Teams"
                                         v-model="chosenAgentTeamFilterOptions"
                                         multiple
                                         :reserve-keyword="false"
                                         collapse-tags
                                         @change="onAgentsTeamsFilterChange($event)"
                                         value-key='id'
                                         size="small")
                         aiq-option(v-for="option in agentsTeamsFilterOptions"
                                  :key="option.id"
                                  :label="optionLabel(option)"
                                  :value="option")

            .time-range-filter(v-if="innerWidth>=smallToolbarWidth")
              aiq-date-picker(v-model="dateRange"
                              type="daterange"
                              placeholder="Select date range",
                              popper-class="metrics-time-range__date-picker-popup"
                              :shortcuts="datePickerOptions.shortcuts"
                              :disabled-date="datePickerOptions.disabledDate"
                              :teleported="false"
                              format="YYYY/MM/DD"
                              :clearable="false")

          .small_view_toolbar(v-if="innerWidth<smallToolbarWidth")
            .dropdown-filters
                .dropdown-filter
                    aiq-select(placeholder="Filter by Agents of  Teams"
                                         v-model="chosenAgentTeamFilterOptions"
                                         multiple
                                         :reserve-keyword="false"
                                         collapse-tags
                                         @change="onAgentsTeamsFilterChange($event)"
                                         value-key='id'
                                         size="small")
                         aiq-option(v-for="option in agentsTeamsFilterOptions"
                                  :key="option.id"
                                  :label="optionLabel(option)"
                                  :value="option")

            .time-range-filter
              aiq-date-picker(v-model="dateRange"
                              type="daterange"
                              placeholder="Select date range",
                              popper-class="metrics-time-range__date-picker-popup"
                              :shortcuts="datePickerOptions.shortcuts"
                              :disabled-date="datePickerOptions.disabledDate"
                              :teleported="false"
                              format="YYYY/MM/DD"
                              :clearable="false")
      performance-grid(:categories="performanceCategories" @change-table="changeTable" :selected-metric="selectedMetric")
      aiq-card.top-performers(v-if="selectedMetric")
        template(v-slot:header)
          div(class="card-header")
            span {{ `Top Performers for ${selectedMetric.title}` }}
            aiq-tooltip(content="Download" placement="top")
              aiq-button(text)
                fa-icon(:icon="['fas', 'download']" color="#fff" size="lg" @click="exportTable()")
        div
          responsive-table.scrollable(
            v-loading="loading"
            :data="performanceData"
            :columns="selectedMetric.performances"
            :default-sort="selectedMetric.defaultSort"
            :rows-count="rowsCount"
            :isNormal="innerWidth>=selectedMetric.maxWidth"
            @set-export-table-sort="setExportTableSort")
</template>

<script>
import cloneDeep from 'lodash/cloneDeep';
import round from 'lodash/round';
import isFinite from 'lodash/isFinite';
import get from 'lodash/get';

import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import { downloadAsFile, durationFormatter } from '@/libs';
import PerformanceGrid from '@/components/AgentPerformance/PerformanceGrid.vue';

import ResponsiveTable from '../../ResponsiveTable.vue';
import performanceMixin from '../Performance';
import { onSort } from '../../helpers/formatters';
import PlatformFiltersMixin from '../../Platform/children/selectableFilters/SelectableFilters';

const Key = {
  agent: 0,
  conversations: 1,
  messages: 2,
  customers: 3,
  returning_customers: 4,
  median_frt: 5,
  average_frt: 6,
  conversations_per_hour: 7,
  messages_per_hour: 8,
  total_surveys: 9,
  average_score: 10,
  median_score: 11,
};

const ROWS_COUNT = 5;

export default {
  name: 'Performance',
  components: {
    ManagementPage,
    ResponsiveTable,
    PerformanceGrid,
  },
  watch: {
    selectedFilters() {
      this.loadList();
    },
  },
  computed: {
    performanceCategories() {
      return {
        communication: {
          title: 'Communication',
          metrics: [
            {
              title: 'Total Number of Conversations',
              tooltip:
                `This is the total number of conversations for the agents${
                  this.durationTooltip}`,
              value: this.getTotalNumberOfConversationsAggregate(),
              duration: this.duration,
              performances: [
                this.performances[Key.agent],
                this.performances[Key.conversations],
              ],
              maxWidth: 800,
              defaultSort: {
                prop: this.performances[Key.conversations].name,
                order: 'ascending',
              },
            },
            {
              title: 'Total Number of Messages',
              tooltip:
                `This is the total number of messages sent by the agents${
                  this.durationTooltip}`,
              value: this.getTotalNumberOfMessagesAggregate(),
              duration: this.duration,
              performances: [
                this.performances[Key.agent],
                this.performances[Key.messages],
              ],
              maxWidth: 800,
              defaultSort: {
                prop: this.performances[Key.messages].name,
                order: 'ascending',
              },
            },
          ],
        },
        responsiveness: {
          title: 'Responsiveness',
          metrics: [
            {
              title: 'First Response Time',
              tooltip:
                `This is the median response time for the agents${
                  this.durationTooltip}`,
              value: this.getFirstResponseTimeAggregate(),
              duration: this.duration,
              performances: [
                this.performances[Key.agent],
                this.performances[Key.median_frt],
              ],
              maxWidth: 800,
              defaultSort: {
                prop: this.performances[Key.median_frt].name,
                order: 'descending',
              },
            },
          ],
        },
        relationship: {
          title: 'Relationships',
          metrics: [
            {
              title: 'Average Survey Score',
              tooltip:
                `This is the average survey score for the agents${
                  this.durationTooltip}`,
              value: this.getAverageSurveyScoreAggregate(),
              duration: this.duration,
              performances: [
                this.performances[Key.agent],
                this.performances[Key.average_score],
              ],
              maxWidth: 800,
              defaultSort: {
                prop: this.performances[Key.average_score].name,
                order: 'ascending',
              },
            },
            {
              title: 'Returning Customers',
              tooltip:
                `This is the number of return customers for the agents${
                  this.durationTooltip}`,
              value: this.getReturningCustomersAggregate(),
              duration: this.duration,
              performances: [
                this.performances[Key.agent],
                this.performances[Key.returning_customers],
              ],
              maxWidth: 800,
              defaultSort: {
                prop: this.performances[Key.returning_customers].name,
                order: 'ascending',
              },
            },
            {
              title: 'Customers',
              tooltip:
                `This is the total number of customers the agents have assisted${
                  this.durationTooltip}`,
              value: this.getCustomersAggregate(),
              duration: this.duration,
              performances: [
                this.performances[Key.agent],
                this.performances[Key.customers],
              ],
              maxWidth: 800,
              defaultSort: {
                prop: this.performances[Key.customers].name,
                order: 'ascending',
              },
            },
          ],
        },
      };
    },
    rowsCount() {
      return ROWS_COUNT;
    },
    helpText() {
      return 'Coach agents using performance metrics. Click a metric to view a table with the top performers for the metric. On the table, click <b>Download</b> to export <u>all</u> data associated with the metric.';
    },
  },
  mixins: [PlatformFiltersMixin, performanceMixin],
  data() {
    const performances = [
      {
        key: Key.agent,
        name: 'first_name',
        label: 'Agent',
        min: '5',
        sort: this.sortDataByAgent,
        get: this.getAgentName,
      },
      {
        key: Key.conversations,
        name: 'total_conversations',
        label: 'Total Number of Conversations',
        tooltip: 'This is the total number of conversations for the agent.',
        min: '5',
        sort: this.sortDataByField,
        get: this.getNumberOfConversations,
      },
      {
        key: Key.messages,
        name: 'total_messages',
        label: 'Total Number of Messages',
        tooltip: 'This is the total number of messages sent by the agent.',
        min: '5',
        sort: this.sortDataByField,
        get: this.getNumberOfMessages,
      },
      {
        key: Key.customers,
        name: 'number_of_customers',
        label: 'Customers',
        tooltip: 'This is the number of customers for the agent.',
        min: '5',
        sort: this.sortDataByField,
        get: this.getnumberOfCustomers,
      },
      {
        key: Key.returning_customers,
        name: 'returning_customers',
        label: 'Returning Customers',
        tooltip: 'This is the number of returning customers for the agent.',
        min: '5',
        sort: this.sortDataByField,
        get: this.getnumberOfReturningCustomers,
      },
      {
        key: Key.median_frt,
        name: 'median_first_response_time',
        label: 'Median FRT',
        min: '5',
        sort: this.sortDataByField,
        get: this.getMedianFRTDuration,
      },
      {
        key: Key.average_frt,
        name: 'average_first_response_time',
        label: 'Average First Response Time',
        tooltip: 'This is the average first response time for the agent.',
        min: '5',
        sort: this.sortDataByField,
        get: this.getAvgFRTDuration,
      },
      {
        key: Key.conversations_per_hour,
        name: 'conversations_per_hour',
        label: 'Conversations per hour',
        tooltip:
          'This is the average number of conversations per hour, for the agent.',
        min: '5',
        sort: this.sortDataByField,
        get: this.getConversationsPerHour,
      },
      {
        key: Key.messages_per_hour,
        name: 'messages_per_hour',
        label: 'Messages per hour',
        tooltip:
          'This is the average number of messages per hour, for the agent.',
        min: '5',
        sort: this.sortDataByField,
        get: this.getMessagesPerHour,
      },
      {
        key: Key.total_surveys,
        name: 'total_ratings',
        label: 'Total Surveys',
        tooltip: 'This is the number of surveys for the agent.',
        min: '5',
        sort: this.sortDataByField,
        get: this.getRatings,
      },
      {
        key: Key.average_score,
        name: 'average_rating_value',
        label: 'Average Survey Score',
        tooltip: 'This is the average survey score for the agent.',
        min: '5',
        sort: this.sortDataByField,
        get: this.getAvgRating,
      },
      {
        key: Key.median_score,
        name: 'median_rating_value',
        label: 'Median Score',
        min: '5',
        sort: this.sortDataByField,
        get: this.getMedianRating,
      },
    ];

    return {
      selectedMetric: null,
      innerWidth: 768,
      smallToolbarWidth: 1136,
      performances,
      exportTableSort: null,
      chosenAgentTeamFilterOptions: [],
    };
  },
  methods: {
    exportTable() {
      const { sort: sortingCb } = this.selectedMetric.performances.find(
        (column) => column.name === this.exportTableSort.prop,
      );

      const sortedPerformanceData = cloneDeep(this.performanceData).sort(
        sortingCb(this.exportTableSort.prop, this.exportTableSort.order),
      );

      const data = sortedPerformanceData.map((record) => {
        const result = {};
        this.selectedMetric.performances.forEach((column) => {
          result[column.label] = column.get(record);
        });
        return result;
      });

      downloadAsFile({
        filename: `${this.selectedMetric.title}.csv`,
        dataType: 'table',
        dataSource: 'metrics',
        data,
      });
    },
    getMedianFRTDuration(datapoint) {
      return durationFormatter(this.getMedianFRT(datapoint));
    },
    getAvgFRTDuration(datapoint) {
      return durationFormatter(this.getAvgFRT(datapoint));
    },
    handleResize() {
      this.innerWidth = window.innerWidth;
    },
    getTotalNumberOfConversationsAggregate() {
      return this.performanceData.reduce(
        (sum, dataPoint) => sum + this.getNumberOfConversations(dataPoint),
        0,
      );
    },
    getTotalNumberOfMessagesAggregate() {
      return this.performanceData.reduce(
        (sum, dataPoint) => sum + this.getNumberOfMessages(dataPoint),
        0,
      );
    },
    // TODO: Change the method to calculate median of FRTs instead of avg of FRT medians
    getFirstResponseTimeAggregate() {
      const filteredMedianFRTs = this.performanceData.filter(
        (dataPoint) => this.getMedianFRT(dataPoint),
      );
      const dataSum = filteredMedianFRTs.reduce(
        (sum, dataPoint) => sum + this.getMedianFRT(dataPoint),
        0,
      );
      const dataAvg = dataSum / filteredMedianFRTs.length;
      return dataAvg ? durationFormatter(dataAvg) : 'N/A';
    },
    getAverageSurveyScoreAggregate() {
      const averageSurveyScoreItems = this.performanceData.reduce(
        (accumulator, currentValue) => {
          const avgScoreForAgent = this.getAvgRating(currentValue);
          if (isFinite(avgScoreForAgent)) {
            return {
              sumOfSurveyScores: accumulator.sumOfSurveyScores + avgScoreForAgent,
              numOfSurveys: accumulator.numOfSurveys + 1,
            };
          }
          return accumulator;
        },
        {
          sumOfSurveyScores: 0,
          numOfSurveys: 0,
        },
      );
      return averageSurveyScoreItems.numOfSurveys ?
        round(averageSurveyScoreItems.sumOfSurveyScores / averageSurveyScoreItems.numOfSurveys) :
        'N/A';
    },
    getReturningCustomersAggregate() {
      return this.performanceData.reduce(
        (sum, dataPoint) => sum + this.getnumberOfReturningCustomers(dataPoint),
        0,
      );
    },
    getCustomersAggregate() {
      return this.performanceData.reduce(
        (sum, dataPoint) => sum + this.getnumberOfCustomers(dataPoint),
        0,
      );
    },
    changeTable(_, metric) {
      this.selectedMetric = metric;
    },
    setExportTableSort({ prop, order }) {
      this.exportTableSort = { prop, order };
    },
    sortDataByField(field, order) {
      if (order === 'descending') {
        return (a, b) => onSort(get(b, [field]), get(a, [field]));
      }
      return (a, b) => onSort(get(a, [field]), get(b, [field]));
    },
    sortDataByAgent(_, order) {
      if (order === 'descending') {
        return (a, b) => onSort(this.getAgentName(b), this.getAgentName(a));
      }
      return (a, b) => onSort(this.getAgentName(a), this.getAgentName(b));
    },
  },
  created() {
    window.addEventListener('resize', this.handleResize);
    this.handleResize();
  },
  unmounted() {
    window.removeEventListener('resize', this.handleResize);
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/aiq-variables.scss";
.content-inner {
  max-width: 1100px;
  margin: 0 auto;
}
.toolbar {
  margin-bottom: 24px;
  .end_tools {
    display: flex;
    justify-content: flex-end;
    .flex-spacer {
      flex-grow: 1;
      font-size: 20px;
      :first-child {
        margin-right: 0.5rem;
      }
    }
    .search {
      width: 15em;
    }
    .dropdown-filters {
      display: flex;
      align-content: flex-end;
      .dropdown-filter {
        margin-left: 12px;
        width: 18em;
        height: 28px;
      }
    }
    .time-range-filter {
      position: relative;
      margin-left: 12px;
    }
    .download-button {
      margin-right: 20px;
      margin-left: 12px;
    }
  }
  .small_view_toolbar {
    display: flex;
    justify-content: space-around;
    margin-top: 20px;
    .dropdown-filters {
      flex-grow: 1;
      margin-left: 20px;
      margin-right: 20px;
    }
    .time-range-filter {
      flex-grow: 1;
      margin-right: 20px;
    }
  }
}

@media (max-width: 565px) {
  .toolbar {
    display: none !important;
  }
}
</style>

<style lang="scss">
@import "../../../../styles/aiq-variables.scss";

.top-performers {
  border-radius: unset;
  .el-card__header {
    color: #fff;
    background: $aiq-bgc-header;
    padding: 10px 20px;
    .card-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }
  }
}
</style>
