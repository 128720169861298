import axios from 'axios';
import moment from 'moment';
import isUndefined from 'lodash/isUndefined';
import { getUrl } from '@/config/api.routes';
import { INGESTION_MODES } from '@/constants';

const myRegexp = /^Conflict\((.*?)\): original: (.*?) updating: (.*?)$/;

// Compare two js object and returns a list of different fields and reason
// ex)
// {                    {
//   name: "john",        name: "jon"
//   ids: [1, 2],         ids: [1,2,3,4],
//   sex: "male",         sex: "male",
//   age: 123,            age: "123",
//   job: "",             job: "software engineer",
// }                    }
//
// The two objects will should return three different fields as following
// [
//  "name= jon <> john",
//  "ids= list length different",
//  "age= integer type <> string type",
//  "job= EMPTY <> software engineer"
// ]
function discoverDiffFields(path = [], ret = [], original, updating) {
  if (isUndefined(original) || isUndefined(updating)) {
    return ret;
  }

  if (typeof updating !== typeof original) {
    ret.push(`${path.join('.')}= ${typeof updating} type <> ${typeof original} type`);
    return ret;
  }

  if (updating instanceof Array) {
    if (updating.length !== original.length) {
      ret.push(`${path.join('.')}= list length different`);
    } else {
      for (const [index, val] of updating.entries()) {
        path.push(index);
        discoverDiffFields(path, ret, original[index], val);
        path.pop();
      }
    }
  } else if (typeof updating === 'object') {
    for (const field of Object.keys(updating)) {
      path.push(field);
      discoverDiffFields(path, ret, original[field], updating[field]);
      path.pop(field);
    }
  } else if (updating !== original) {
    ret.push(`${path.join('.')}= ${updating || 'EMPTY'} <> ${original || 'EMPTY'}`);
  }
  return ret;
}

function parsedRecord(item) {
  item.record = JSON.parse(item.record);
  item.updated_at = moment(item.updated_at).format('MMM DD, YYYY hh:mm A');

  if (item.reason) {
    const matches = item.reason.match(myRegexp);
    if (matches) {
      const table = matches[1];
      const dbVal = JSON.parse(matches[2]);
      const upVal = JSON.parse(matches[3]);
      const ret = discoverDiffFields([], [`In ${table} table,`], dbVal, upVal);
      item.reason = ret.join('\n');
    }
  }

  return item;
}

export default {
  namespaced: true,
  state: {
    records: [],
    mode: INGESTION_MODES.find(m => m.isDefault),
  },
  getters: {
    RECORD_STATE: () => ({
      ALL: 'all',
      SUCCESS: 'success',
      FAILURE: 'failure',
      SKIP: 'skip',
    }),
  },
  mutations: {
    SET_MODE(state, mode) {
      state.mode = mode;
    },
    SET_RECORDS(state, records) {
      state.records = records.map(parsedRecord);
    },
    SET_RECORD(state, record) {
      const parsed = parsedRecord(record);
      const foundIndex = state.records.findIndex(item => item.id === parsed.id);

      if (foundIndex > -1) {
        state.records[foundIndex] = parsed;
      }
      state.records = [...state.records];
    },
  },
  actions: {
    async loadImportedData({ commit }, { state, pagination = { limit: 20, offset: 0 } }) {
      const res = await axios.get(`${getUrl('management.ingestion')}/records`, {
        params: { state, ...pagination },
      });
      commit('SET_RECORDS', res.data.models);
      return res.data;
    },
    async resolveConflict({ commit }, { id, resolution = 'skip' }) {
      const res = await axios.put(`${getUrl('management.ingestion')}/records/${id}/resolution`, {
        resolution,
      });
      commit('SET_RECORD', res.data);
      return res.data;
    },
    async ingestFile({ state }, { data, override = false }) {
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };
      const form = new FormData();
      form.append('file', data);
      if (state.mode.channel) {
        form.append('channel', state.mode.channel);
      }

      let url = `${getUrl('management.ingestion')}${state.mode.endpoint}`;
      if (override) {
        url += '?resolution_mode=override'; // this makes an update request
      }
      const res = await axios.post(url, form, config);
      return res.data;
    },
  },
};
