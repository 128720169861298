<template lang="pug">
aiq-dropdown.full-width(v-if="!editMode",
                        placement="top-end", 
                        :hide-timeout="5"  
                        class="mt-1" 
                        popper-class="quick-response-dropdown")
  template(v-slot:dropdown)
    aiq-dropdown-menu.context-menu.quick-response-menu
      template(v-if="readOnly")
        aiq-dropdown-item.context-menu-item(key="add-to-mine", command="add-to-mine")
          aiq-tooltip(effect="dark", placement="top", :content="$t('conversation_tab.side_panel.quick_reponses_accordion.add_to_mine')", :show-after="500")
            .menu-item-icon(@click="$emit('add-to-mine', response)")
              i(class="el-icon-plus")
      template(v-else)
        aiq-dropdown-item.context-menu-item(key="is_pinned", command="is_pinned")
          aiq-tooltip(effect="dark", placement="top", :content="$t(`conversation_tab.side_panel.quick_reponses_accordion.${response.is_pinned ? 'unpin': 'pin' }`)", :show-after="500")
            .menu-item-icon(@click="togglePinRespone")
              fa-icon(:icon="[response.is_pinned ? 'far' : 'fas', 'thumbtack']")
        aiq-dropdown-item.context-menu-item(key="edit", command="edit")
          aiq-tooltip(effect="dark", placement="top", :content="$t('conversation_tab.side_panel.quick_reponses_accordion.edit')", :show-after="500")
            .menu-item-icon(@click="changeMode(true)")
              i(class="el-icon-edit")
        aiq-dropdown-item.context-menu-item(key="delete", command="delete")
          aiq-tooltip(effect="dark", placement="top", :content="$t('conversation_tab.side_panel.quick_reponses_accordion.delete')", :show-after="500")
            .menu-item-icon(@click="$emit('remove', response.id)")
              i(class="el-icon-delete")
  .quick-response_list-item(@click="selectResponse", :class="{'is-pinned': response.is_pinned}")
    .quick-response_list-item_content
      .quick-response_list-item_content-text {{ responseContent }}
.quick-response_list-item(v-else, @click="selectResponse", :class="{'is-pinned': response.is_pinned}")
  .quick-response_list-item_content
    aiq-input.quick-response_list-item_content-textarea(size="small",
                                                        type="textarea",
                                                        :autosize="{ minRows: 1, maxRows: 3}",
                                                        v-model="value",
                                                        @keyup.esc="changeMode(false)"
                                                        @focus.stop="")
    .quick-response_list-item_actions
      aiq-tooltip(effect="dark", placement="top", :content="$t('conversation_tab.side_panel.quick_reponses_accordion.update')", :show-after="500")
        aiq-button(@click.stop="updateResponse")
          i.el-icon-check
</template>

<script>
const MAX_CHARACTER_LIMIT = 200;

export default {
  data() {
    return {
      editMode: false,
      value: '',
    };
  },
  props: {
    response: {
      type: Object,
      required: true,
    },
    readOnly: Boolean,
  },
  emits: ['editMode', 'remove', 'update'],
  watch: {
    response(value) {
      this.value = value.content;
    },
  },
  computed: {
    responseContent() {
      if (this.response.content.length > MAX_CHARACTER_LIMIT) {
        return `${this.response.content.slice(0, MAX_CHARACTER_LIMIT)}...`;
      }
      return this.response.content;
    },
  },
  methods: {
    changeMode(value = !this.editMode) {
      value && (this.value = this.response.content);
      this.editMode = value;
      this.$emit('editMode', [this.response.id, value]);
    },

    togglePinRespone() {
      if (this.response.is_pinned) {
        this.$emit('unpin', this.response.id);
      } else {
        this.$emit('pin', this.response.id);
      }
    },

    selectResponse() {
      !this.editMode && this.$emit('select', this.response.content, this.response.id);
    },

    updateResponse() {
      this.response.content !== this.value
      && this.value
      && this.$emit('update', this.response.id, this.value);
      this.changeMode(false);
    },
  },
  mounted() {
    this.value = this.response.content;
  },
};
</script>

<style lang="scss" scoped>
@import "./../../../../../../styles/aiq-variables.scss";
.full-width {
  width: 100%;
}
.quick-response_list-item {
  position: relative;
  padding: 10px 10px 10px 10px;
  margin-top: 8px;
  color: #F9FAFC;
  font-size: 11px;
  line-height: 13px;
  font-weight: 300;
  border-radius: 4px;
  background-color: #414246;

  &.is-pinned {
    background-color: #5A88DE;
  }

  &:hover {
    box-shadow: 0 0 2px 0 rgba(50,64,87,0.90);
    background-color: #80ACFF;

    &:not(.is-pinned) {
     background-color: #66676a;
    }

    .quick-response_list-item_actions  button.el-button {
      color: #fff;
    }
  }

  .quick-response_list-item_content {
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    align-items: center;
    .quick-response_list-item_actions {
      margin-left: 4px;
      .el-button {
        padding: 0;
        color: #BFCBD9;
        font-size: 10px;
        border: 0;
        background-color: transparent;
      }
    }
  }
}
ul.quick-response-menu.el-zoom-in-top-leave-active {
  -webkit-transition: none !important;
  -moz-transition: none !important;
  -o-transition: none !important;
  transition: none !important;
}
</style>
