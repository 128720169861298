export const PROFILE_EDITOR_TYPE = {
  CONCAT_STRING: 'concat_string', // used only internally to concat first & last name
  STRING: 'string',
  STRING_LIST: 'list_string',
  JSON: 'json',
  OBJECT_TABLE: 'object_table',
};

export const HAMBURGER_MENU_SELECTION_OPTIONS = [
  { label: 'Mobile User', value: 'mobile' },
  { label: 'Anonymous Web User', value: 'anonymous' },
  { label: 'Logged in Web User', value: 'external' },
];

export const ONBOARD_SCREERNS = [
  { label: 'Introduction', value: 'introduction' },
  { label: 'Terms and Conditions', value: 't_and_c' },
  { label: 'PreChat Survey Start', value: 'prechat_survey' },
];

export const DEFAULT_SYSTEM_LANGUAGE = {
  code: 'en',
  name: 'English',
};

export const AVAILABLE_LANGUAGES = [
  {
    ...DEFAULT_SYSTEM_LANGUAGE,
    disabled: true,
  },
  {
    code: 'fr',
    name: 'French',
  },
];

export const CHAT_ICON_RESOLUTION = {
  min: { height: 25, width: 25 },
  max: { height: 65, width: 65 },
};


export default {
  PROFILE_EDITOR_TYPE,
  DEFAULT_SYSTEM_LANGUAGE,
  AVAILABLE_LANGUAGES,
  CHAT_ICON_RESOLUTION,
};
