<template lang="pug">
  aiq-input(v-model="formattedInputValue"
            :disabled="disabled"
            :type="type"
            :name="name"
            :size="size"
            :placeholder="placeholder"
            @focus="$emit('focus')"
            @blur="$emit('blur')")
</template>

<script>
import debounce from 'lodash/debounce';

const INPUT_MAX_LENGTH = 256;
const DEBOUNCE_TIMEOUT = 700;

export default {
  name: 'FilteredInput',
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    type: {
      type: String,
      default: 'text',
    },
    name: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'small',
    },
    maxLength: {
      type: Number,
      default: INPUT_MAX_LENGTH,
    },
    placeholder: {
      type: String,
      default: '',
    },
    modelValue: {
      type: String,
      default: '',
    },
    shouldFilterSpaces:{
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      inputValue: this.modelValue,
    };
  },
  emits: ['update:modelValue', 'blur', 'focus'],
  computed: {
    formattedInputValue: {
      get() {
        return this.inputValue;
      },
      set(value) {
        this.inputValue = value;
        this.update();
      },
    },
  },
  methods: {
    update: debounce(function updateWithDebounce() {
      const inputValue = this.formatter(this.inputValue);
      if (inputValue !== this.inputValue) {
        this.inputValue = inputValue;
        const errorKey = this.shouldFilterSpaces 
          ? 'metrics_tab.search.notify_errors.no_special_characters_or_spaces' 
          : 'metrics_tab.search.notify_errors.no_special_characters';
        this.$aiq.notify.error(this.$t(errorKey));
      }
      this.$emit('update:modelValue', this.inputValue);
    }, DEBOUNCE_TIMEOUT),
    formatter(value) {
      // eslint-disable-next-line no-useless-escape
      const regex = this.shouldFilterSpaces ? /[\s\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@#$%&\\\=\(\'\"]/gi : /[\{\}\[\]\/?.,;:|\)*~`!^\-_+<>@#$%&\\\=\(\'\"]/gi;
      const result = value.replace(regex, '');
      return result.substring(0, this.maxLength);
    },
  },
};
</script>
