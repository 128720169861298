import axios from 'axios';
import merge from 'lodash/merge';
import get from 'lodash/get';
import has from 'lodash/has';
import uniqBy from 'lodash/uniqBy';
import * as log from 'loglevel';

import { CRUDStoreBuilder } from '@/store/StoreBuilder';
import { getUrl } from '@/config/api.routes';
import { fileNameParser } from '@/libs';
import { DOCUMENTS } from '@/constants/analytics';

const routingAlias = 'management.documents';
const documentsCrud = new CRUDStoreBuilder(routingAlias, 'document', [], 'data.models', true).createStore();

export default merge(documentsCrud, {
  namespaced: true,

  state: {
    documents: [],
    selected: null,
  },

  getters: {
    documentModel: () => ({
      file: {
        payload: {
          url: '',
        },
      },
      description: '',
    }),
  },

  mutations: {
    SELECT_DOCUMENT(state, doc) {
      state.selected = doc;
      return doc;
    },
    // TODO (Gabe) Need to check for pagination
    SET_DOCUMENTS(state, documents) {
      state.documents = documents;
    },
    SET_SEARCHED_DOCUMENTS(state, data) {
      // TODO (Gabe) set in own documentSearchResults array
      let items;

      if (data.isNew) {
        items = data.items;
      } else {
        items = uniqBy(state.documents.concat(data.items), 'id');
      }
      state.documents = items;
    },
    SET_TOTAL_DOCUMENTS(state, rowCount) {
      state.totalDocuments = rowCount;
    },
    CLEAR_STATE(state) {
      state.documents = [];
      state.totalDocuments = 0;
    },
  },

  actions: {
    appendDocument({ rootState, dispatch }, { document, source }) {
      let file = null;
      if (has(document, 'file.payload')) {
        // Typical KB payload
        file = get(document, 'file.payload');
      } else if (has(document, 'payload._source')) {
        // KB suggestion
        file = get(document, 'payload._source');
      } else {
        // Unexpected
        log.warn(`Unexpected document is added ${JSON.stringify(document)}`);
        return;
      }

      dispatch(
        'notifications/emitEventToSocket',
        { name: DOCUMENTS.ATTACHED,
          payload: {
            agent: get(rootState, 'agent.profile'),
            document: {
              name: file.name,
              url: file.url,
            },
            source,
          },
        },
        { root: true },
      );

      return dispatch('files/attachFile', { file, source }, { root: true });
    },
    async getDocument(_, params) {
      const [id, teams] = params;
      return axios.get(getUrl('management.documents', id), { params: { teams } });
    },
    async getDocumentSearchList({ rootState, dispatch }, params) {
      const ret = await axios.get(getUrl('management.documentSearch'), {
        params,
      });
      dispatch(
        'notifications/emitEventToSocket',
        { name: DOCUMENTS.SEARCHED,
          payload: {
            agent: get(rootState, 'agent.profile'),
            query: get(params, 'query'),
          },
        },
        { root: true },
      );
      return ret.data;
    },
    loadConversationDocuments({ commit }, documents) {
      commit('SET_DOCUMENTS', documents);
    },
    selectDocument({ commit }, rawDocument) {
      const filename = get(rawDocument, 'file.payload.filename', '');
      const url = get(rawDocument, 'file.payload.url', '');
      const acl = get(rawDocument, 'file.payload.acl', 'private');
      const document = {
        id: rawDocument.id,
        title: rawDocument.name,
        description: rawDocument.description,
        fileExt: fileNameParser(filename)[1],
        teams: rawDocument.teams,
        url,
        acl,
      };

      commit('SELECT_DOCUMENT', document);
      return document;
    },
    createDocument({ commit }, params = []) {
      const [item, dataKey = this.dataKey] = params;
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      if (this.pending) {
        commit('PUT_DOCUMENT');
      }

      return axios.post(getUrl('management.documents'), item.formData, config)
        .then(data => {
          // TODO (Gabe) set the right dataKey
          commit('PUT_DOCUMENT_SUCCESS', get(data, dataKey));
          return data;
        });
    },
    updateDocument({ commit }, params = []) {
      const [item, dataKey = this.dataKey] = params;
      const config = {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
      };

      if (this.pending) {
        commit('PUT_DOCUMENT');
      }

      return axios.put(getUrl('management.documents', item.id), item.formData, config)
        .then(data => {
          commit('PUT_DOCUMENT_SUCCESS', get(data, dataKey));
          return data;
        });
    },
  },
});
