export default {
  multiObject: {
    hasArguments: true,
    toEntry: params => {
      const args = params.map(id => ({ id }));

      return { arguments: args };
    },
    toComponentParams: params => {
      if (params.arguments) {
        return params.arguments.map(arg => arg.id);
      }

      return [];
    },
  },
  bool: {
    hasArguments: true,
    toEntry: bool => ({ arguments: bool }),
    toComponentParams: params => params.arguments,
  },
  none: {
    toEntry: () => undefined, // returns undefined to remove from json
  },
  bot: {
    hasArguments: true,
    // Only allowing bot to be false (bot.pass)
    toEntry: () => ({ arguments: false }),
    toComponentParams: () => 'Off',
  },

  singleObject: {
    hasArguments: true,
    toEntry: obj => (obj),
    toComponentParams: params => params.id,
  },

  // Currently unused
  textInput: {
    hasArguments: true,
    toEntry: () => {
    },
  },

  stringArray: {
    hasArguments: true,
    toEntry: obj => obj,
    toComponentParams: params => params,
  },
};
