import get from 'lodash/get';
// vue-test-utils does not support using Vue Router outside of Vue components.
// Even if the router is not used during tests, importing it here will cause vue-test-utils to throw an error and crash.
// To avoid this, we need to mock the router in the test environment.
let router;
if (!process || process.env.NODE_ENV !== 'test') router = require("@/router");
else router = { push: () => { } };

export default {
  namespaced: true,
  state: {
    props: {},
  },
  getters: {
    props: state => state.props,
  },
  mutations: {
    SET_PROPS(state, props) {
      state.props = props;
    },
  },
  actions: {
    routeIqtoolActionItem(_, item) {
      const unitType = get(item, 'unit_type', null);
      if (['workflow', 'dialog'].includes(unitType)) {
        router.push({ path: `/iqtools/${unitType}s`, hash: get(item, 'name') });
      }
    },
    setProps({ commit }, props) {
      commit('SET_PROPS', props);
    },
  },
};
