<template lang="pug">
managementPage(title="Roles and Permissions"
              @fetchData="loadList"
              :creatable="false"
              :searchable="false")

  template(v-slot:body)
    aiq-collapse.scrollbar__collapse(accordion)
      aiq-collapse-item(v-for="item in categorizedRows"
                        :title="item.label"
                        :name="item.index"
                        :key="item.label")
        aiq-table.scrollbar__category(:data="item.rows")
          aiq-table-column(label="Resource"
                          prop="role"
                          min-width="250px")
          aiq-table-column(v-for="role in roleNames"
                          :label="role.label"
                          :prop="role.name"
                          :key="role.name")
            template(v-slot:default="scope")
              permission-item(:role="role",
                              :config="$_get(scope.row, role.name, {})"
                              @request="onRequestPermissionUpdate")
</template>

<script>
import { Component, Vue, toNative } from 'vue-facing-decorator';
import * as log from 'loglevel';
import { mapState, mapGetters } from 'vuex';
import get from 'lodash/get';
import has from 'lodash/has';
import capitalize from 'lodash/capitalize';
import sortBy from 'lodash/sortBy';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import PermissionItem from './children/PermissionItem.vue';
import { masterRoleKey, uncategorizedCategory, uncategorizedKey } from '@/constants/access';

const generatePList = (resources, resourcesMetadata = {}) => Object.keys(resources)
  .reduce((permissions, resource) => {
    const permissionsForResource = resources[resource].reduce(
      (perms, permission) => {
        if (!get(resourcesMetadata[resource], 'hidden')) {
          perms.push(`${permission}_${resource}`);
        }
        return perms;
      }, [],
    );
    return [...permissions, ...permissionsForResource];
  }, []);

const reducer = (permissionMap, permission) => ({
  ...permissionMap,
  [permission]: true,
});
const reducePListToMap = permissions => generatePList(permissions).reduce(reducer, {});

const formatPermissionName = (permission, resource, resourcesMetadata) => {
  if (has(resourcesMetadata[resource], 'label')) {
    // Only roles have multiple entry so print permission for it with label.
    const label = get(resourcesMetadata[resource], 'label');
    if (resource.includes('/agents/:agent/roles/')) {
      return `${capitalize(label)}(${capitalize(permission)})`;
    }
    return label;
  }
  return `${capitalize(permission)} ${capitalize(resource)}`;
};

const categoryForResource = (permission, resources) => {
  const config = resources[permission];
  return config ? config.category : uncategorizedKey;
};

const getEditPermissionForRole = role => `edit_/roles/${role}`;

@Component({
  name: 'manageRoles',
  components: {
    ManagementPage,
    PermissionItem,
  },
  computed: {
    ...mapState({
      roles: state => state.settings.access.roles,
      categoriesMetadata: state => state.settings.access.metadata.categories,
      resourcesMetadata: state => state.settings.access.metadata.resources,
      agentPermissions: state => reducePListToMap(state.agent.permissions),
      myRoles: state => state.agent.profile.roles,
    }),
    ...mapGetters({
      roleNames: 'settings/roleNames',
    }),
    categorizedRows() {
      if (!this.resourcesMetadata || !this.categoriesMetadata) {
        return [];
      }

      const masterRole = this.roles.find(role => role.role === masterRoleKey);

      if (!masterRole) {
        return [];
      }

      const permissions = generatePList(masterRole.resources, this.resourcesMetadata);

      /**
      * rows example:
      *  [
      *    {
      *      id: "edit_/resource",
      *      superadmin: {
      *        resource: "/resource",
      *        permission: "edit"
      *        allow: true,
      *        disabled: false
      *      },
      *      admin: {
      *        resource: "/resource",
      *        permission: "edit"
      *        allow: true,
      *        disabled: true
      *      }
      *    }
      *  ]
      */

      const categorizedRowsMap = Object.keys(this.categoriesMetadata).reduce((map, key) => {
        const { index, label } = this.categoriesMetadata[key];
        map[key] = {
          index,
          label,
          rows: [],
        };

        return map;
      }, {});

      categorizedRowsMap[uncategorizedKey] = {
        ...uncategorizedCategory,
        rows: [],
      };

      permissions.forEach(perm => {
        const permissionToFormat = perm.split('_');
        const permission = permissionToFormat.shift();
        const resource = permissionToFormat.join('_');

        const row = {
          id: perm,
          role: formatPermissionName(permission, resource, this.resourcesMetadata),
        };

        this.roles.forEach(item => {
          const itemResource = item.resources[resource];
          row[item.role] = {
            resource,
            permission,
            role: item.role,
            allow: Boolean(itemResource && itemResource.includes(permission)),
            disabled:
              !this.agentPermissions[perm]
              || !this.agentPermissions[getEditPermissionForRole(item.role)],
          };
        });

        const category = categoryForResource(resource, this.resourcesMetadata);
        categorizedRowsMap[category].rows.push(row);
      });

      for (const [key, categorized] of Object.entries(categorizedRowsMap)) {
        if (key !== uncategorizedKey) {
          categorized.rows = sortBy(categorized.rows, 'role');
        }
      }

      const filteredMap = Object.values(categorizedRowsMap).filter(item => (item.superadmin ? this.myRoles.includes('superadmin') : true));
      return sortBy(filteredMap, 'index');
    },
  },
})
class Roles extends Vue {
  loadList() {
    Promise.all([
      this.$store.dispatch('settings/getRolesList'),
      this.$store.dispatch('settings/getRolesMetadata'),
    ]);
  }

  created() {
    this.$_get = (object, key, defaultValue) => get(object, key, defaultValue);
  }

  async onRequestPermissionUpdate(payload) {
    try {
      await this.$store.dispatch('settings/updateRole', payload);
      this.$aiq.notify.success(`${capitalize(payload.role)} role's ${payload.config.resource_name} updated.`);
      await this.onPermissionUpdated(payload);
    } catch (err) {
      log.error(err);
      this.$aiq.notify.error(get(err, 'data.error') || 'Edit failed.');
    }
  }

  async onPermissionUpdated({ role, config }) {
    const { resource_name, allow } = config;

    // Update down stream permissions recursively
    const currentResource = this.resourcesMetadata[resource_name];
    const resourcesToUpdate = [];
    for (const [depedentResource, dependentPermissions] of Object.entries(get(currentResource, 'dependencies', {}))) {
      for (const dependentPermission of dependentPermissions) {
        resourcesToUpdate.push(this.onRequestPermissionUpdate({
          role,
          config: {
            resource_name: depedentResource,
            permission: dependentPermission,
            allow,
          },
        }));
      }
    }
    return Promise.all(resourcesToUpdate);
  }
}
export default toNative(Roles);
</script>

<style lang="scss" scoped>
  @import "../../../../../styles/aiq-mixins.scss";

  .scrollbar__collapse {
    @include scrollable(calc(100vh - 204px));
  }
</style>

<style lang="scss">
  @import "../../../../../styles/aiq-mixins.scss";

  .scrollbar__category {
    .el-table__body-wrapper {
      @include scrollable(calc(100vh - 210px));
    }
  }
</style>
