// The Vue build version to load with the `import` command
// (runtime-only or standalone) has been set in webpack.base.conf with an alias.
import { createApp } from 'vue';
import { vScroll } from '@vueuse/components';
import InfiniteLoading from "v3-infinite-loading";
import "v3-infinite-loading/lib/style.css";
import VueClickAway from "vue3-click-away";
import VueSocketio from 'vue-3-socket.io';
import AiqComponents from '@agentiq/aiq-components';
import fontawesome from '@fortawesome/fontawesome';
import faSpinner from '@fortawesome/fontawesome-free-solid/faSpinner';
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome';
import draggable from 'vuedraggable';
/* TODO: VUE3 Check
import LogRocket from 'logrocket';
*/
import * as log from 'loglevel';
import { initialize } from './i18n';
import LocalPlugins from './localPlugins';
import VCalendar from 'v-calendar';
import 'v-calendar/style.css';
import axios from 'axios';
import 'element-plus/theme-chalk/display.css';
import { ElNotification } from 'element-plus';

// Own components
import FilteredInput from '@/components/FilteredInput.vue';

/* TODO: VUE3 Check
const lrId = process.env.LOG_ROCKET_ID;
if (!Vue.config.devtools) {
  if (lrId) {
    LogRocket.init(lrId, {
      network: {
        requestSanitizer: request => {
          request.headers.Authorization = null;
          return request;
        },
      },
      console: {
        shouldAggregateConsoleErrors: true,
      },
    });
  }
}
*/

/* eslint-disable */
import installFilters from './filters';
import App from './App.vue';
import router from './router';
import store from './store';
import aclMixin from './libs/acl.mixin';
import iconMixin from './libs/icon.mixin';
import { defaultToastedConfig, socketConnection } from '@/libs';
/* TODO: VUE3 Drawer is not using in the code. Check again
import { Drawer } from 'element-ui';
*/
/* eslint-enable */

const Vue = createApp(App);

if (window) {
  // This will enable dynamically set logger level by calling
  // log.setLevel('trace') on browser
  window.log = log;
}

// TODO (Gabe) move the font awesome components to AIQ-components

fontawesome.library.add(faSpinner);

initialize(Vue)
  .then(() => {
    Vue.use(AiqComponents);
    Vue.use(LocalPlugins);
    Vue.config.globalProperties.$http = axios;
    Vue.directive('scroll', vScroll);
    Vue.component('infinite-loading', InfiniteLoading);
    Vue.use(VueClickAway); // Makes 'v-click-away' directive
    Vue.use(new VueSocketio({
      debug: process.env.NODE_ENV === 'development',
      connection: socketConnection,
      vuex: {
        store,
        actionPrefix: "socket_",
        mutationPrefix: "SOCKET_"
      }
    }));
    Vue.use(VCalendar);
    /* TODO: VUE3 Drawer is not using in the code. Check again
    Vue.use(Drawer);
    */
    Vue.component('font-awesome-icon', FontAwesomeIcon);
    Vue.component('draggable', draggable);
    Vue.mixin(aclMixin);
    Vue.mixin(iconMixin);
    installFilters(Vue);
    Vue.component('FilteredInput', FilteredInput);
    ['error', 'info', 'success'].map(type => {
      Vue.config.globalProperties.$aiq.notify = {
        ...Vue.config.globalProperties.$aiq.notify,
        [type]: (title) => ElNotification({
          title,
          ...defaultToastedConfig,
          customClass: type,
        }),
      };
      return Vue;
    });

    Vue.use(router);
    Vue.use(store);
    store.dispatch('instrumentations/init', Vue);
    Vue.mount('#app');
  });
