import COLORS from './colors';

const conversationbyAgentsChartConfig = {
  name: 'conversations_by_participants',
  title: 'Agent Interaction',
  metric: 'conversations_by_participants',
  lines: {
    multiple_agents: {
      label: 'Multiple Agents Interacted',
      position: 1,
      color: COLORS.orange,
    },
    single_agents: {
      label: 'One Agent Interacted',
      position: 2,
      color: COLORS.yellow,
    },
    zero_agents: {
      label: 'No Agent Interaction',
      position: 3,
      color: COLORS.blood_red,
    },
    total_conversations: {
      label: 'Total Conversations',
      position: 6,
      color: COLORS.medium_turquoise,
      type: 'spline',
    },
  },
  helpText:
  'Displays all agent interactions, which when totaled, equals total conversations.',
};

export default conversationbyAgentsChartConfig;
