import moment from 'moment';

const DATE_RANGE = 7;
const WEEK_RANGE = 2;
const DAY_RANGE = 6;

const INDEX_DEFAULT = 1;

const DAY_COUNT_60 = 60;
const DAY_COUNT_90 = 90;

const dateLastRanges = (day, optionCallback) => ({
  label: `Last ${day} Days`,
  startTime: () => moment()
    .subtract(day, 'days')
    .startOf('day'),
  endTime: () => moment()
    .subtract(1, 'day')
    .endOf('day'),
  optionCallback,
});

const dateRangeOptions = (optionCallback) => [
  {
    label: 'Last Week',
    startTime: () => moment()
      .subtract(1, 'week')
      .startOf('day'),
    endTime: () => moment()
      .subtract(1, 'day')
      .endOf('day'),
    optionCallback,
  },
  {
    label: 'Last Month',
    startTime: () => moment()
      .subtract(1, 'month')
      .startOf('day'),
    endTime: () => moment()
      .subtract(1, 'day')
      .endOf('day'),
  },
  dateLastRanges(DAY_COUNT_60, optionCallback),
  dateLastRanges(DAY_COUNT_90, optionCallback),
];

const defaultDateRange = () => [
  moment()
    .subtract(1, 'week')
    .startOf('day'),
  moment()
    .subtract(1, 'day')
    .endOf('day'),
];

const lastWeekRange = [
  moment()
    .subtract(DATE_RANGE + 1, 'day')
    .startOf('day'),
  moment().subtract(1, 'day').endOf('day'),
];
const getStartEndMomentDates = (dateEpoch, deltaIncrement,
  deltaType = 'days') => ([
  deltaIncrement ? moment(dateEpoch) : moment(dateEpoch).startOf(deltaType),
  deltaIncrement ? moment(dateEpoch).add(deltaIncrement,
    deltaType) : moment(dateEpoch).endOf(deltaType),
]);


const breakdownOptions = [
  // {
  //   value: 'hour',
  //   label: 'Hourly',
  //   xAxisFormat: value => moment(value).format('MMM DD ha'),
  //   dateRangeWhenLineClicked: value => getStartEndMomentDates(value, 0,
  //     'hours'),
  //
  // },
  // Hourly bucket should be hidden  till ETL support is added
  {
    value: 'day',
    label: 'Daily',
    isDefault: true,
    xAxisFormat: value => moment(value).format('MMM DD'),
    dateRangeWhenLineClicked: value => getStartEndMomentDates(value, 0, 'days'),
  },
  {
    value: 'week',
    label: 'Weekly',
    xAxisFormat: value => {
      const dateRange = getStartEndMomentDates(value, DAY_RANGE);
      const format = 'MMM DD';
      return `${dateRange[0].format(format)} - ${dateRange[1].format(format)}`;
    },
    dateRangeWhenLineClicked: value => getStartEndMomentDates(value, 1, 'weeks'),
  },
  {
    value: 'month',
    label: 'Monthly',
    xAxisFormat: value => moment(value).format('MMM'),
    dateRangeWhenLineClicked: value => getStartEndMomentDates(value, 1, 'months'),


  },
];

const selectedBreakdownOption = () => breakdownOptions.find(
  ({ isDefault }) => isDefault,
);

const filterBreakdownOptions = (dateRange, allBreakdownOptions = breakdownOptions) => {
  const [startDate, endDate] = dateRange;
  /* eslint-disable no-magic-numbers */
  if (moment(endDate).diff(startDate, 'months') >= 2) {
    return allBreakdownOptions;
  }

  /* eslint-disable no-magic-numbers */
  if (moment(endDate).diff(startDate, 'weeks') >= 2) {
    return allBreakdownOptions.filter(({ value }) => value !== 'month');
  }

  return allBreakdownOptions.filter(({ value }) => value === 'hour' || value === 'day');
};


const createPickerDateRangeOption = option => ({
  text: option.label,
  value() {
    if (option.optionCallback) {
      option.optionCallback(option);
    }
    if (option.startTime && option.endTime) {
      const range = [option.startTime(), option.endTime()];
      return range;
    }
  },
});

// When passed to date-pickers, will disable the selection of dates from today onward
const todayOnwards = (time) => time.getTime() > moment().subtract(1, 'day').endOf('day');

// Sets the time of the end date to 11:59:59 pm
const setToEndOfDay = (newDateRange) => {
  const [startDate, endDate] = newDateRange;
  return [startDate, moment(endDate).endOf('day').toDate()];
};

// Used to fix an edge case in range date-pickers in which the user changes the date manually then clicks outside
// https://agentiq.atlassian.net/browse/AG-16561
// Instead of returning a date string, the date pickers return an object that the moment() function cannot handle
// So, this function turns the dates into a format that moment can handle.
// Please don't change it unless you are sure that the your change doesn't regress the edge case.
const getNonReactiveDateRange = (reactiveDateRange) => {
  const [startDate, endDate] = reactiveDateRange;
  return [new Date(startDate.toString()), new Date(endDate.toString())];
};

const getDiffDays = (dateRange) => {
  const [startDate, endDate] = getNonReactiveDateRange(dateRange);
  const startMoment = moment(startDate).startOf('day');
  const endMoment = moment(endDate).endOf('day');
  const diffInMilliseconds = endMoment.diff(startMoment);
  const diffInDays = moment.duration(diffInMilliseconds).asDays();
  return Math.round(diffInDays);
};

const isWarningRange = (dateRange) => (getDiffDays(dateRange) > DAY_COUNT_90);

const datePickerDefaultOptions = (optionCallback) => ({
  shortcuts: dateRangeOptions(optionCallback).map(createPickerDateRangeOption),
  disabledDate: todayOnwards,
});

const getDefaultDateRange = () => {
  const defaultOption = dateRangeOptions(undefined)[INDEX_DEFAULT];
  return [defaultOption.startTime(), defaultOption.endTime()];
};

export {
  getDefaultDateRange,
  dateRangeOptions,
  getDiffDays,
  isWarningRange,
  datePickerDefaultOptions,
  DATE_RANGE,
  WEEK_RANGE,
  DAY_RANGE,
  lastWeekRange,
  breakdownOptions,
  selectedBreakdownOption,
  createPickerDateRangeOption,
  defaultDateRange,
  todayOnwards,
  setToEndOfDay,
  filterBreakdownOptions,
  getNonReactiveDateRange,
};
