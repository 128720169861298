<template lang="pug">
  .container
    aiq-select.selector(placeholder="Filter by Teams"
                size="small"
                v-model="selectedTeams"
                multiple
                value-key='id'
                :reserve-keyword="false")
        aiq-option(v-for="team in activeTeams"
                  :key="team.id"
                  :label="team.name"
                  :value="team")
    aiq-table.scrollable(v-loading="initialLoading"
                        row-key="name"
                        :data="tableData" 
                        :default-sort="{prop: 'name', order: 'descending'}"
                        :row-class-name="getRowClasses"
                        :expand-row-keys="expandedRows"
                        @expand-change="handleRowExpand")
      aiq-table-column(type="expand")
        template(v-slot:default="props")
          .breakdown-row(v-for="agent in props.row.agentsBreakdown")
            .double-cell
              aiq-image.avatar(:src="agent.agents.avatar" :key="agent.agents.avatar")
              span.agent-name {{ agent.agents.fullName }}
            .double-cell
              template(v-if="agent.available")
                .status-indicator.green
                span Available
            .double-cell
              template(v-if="agent.busy")
                .status-indicator.orange
                span Busy
            div
              aiq-tooltip(effect="dark" :content="agent.primaryCapacity.tooltipContent" placement="top" :show-after="500")
                router-link(v-if="canView('/settings/agents/manage')" :to="{ name: 'Settings.Agents.Manage', query: { query: agent.agents.fullName } }")
                  span(:class="{ 'warning': agent.primaryCapacity.showAgentWarning }") {{ agent.primaryCapacity.value }}
                span(v-else :class="{ 'warning': agent.primaryCapacity.showAgentWarning }") {{ agent.primaryCapacity.value }}
            div
              aiq-tooltip(effect="dark" :content="agent.concurrentCapacity.tooltipContent" placement="top" :show-after="500")
                router-link(v-if="canView('/settings/agents/manage')" :to="{ name: 'Settings.Agents.Manage', query: { query: agent.agents.fullName } }")
                  span(:class="{ 'warning': agent.concurrentCapacity.showAgentWarning }") {{ agent.concurrentCapacity.value }}
                span(v-else :class="{ 'warning': agent.concurrentCapacity.showAgentWarning }") {{ agent.concurrentCapacity.value }}
      aiq-table-column(prop="name" label="Team" :sortable="true" :sort-method="sortData('name')")
      aiq-table-column(prop="agents" label="Agents")
      aiq-table-column(prop="available" label="Available")
      aiq-table-column(prop="busy" label="Busy")
      aiq-table-column(prop="primaryCapacity" label="Primary Capacity")
        template(v-slot:default="scope")
          .team-capacity
            span {{ scope.row.primaryCapacity.value }}
            fa-icon.warning(v-if="scope.row.primaryCapacity.showTeamWarning" :icon="['fas', 'circle-exclamation']" size="lg")
      aiq-table-column(prop="concurrentCapacity" label="Concurrent Capacity")
        template(v-slot:default="scope")
          .team-capacity
            span {{ scope.row.concurrentCapacity.value }}
            fa-icon.warning(v-if="scope.row.concurrentCapacity.showTeamWarning" :icon="['fas', 'circle-exclamation']" size="lg")
</template>

<script>
import get from 'lodash/get';
import { AGENT_STATUS, BOT_EMAIL } from '@/constants';
import { defaultProfile } from '@/libs';
import { onSort } from '@/pages/Metrics/helpers/formatters';
import PulsePanel from '../../common/panel.vue';

const AGENT_CAPACITY_THRESHOLD = 0.75; // 75%
const TEAM_SELECTIONS_CACHE_KEY = 'pulse/now/teams';

export default {
  name: 'PulseTeams',
  props: {
    action: {
      type: String,
      default: 'getAgentsMetrics',
    },
    storeKey: {
      type: String,
      default: 'agents',
    },
  },
  extends: PulsePanel,
  data() {
    return {
      activeTeams: [], // Teams that have agents assigned to them
      teamsMap: {}, // Agents grouped by Teams
      selectedTeams: [],
      initialLoading: true,
      tableData: [],
      expandedRows: [],
    };
  },
  computed: {
    isAvailable() {
      return AGENT_STATUS.Available;
    },
    isBusy() {
      return AGENT_STATUS.Paused;
    },
  },
  methods: {
    getActiveTeams() {
      const teamsMap = {};
      const activeTeams = [];

      const allAgents = get(this, 'metrics.data.models', []);

      for (const agent of allAgents) {
        if (agent.email !== BOT_EMAIL) {
          for (const team of agent.teams) {
            if (teamsMap[team.name]) {
              teamsMap[team.name].allAgentsCount += 1;
            } else {
              activeTeams.push({ id: team.id, name: team.name });
              teamsMap[team.name] = {
                id: team.id,
                name: team.name,
                allAgentsCount: 1,
                presentAgents: [],
              };
            }

            if (this.getIsAgentPresent(agent)) {
              teamsMap[team.name].presentAgents.push(agent);
            }
          }
        }
      }

      this.activeTeams = activeTeams;
      this.teamsMap = teamsMap;
      return activeTeams;
    },
    getTableData(selectedTeams) {
      const tableData = [];
      let filteredTeamsMap = {};

      if (get(selectedTeams, 'length')) {
        for (const team of selectedTeams) {
          filteredTeamsMap[team.name] = this.teamsMap[team.name];
        }
      } else {
        filteredTeamsMap = this.teamsMap;
      }

      for (const [teamName, { presentAgents, allAgentsCount }] of Object.entries(
        filteredTeamsMap,
      )) {
        presentAgents.sort((a, b) => onSort(b.fullName.toUpperCase(), a.fullName.toUpperCase()));
        const agentsMetrics = this.calculateAgentsMetrics(
          presentAgents,
          allAgentsCount,
        );
        tableData.push({
          name: teamName,
          ...agentsMetrics,
        });
      }
      this.tableData = tableData;
      return tableData;
    },
    calculateAgentsMetrics(presentAgents, allAgentsCount) {
      let availableAgentsCount = 0;
      let busyAgentsCount = 0;
      let totalPrimaryCustomersSum = 0;
      let maxLoadSum = 0;
      let totalLocksSum = 0;
      let concurrentCapacitySum = 0;
      let teamPrimaryCapacityWarning = false;
      let teamConcurrentCapacityWarning = false;
      const agentsBreakdown = [];

      for (const agent of presentAgents) {
        let isAgentAvailable = false;
        let isAgentBusy = false;
        const agentPrimaryCapacityWarning =
          this.shouldShowWarning(agent.totalPrimaryCustomers, agent.max_load);
        const agentConcurrentCapacityWarning =
          this.shouldShowWarning(agent.totalLocks, agent.concurrent_capacity);

        if (agent.available === this.isAvailable.field) {
          availableAgentsCount += 1;
          isAgentAvailable = true;
        } else if (agent.available === this.isBusy.field) {
          busyAgentsCount += 1;
          isAgentBusy = true;
        }

        totalPrimaryCustomersSum += agent.totalPrimaryCustomers || 0;
        maxLoadSum += agent.max_load || 0;
        totalLocksSum += agent.totalLocks || 0;
        concurrentCapacitySum += agent.concurrent_capacity || 0;
        teamPrimaryCapacityWarning =
          teamPrimaryCapacityWarning || agentPrimaryCapacityWarning;
        teamConcurrentCapacityWarning =
          teamConcurrentCapacityWarning || agentConcurrentCapacityWarning;

        agentsBreakdown.push({
          agents: {
            avatar: get(agent, 'picture.payload.thumbnail.url', defaultProfile.picture),
            fullName: agent.fullName,
          },
          available: isAgentAvailable,
          busy: isAgentBusy,
          primaryCapacity: {
            value: this.calculatePercentage(agent.totalPrimaryCustomers, agent.max_load),
            tooltipContent: `${agent.totalPrimaryCustomers}/${agent.max_load}`,
            showAgentWarning: agentPrimaryCapacityWarning,
          },
          concurrentCapacity: {
            value: this.calculatePercentage(agent.totalLocks, agent.concurrent_capacity),
            tooltipContent: `${agent.totalLocks}/${agent.concurrent_capacity}`,
            showAgentWarning: agentConcurrentCapacityWarning,
          },
        });
      }

      return {
        agents: `${availableAgentsCount + busyAgentsCount}/${allAgentsCount}`,
        available: availableAgentsCount,
        busy: busyAgentsCount,
        primaryCapacity: {
          value: this.calculatePercentage(totalPrimaryCustomersSum, maxLoadSum),
          showTeamWarning: teamPrimaryCapacityWarning,
        },
        concurrentCapacity: {
          value: this.calculatePercentage(totalLocksSum, concurrentCapacitySum),
          showTeamWarning: teamConcurrentCapacityWarning,
        },
        agentsBreakdown,
      };
    },
    calculatePercentage(numerator, denominator) {
      if (denominator === 0) {
        if (numerator === 0) {
          return '0%';
        }
        return '100%';
      }
      if (numerator > denominator) {
        return '100%';
      }
      return `${Math.ceil((numerator / denominator) * 100)}%`; // eslint-disable-line no-magic-numbers
    },
    shouldShowWarning(numerator, denominator) {
      if (denominator === 0) {
        if (numerator === 0) {
          return false;
        }
        return true;
      }
      return (numerator / denominator) > AGENT_CAPACITY_THRESHOLD;
    },
    sortData(field) {
      return (a, b) => onSort(get(a, [field], '').toUpperCase(), get(b, [field], '').toUpperCase());
    },
    getIsAgentPresent(agent) {
      return [this.isAvailable.field, this.isBusy.field].includes(agent.available);
    },
    handleRowExpand(_, expandedRows) {
      this.expandedRows = expandedRows.map(({ name }) => name);
    },
    getRowClasses({ row }) {
      if (!row.agentsBreakdown.length) {
        return 'no-expand';
      }
    },
  },
  watch: {
    selectedTeams(newValue) {
      const teamNames = newValue.map(team => team.name);
      localStorage.setItem(TEAM_SELECTIONS_CACHE_KEY, teamNames);
      this.getTableData(newValue);
    },
    'metrics.lastFetchedAt': function watchMetricsLastFetchedAt() {
      const activeTeams = this.getActiveTeams();

      if (this.initialLoading) {
        const cachedTeamSelections = localStorage.getItem(TEAM_SELECTIONS_CACHE_KEY) || '';
        this.selectedTeams = activeTeams
          .filter(team => cachedTeamSelections.split(',').includes(team.name));
        this.initialLoading = false;
      } else {
        this.getTableData(this.selectedTeams);
        this.expandedRows = this.expandedRows.filter(
          team => this.teamsMap[team].presentAgents.length,
        );
      }
    },
  },
};
</script>

<style lang="scss" scoped>
 @import "@/styles/aiq-mixins.scss";
 @import "@/styles/aiq-variables.scss";

.container {
  margin-top: 20px;

  .selector {
    margin-bottom: 10px;
  }

  :deep(.scrollable) {
    border-radius: 5px;

    .el-table__header-wrapper {
      th:first-child {
        background: $aiq-bgc-header;
      }
      
      .cell {
        background: $aiq-bgc-header;
        color: #fff;
        height: 100%;
        line-height: 30px;
      }
    }

    .el-table__body-wrapper {
      @include scrollable(calc(100vh - 530px));
      .el-scrollbar {
        height: calc(100vh - 530px);
      }

      .no-expand {
        .el-table__expand-icon {
          pointer-events: none;

          .el-icon {
            display: none;
          }
        } 
      }

      .warning {
        color: red;
      }

      .team-capacity {
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding-right: 20px;
      }

      .el-table__expanded-cell {
        padding: 0 0 0 50px;

        .breakdown-row {
          display: flex;
          padding: 10px 0 10px calc(100% / 6);

          & > div {
            flex: 1 1 0px;
            padding: 0 8px;
            display: flex;
            align-items: center;
          }

          .double-cell {
            .avatar {
              width: 22px;
              height: 22px;
              border-radius: 50%;
            }

            .status-indicator {
              width: 14px;
              height: 14px;
              border-radius: 50%;

              &.green {
                border: 1px solid $aiq-agent-available-indicator-border;
                background: $aiq-agent-available-indicator-bg;
              }

              &.orange {
                border: 1px solid $aiq-agent-busy-indicator-border;
                background: $aiq-agent-busy-indicator-bg;
              }
            }

            :nth-child(2) {
              margin-left: 8px;
            }

            .agent-name {
              color: $aiq-bgc-header;
            }
          }
        }
      }
    }
  }
}
</style>
