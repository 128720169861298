<template lang="pug">
  .label-switch
    h4 Segment
      aiq-button.create(class="add-button" @click="openEditor({})")
        i.el-icon-plus
    aiq-table.scrollbar_segments(:data="segmentTeamsSorted")
      aiq-table-column(label="Priority" prop="priority" width="75")
        template(v-slot:default="scope") 
          priority(:value="scope.row.priority"
                   :disabled="orderingDisabled"
                   :controlsVisible="!!scope.row.icon_url || !!scope.row.priority"
                   :isUpVisible="!!scope.$index"
                   :isDownVisible="scope.$index !== prioritizedSegments.length-1"
                   @up="reorder(scope.row, 1)"
                   @down="reorder(scope.row, -1)")
      aiq-table-column(label="Icon" prop="icon_url" width="50")
        template(v-slot:default="scope")
          aiq-Image(v-if="scope.row.icon_url"
                    :key="scope.row.icon_url"
                    :src="scope.row.icon_url"
                    class="segment-icon")
      aiq-table-column(label="Name" prop="name")
      aiq-table-column(label="Alias" prop="alias")
      aiq-table-column(label="Teams" prop="team_ids")
        template(v-slot:default="scope")
          aiq-keyword-list(:list="getTeamNames(scope.row.team_ids)")
      aiq-table-column(v-if="isQueuePriorityVisible" label="Queue Priority" prop="queue_priority" width="150")
      aiq-table-column(label-class-name="is-bordered", width="71")
        template(v-slot:default="scope")
          aiq-button(link type="primary" size="small", @click="openEditor(scope.row)") Edit
    Edit(v-if="isModalVisible",
        :id="selected.id"
        :next-priority="prioritizedSegments.length+1"
        @afterSave="verifyOrdering"
        @afterDelete="verifyOrdering($event, true)"
        @close="closeEditor")
</template>
<script>
import sortBy from 'lodash/sortBy';
import set from 'lodash/set';
import findIndex from 'lodash/findIndex';
import get from 'lodash/get';
import cloneDeep from 'lodash/cloneDeep';
import { mapGetters, mapActions } from 'vuex';
import Edit from './Edit.vue';
import Priority from './Priority.vue';

const ALL_TEAM_COUNT = 1000;

export default {
  name: 'customer-tab-segments',
  components: {
    Edit,
    Priority,
  },
  data() {
    return {
      selected: {},
      orderingDisabled: false,
      isModalVisible: false,
      isQueuePriorityVisible: false,
    };
  },
  computed: {
    ...mapGetters({
      teams: 'teams/teams',
      segmentTeams: 'configs/segmentTeams',
    }),
    segmentTeamsSorted() {
      return sortBy(this.segmentTeams, (s) => s.priority);
    },
    prioritizedSegments() {
      return this.segmentTeamsSorted.filter(s => s.icon_url || s.priority);
    },
  },
  methods: {
    ...mapActions({
      getTeamsList: 'teams/getTeamsList',
      getCustomerSegments: 'configs/getCustomerSegments',
      getWebchatConfigs: 'configs/getWebchatConfigs',
    }),
    getTeamNames(team_ids) {
      return team_ids.map(id => this.teams.find(t => t.id === id)?.name);
    },
    openEditor(record) {
      this.selected = { ...record };
      this.isModalVisible = true;
    },
    async verifyOrdering(record, isDeleting) {
      try {
        const prioritizedSegments = cloneDeep(this.prioritizedSegments);
        const index = findIndex(prioritizedSegments, s => s.id === record.id);
        /* If the icon was removed and it previously had priority, the priority
          must be removed from this segment and reorder those that were below it. */
        if (index !== -1 && (!record.payload.icon_url || isDeleting)) {
          const promises = prioritizedSegments.slice(index + 1).map(segment => {
            set(segment, 'payload.priority', segment.payload.priority - 1);
            return this.$store.dispatch('segments/updateSegment', [segment, 'data']);
          });
          await Promise.all(promises);
        }
        await this.getCustomerSegments();
      } catch (err) {
        this.$aiq.notify.error('Unable to ordering segments');
      }
    },
    async reorder(item, priority) {
      this.orderingDisabled = true;
      try {
        const promises = [];
        const prioritizedSegments = cloneDeep(this.prioritizedSegments);
        const index = findIndex(prioritizedSegments, s => s.id === item.id);
        const byIndex = index - priority;

        const segment = get(prioritizedSegments, index);
        const bySegment = get(prioritizedSegments, byIndex);

        set(segment, 'payload.priority', get(this.prioritizedSegments, `${byIndex}.payload.priority`));
        set(bySegment, 'payload.priority', get(this.prioritizedSegments, `${index}.payload.priority`));

        promises.push(this.$store.dispatch('segments/updateSegment', [segment, 'data']));
        promises.push(this.$store.dispatch('segments/updateSegment', [bySegment, 'data']));

        await Promise.all(promises);
        await this.getCustomerSegments();
        this.$aiq.notify.success('Segments has been updated');
      } catch (err) {
        this.$aiq.notify.error('Unable to update segments');
      }
      this.orderingDisabled = false;
    },
    async closeEditor() {
      this.isModalVisible = false;
    },
  },
  async mounted() {
    try {
      await this.getTeamsList([{ limit: ALL_TEAM_COUNT }]);
      await this.getCustomerSegments();
      await this.getWebchatConfigs();
      this.isQueuePriorityVisible = this.$store.state.configs.channels
        .webchat.webchat_configs.features.SHOW_QUEUE_TRANSPARENCY.segment_queue;
    } catch (err) {
      this.$aiq.notify.error('Unable to fetch segments');
    }
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../../../styles/aiq-mixins.scss";
@import "../../../../../../styles/aiq-extensions.scss";

:deep(.scrollbar_segments) {
  .el-table__body-wrapper {
    .el-scrollbar {
      @include scrollable(calc(100vh - 300px));
    }
  }
}

.add-button {
  margin-bottom: 5px;
  float: right;
  padding: 4px 0;
  margin-left: 12px;
  color: #8492A6;
  font-size: 16px;
  line-height: 16px;
  border: 0;
  background: transparent;
}

.segment-icon {
  width: 28px;
}

</style>
