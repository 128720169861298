import axios from 'axios';
import reduce from 'lodash/reduce';

import { getUrl } from '@/config/api.routes';
import { allCommandsBySystemName, getCommandType, mapBKItemsByType as mapByType } from '@/libs';

export const mutations = {
  GET_DBK_LIST_SUCCESS(state, items) {
    state.DBKItems = items;
    state.loading = false;
    state.loaded = true;
  },

  GET_DBK_LIST(state) {
    state.loading = true;
    state.loaded = false;
  },

  CREATE_DBK_ITEM(state, item) {
    // put new item in front of its kind. Assume that the array is already sorted as below order.
    // response -> commands -> dialog -> workflow
    if (state.DBKItems.length === 0) {
      state.DBKItems.push(item);
      return;
    }

    let index = 0;
    while (state.DBKItems.length > index) {
      if (state.DBKItems[index].type === item.type) {
        break;
      }
      index += 1;
    }

    if (index === 0) {
      state.DBKItems.unshift(item);
    } else {
      let temp = state.DBKItems.slice(0, index);
      temp.push(item);
      if (state.DBKItems.length !== index) {
        temp = temp.concat(state.DBKItems.slice(index, state.DBKItems.length));
      }
      state.DBKItems = temp;
    }
  },

  UPDATE_DBK_LIST(state, item) {
    state.DBKItems = state.DBKItems.map(e => (e.item.id === item.id ? { item, type: 'response' } : e));
  },

  UPDATE_RESPONSE(state, item) {
    state.DBKItems = state.DBKItems.map(e => (e.item.id === item.id ? { item } : e));
  },

  DELETE_ACTION(state, id) {
    state.DBKItems = state.DBKItems.filter(e => e.item.id !== id);
  },

  GET_FUNCTIONS_SUCCESS(state, commands) {
    state.commands = commands;
  },
};

export default {
  namespaced: true,

  state: {
    // Set up in object to match with the aiq-components
    commands: { functions: [] },
    DBKItems: [],
    loading: false,
    loaded: false,
  },

  getters: {
    dataByType: state => mapByType(state.DBKItems),
  },

  mutations,

  actions: {
    getDBKAll({ commit }, params = []) {
      commit('GET_DBK_LIST');
      const cb = (result, value, key) => (value ? { ...result, [key]: value } : result);
      return axios.get(getUrl('aimanager.dialogBuildingKit'),
        { params: reduce(params, cb, {}) }).then(data => {
        commit('GET_DBK_LIST_SUCCESS', data.data.data);

        return data;
      });
    },

    createAction({ commit }, entry) {
      return axios.post(getUrl('aimanager.actions'), entry).then(data => {
        const itemType = getCommandType(data.data.name);
        commit('CREATE_DBK_ITEM', { type: itemType, item: data.data });

        return data;
      });
    },

    updateAction({ commit }, [id, entry]) {
      return axios.put(getUrl('aimanager.actions', id), entry).then(data => {
        commit('UPDATE_RESPONSE', data.data);
        return data;
      });
    },

    deleteAction({ commit }, id) {
      return axios.delete(getUrl('aimanager.actions', id)).then(data => {
        commit('DELETE_ACTION', id);
        return data;
      });
    },

    getFunctions({ commit }, store) {
      const filteredFunctions = Object.values(allCommandsBySystemName)
        .filter(f => f.iqtoolsName);

      const promises = filteredFunctions.map(command => Promise.resolve()
        .then(() => {
          // Note that Bot.pass command doesn't currently have paramsType
          if (command.getParamsList) {
            return command.getParamsList(store);
          }

          return [];
        })
        .then(params => ({
          id: command.id,
          functionName: command.iqtoolsName,
          commandName: command.systemName,
          // believe iqtools isn't currently using this paramsType, refactor in future PR
          paramsType: command.paramsType,
          params,
        })));

      return Promise.all(promises)
        .then(commandList => {
          commit('GET_FUNCTIONS_SUCCESS', { functions: commandList });

          return commandList;
        });
    },
  },
};
