const isActive = (conversation) => conversation.effective_status === 'active';

const isInactive = (conversation) => conversation.effective_status === 'inactive';

const getEffectiveStatus = (conversation) => conversation.effective_status || conversation.status;

const DEFAULT_CHANNEL = 'all';

const DEFAULT_SCOPE = 'mine';

const CONVERSATION_LIMIT = 50;

const MESSAGES_LIMIT = 100;

const MSG_SUGGESTION_MEMORY_LIMIT = 20;

const ENTITY_PLACEHOLDER_UPDATE_TEXT = 'UPDATE_VALUE';

const KB_MODES = {
  DEFAULT: 'default', // show default assets/documents
  SEARCH: 'search', // show searched assets/documents
  SELECTED: 'selected', // show suggested assets/documents
};

const KB_MODE_TYPES = {
  ASSETS: 'assets',
  DOCUMENTS: 'documents',
};

const DEFAULT_KB_MODE = {
  name: KB_MODES.DEFAULT,
  type: KB_MODE_TYPES.ASSETS,
  // TODO (Gabe) - Currently only for suggested items. Refactor for all
  items: [],
};

const SIDE_PANEL_TAB_NAMES = {
  CUSTOMER_INFO: 'CUSTOMER_INFO',
  CONVERSATION_CLASSIFICATION: 'CONVERSATION_CLASSIFICATION',
  MY_TOOLBOX: 'MY_TOOLBOX',
  KNOWLEDGE_BASE: 'KNOWLEDGE_BASE',
  NOTES: 'NOTES',
  HISTORY: 'HISTORY',
  COBROWSE: 'COBROWSE',
};


const DEFAULT_POLL_INTERVAL = 20000;

const ATTACHMENT_HISTORY_TYPES = {
  DOCUMENTS: {
    key: 'DOCUMENTS',
    types: ['document'],
  },
  MEDIA: {
    key: 'MEDIA',
    types: ['image'],
  },
  URL: {},
};

const DEFAULT_ATTACHMENTS_HISTORY = {
  mode: ATTACHMENT_HISTORY_TYPES.DOCUMENTS,
  loaded: false,
  records: {},
};

const RESOLVED_STATUS = {
  RESOLVED_BY_AI: 'resolved_by_ai',
  UNRESOLVED_AI: 'unresolved_ai',
  RESOLVED_BY_AGENT: 'resolved_by_agent',
  UNRESOLVED_BY_AGENT: 'unresolved_by_agent',
  ROLL_OVER_NEW_CONVERSATION: 'roll_over_new_conversation',
  UNKNOWN: 'unknown',
};

export {
  DEFAULT_CHANNEL,
  DEFAULT_KB_MODE,
  DEFAULT_SCOPE,
  ENTITY_PLACEHOLDER_UPDATE_TEXT,
  getEffectiveStatus,
  isActive,
  isInactive,
  CONVERSATION_LIMIT,
  MESSAGES_LIMIT,
  KB_MODES,
  KB_MODE_TYPES,
  MSG_SUGGESTION_MEMORY_LIMIT,
  DEFAULT_POLL_INTERVAL,
  SIDE_PANEL_TAB_NAMES,
  ATTACHMENT_HISTORY_TYPES,
  DEFAULT_ATTACHMENTS_HISTORY,
  RESOLVED_STATUS,
};
