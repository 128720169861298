import cloneDeep from 'lodash/cloneDeep';
import set from 'lodash/set';
import get from 'lodash/get';
import { Component, Vue, toNative } from 'vue-facing-decorator';

@Component({
  name: 'tools-tab',
  computed: {
    sentryConfig() {
      return get(this.webchatConfig, 'configs.sentry', {});
    },
  },
})

class ToolsTab extends Vue {
  webchatConfig = null;

  async mounted() {
    this.webchatConfig = await this.$store.dispatch(
      'settings/getSetting',
      { name: 'webchat_configs' },
    );
  }

  async onSentryChange(val) {
    try {
      const payload = cloneDeep(this.webchatConfig);
      set(payload, 'configs.sentry.enabled', val);
      this.webchatConfig = await this.$store.dispatch('settings/updateSetting', {
        name: 'webchat_configs',
        payload,
      });

      this.$aiq.notify.success('Updated');
    } catch (err) {
      this.$aiq.notify.error(err.message);
    }
  }
}
export default toNative(ToolsTab);
