import COLORS from './colors';

const conversationbyCustomersChartConfig = {
  name: 'conversations_by_participants',
  title: 'Engagement',
  metric: 'conversations_by_participants',
  lines: {
    single_customers: {
      label: 'Customers',
      position: 1,
      color: COLORS.green,
    },
    zero_customers: {
      label: 'Agents or Bots Only',
      position: 2,
      color: COLORS.bottle_green,
    },
    total_conversations: {
      label: 'Total Conversations',
      position: 3,
      color: COLORS.medium_turquoise,
      type: 'spline',
    },
  },
  helpText:
    'Displays customer, agent, and/or bot engagement. Customer engagement can precede, proceed, or have no agent and/or bot engagement. Agent or bot engagement indicates no customer engagement.',
};
export default conversationbyCustomersChartConfig;
