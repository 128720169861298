<template lang="pug">
  management-page.scrollable(title="Communication"
                            :creatable="false"
                            :searchable="false"
                            :showHeader="false")
    template(v-slot:body)
      aiq-row(:gutter="16" justify="end")
        .time-range-filter
          aiq-date-picker(v-model="dateRange"
                          @change="handleDatePickerChange($event)"
                          type="daterange"
                          placeholder="Select date range",
                          popper-class="metrics-time-range__date-picker-popup"
                          :shortcuts="datePickerOptions.shortcuts"
                          :disabled-date="datePickerOptions.disabledDate"
                          :teleported="false"
                          format="YYYY/MM/DD"
                          :clearable="false")
      include ./selectableFilters/SelectableFilters.pug
      .chart-grid
        aiq-row(:gutter="16")
          aiq-col(:span="24")
            overview-chart(v-if="configs.cobrowse_volume"
                          :dateRange="dateRangeUtc"
                          :filters="selectedFilters"
                          :chartConfig="cobrowseVolumeConfig"
                          yLabel="Count"
                          :formatYTick="intCountFormatter"
                          :BucketOptions="filteredBreakdownOptions"
                          visualization='aiq-bar-chart')

</template>

<script>
import { mapGetters, mapState } from 'vuex';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import OverviewChart from '@/components/Chart.vue';
import cobrowseVolumeConfig from '../../configs/cobrowwseVolume';
import PlatformFiltersMixin from './selectableFilters/SelectableFilters';
import debounce from 'lodash/debounce';

export default {
  pageResource: '/metrics/platform',
  name: 'Communication',
  mixins: [PlatformFiltersMixin],
  components: {
    ManagementPage,
    OverviewChart,
  },
  mounted() {
    try {
      this.loadAllFilterOptions();
    } catch (err) {
      this.$aiq.notify.error(`Failed: ${err.message}`);
    }
  },
  watch: {
    dateRange: debounce(function onDateChange(newValue, oldValue = []) {
      const [start, end] = oldValue;
      if (!(start && end)) {
        return;
      }
      this.loadAllFilterOptions();
    }, 1),
  },
  data() {
    return {
      cobrowseVolumeConfig,
      intCountFormatter: v => (Math.floor(parseFloat(v)) === parseFloat(v) ? v : null),
    };
  },
  computed: {
    ...mapGetters({
      configs: 'configs/graphsConfigs',
    }),
    ...mapState({
      showCobrowse: state => state.featureFlags.FEATURE_FLAGS.showCobrowse,
    }),
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/aiq-variables.scss";
@import "./selectableFilters/SelectableFilters.scss";

.scrollable {
  overflow-y: auto;
}

.el-card:not(:first-of-type) {
  margin-top: 24px;
}

.time-range-filter {
  position: relative;
  margin-bottom: 24px;
}


.chart-configuration {
  display: flex;
  margin-bottom: 16px;
  justify-content: space-between;
  .breakdown {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }
  .members-filter {
    display: flex;
    align-items: center;
  }

  .export-command {
    display: flex;
    height: 30px;
    width: 40px;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    padding: 0;
  }
}
</style>
