<template lang="pug">
  management-page(title="Summary"
                :creatable="false"
                :searchable="false"
                :showHeader="false")
    template(v-slot:body)
      .chart-configuration
        .members-filter
        .breakdown
          aiq-button-group.breakdown-options
            aiq-button(v-for="option in filteredBreakdownOptions"
                      :key="option.value"
                      size="small"
                      :class="{'selected': selectedBreakdownOption.value === option.value}"
                      @click="setBreakdown(option.value)"
                      ) {{ option.label}}
        .time-range-filter
          aiq-date-picker(v-model="dateRange"
                          @change="handleDatePickerChange($event)"
                          type="daterange"
                          placeholder="Select date range",
                          popper-class="metrics-time-range__date-picker-popup"
                          :shortcuts="datePickerOptions.shortcuts"
                          :disabled-date="datePickerOptions.disabledDate"
                          :teleported="false"
                          format="YYYY/MM/DD"
                          :clearable="false")
      .chart-grid
        aiq-row(:gutter="16")
          aiq-col(:span="12")
            chart(:dateRange="dateRangeUtc"
                            :breakdown="selectedBreakdownOption.value"
                            :chartConfig="suggestedResponsesChartConfig"
                            :formatXTick="selectedBreakdownOption.xAxisFormat"
                            yLabel="Count")
          aiq-col(:span="12")
            chart(:dateRange="dateRangeUtc"
                            :breakdown="selectedBreakdownOption.value"
                            :chartConfig="dialogsChartConfig"
                            :formatXTick="selectedBreakdownOption.xAxisFormat"
                            yLabel="Count")
          aiq-col(:span="12")
            chart(:dateRange="dateRangeUtc"
                            :breakdown="selectedBreakdownOption.value"
                            :chartConfig="workflowsChartConfig"
                            :formatXTick="selectedBreakdownOption.xAxisFormat"
                            yLabel="Count")
          aiq-col(:span="12")
            chart(:dateRange="dateRangeUtc"
                            :breakdown="selectedBreakdownOption.value"
                            :chartConfig="assetsChartConfig"
                            :formatXTick="selectedBreakdownOption.xAxisFormat"
                            yLabel="Count")
          aiq-col(:span="12")
            chart(:dateRange="dateRangeUtc"
                            :breakdown="selectedBreakdownOption.value"
                            :chartConfig="documentsChartConfig"
                            :formatXTick="selectedBreakdownOption.xAxisFormat"
                            yLabel="Count")
</template>

<script>
import moment from 'moment';
import ManagementPage from '@/components/ManagementPage/ManagementPage.vue';
import Chart from '@/components/Chart.vue';
import { datePickerDefaultOptions, setToEndOfDay, defaultDateRange, filterBreakdownOptions, getNonReactiveDateRange } from '../../configs/dateRange';

const DAY_RANGE = 6;

// todo register colors globally from aiq-components
const COLORS = {
  red: '#FF6384',
  orange: '#FFBE50',
  yellow: '#FFEC71',
  grey: '#D7D9DE',
  blue: '#4671B3',
  green: '#4BC0C0',
};

const breakdownOptions = [
  {
    value: 'hour',
    label: 'Hourly',
    xAxisFormat: value => moment(value).format('MMM DD ha'),
  },
  {
    value: 'day',
    label: 'Daily',
    isDefault: true,
    xAxisFormat: value => moment(value).format('MMM DD'),
  },
  {
    value: 'week',
    label: 'Weekly',
    xAxisFormat: value => {
      const start = moment(value);
      const end = moment(value).add(DAY_RANGE, 'days');
      const format = 'MMM DD';
      return `${start.format(format)} - ${end.format(format)}`;
    },
  },
  {
    value: 'month',
    label: 'Monthly',
    xAxisFormat: value => moment(value).format('MMM'),
  },
];

const suggestedResponsesChartConfig = {
  title: 'Suggestions',
  metric: 'suggestions',
  lines: {
    suggestion_show: {
      label: 'Suggested',
      position: 1,
      color: COLORS.red,
      type: 'line',
    },
    suggestion_click: {
      label: 'Selected',
      position: 2,
      color: COLORS.orange,
      type: 'line',
    },
    suggestion_edit: {
      label: 'Edited',
      position: 3,
      color: COLORS.yellow,
      type: 'line',
    },
    suggestion_send: {
      label: 'Sent',
      position: 4,
      color: COLORS.blue,
      type: 'line',
    },
  },
  helpText:
    'Displays suggestion count by agent action.',
};

const dialogsChartConfig = {
  title: 'Dialogs',
  metric: 'dialogs',
  lines: {
    dialog_success: {
      label: 'Successful',
      position: 1,
      color: COLORS.blue,
      type: 'line',
    },
    dialog_failure: {
      label: 'Failed',
      position: 2,
      color: COLORS.red,
      type: 'line',
    },
  },
  helpText:
    'Displays dialog results.',
};

const workflowsChartConfig = {
  title: 'Workflows',
  metric: 'workflows',
  lines: {
    workflow_success: {
      label: 'Successful',
      position: 1,
      color: COLORS.blue,
      type: 'line',
    },
    workflow_failure: {
      label: 'Failed',
      position: 2,
      color: COLORS.red,
      type: 'line',
    },
  },
  helpText:
    'Displays workflows results.',
};

const assetsChartConfig = {
  title: 'Assets',
  metric: 'kb_assets',
  lines: {
    kb_assets_show: {
      label: 'Suggested',
      position: 1,
      color: COLORS.red,
      type: 'line',
    },
    kb_assets_click: {
      label: 'Selected',
      position: 2,
      color: COLORS.orange,
      type: 'line',
    },
    kb_assets_send: {
      label: 'Sent',
      position: 3,
      color: COLORS.blue,
      type: 'line',
    },
  },
  helpText:
    'Displays asset count by agent action.',
};

const documentsChartConfig = {
  title: 'Documents',
  metric: 'kb_documents',
  lines: {
    kb_documents_show: {
      label: 'Suggested',
      position: 1,
      color: COLORS.red,
      type: 'line',
    },
    kb_documents_click: {
      label: 'Selected',
      position: 2,
      color: COLORS.orange,
      type: 'line',
    },
    kb_documents_send: {
      label: 'Sent',
      position: 3,
      color: COLORS.blue,
      type: 'line',
    },
  },
  helpText:
    'Displays document count by agent action.',
};

export default {
  pageResource: '/metrics',
  components: {
    Chart,
    ManagementPage,
  },
  computed: {
    datePickerOptions() {
      return datePickerDefaultOptions();
    },
    dateRangeUtc() {
      return this.dateRange.map(date => moment(date)
        .utc()
        .unix());
    },
    // TODO: Create a mixin that has this computed value,
    // along with other common properties and functions in metrics
    filteredBreakdownOptions() {
      return filterBreakdownOptions(this.dateRange);
    },
  },
  watch: {
    filteredBreakdownOptions(newOptions) {
      if (!newOptions.find(o => o.value === this.selectedBreakdownOption.value)) {
        this.selectedBreakdownOption = newOptions.find(o => o.isDefault);
      }
    },
  },
  data() {
    return {
      assetsChartConfig,
      documentsChartConfig,
      suggestedResponsesChartConfig,
      dialogsChartConfig,
      workflowsChartConfig,
      percentFormatter: v => `${v}%`,
      durationFormatter: this.$aiq.formatters.timeDelta,
      dateRange: defaultDateRange(),
      selectedBreakdownOption: breakdownOptions.find(
        ({ isDefault }) => isDefault,
      ),
    };
  },
  methods: {
    setBreakdown(value) {
      this.selectedBreakdownOption = breakdownOptions.find(
        option => option.value === value,
      );
    },
    handleDatePickerChange(newDateRange) {
      this.dateRange = setToEndOfDay(getNonReactiveDateRange(newDateRange));
    },
  },
};
</script>

<style lang="scss" scoped>
@import "../../../../styles/aiq-variables.scss";

.content-inner.summary {
  overflow-y: auto;
}

.time-range-filter {
  position: relative;
}

.el-card:not(:first-of-type) {
  margin-top: 24px;
}

.chart-configuration {
  display: flex;
  margin-bottom: 24px;

  .members-filter {
    display: flex;
    align-items: center;
  }

  .breakdown {
    display: flex;
    align-items: center;
    flex-grow: 1;
  }

  .export-command {
    display: flex;
    height: 30px;
    width: 40px;
    justify-content: center;
    align-items: center;
    margin-left: 16px;
    padding: 0;
  }
}
</style>
