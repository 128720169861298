import COLORS from './colors';

const activeCustomersChartConfig = {
  name: 'active_customers',
  title: 'Active Customers',
  metric: 'active_customers',
  lines: {
    daily: {
      label: 'Daily Count',
      position: 1,
      color: COLORS.yellow,
    },
    weekly: {
      label: 'Weekly Count',
      position: 2,
      color: COLORS.red,
    },
    monthly: {
      label: 'Monthly Count',
      position: 3,
      color: COLORS.blue,
    },
  },
  helpText:
    'Displays the number of active customers. Weekly and Monthly totals represent the week or month preceding the selected date. The sum for the selected date range appears in the title.',
};

export default activeCustomersChartConfig;
