import COLORS from './colors';

const conversationbyAgentsChartConfig = {
  name: 'conversations_by_participants',
  title: 'Bot Interaction',
  metric: 'conversations_by_participants',
  lines: {
    single_bots: {
      label: 'Bot Interaction',
      position: 1,
      color: COLORS.steel_blue,
    },
    zero_bots: {
      label: 'No Bot Interaction',
      position: 2,
      color: COLORS.celestial_blue,
    },
    total_conversations: {
      label: 'Total Conversations',
      position: 3,
      color: COLORS.medium_turquoise,
      type: 'spline',
    },
  },
  helpText:
    'Displays all bot interactions, which when totaled, equals total conversations.',
};

export default conversationbyAgentsChartConfig;
